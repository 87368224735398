import {
    Alert,
    ColorIndicator,
    DataTable,
    Loading,
    PageTitle,
    StyleFunction,
    Surface,
    useAuth,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQCmpEventsQuery, useCmpEventsQuery } from "graphql-sdk";
import { AvailableLocale, useTranslation } from "locales";
import React, { useCallback } from "react";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { formatDateTime } from "lib";

type ScreenProps = RootStackScreenProps<"CMP_EVENTS">;

export function CmpEventListScreen({ navigation: { navigate } }: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["merchant.cmp"]);

    const styles = useThemedStyle(styleFunc);
    const [{ locale }] = useAdminSession(["locale"]);
    const theme = useTheme();
    const [t] = useTranslation();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
        initialSortDirection: "DESC",
    });

    const { data, loading, error } = useCmpEventsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    const handleClick = useCallback(
        (externalEventId: string) => {
            navigate("CMP_EVENT", {
                id: externalEventId,
            });
        },
        [navigate]
    );

    const renderCmpEventRow = useCallback(
        (item: GQCmpEventsQuery["cmpEvents"]["data"][0], rowIndex) => (
            <DataTable.Row
                style={rowIndex % 2 ? theme.styles.oddRow : undefined}
                onPress={() => handleClick(item.externalId)}
                key={`cmpEventId_${item.id}`}
            >
                <DataTable.Cell style={styles.iconColumn}>
                    <ColorIndicator
                        status={item.active ? "success" : "warning"}
                    />
                </DataTable.Cell>
                <DataTable.Cell>{item.name}</DataTable.Cell>
                <DataTable.Cell>{item.externalId}</DataTable.Cell>
                <DataTable.Cell style={styles.productCountColumn} numeric>
                    {item.products.length}
                </DataTable.Cell>
                <DataTable.Cell>
                    {item.from
                        ? formatDateTime(item.from, locale as AvailableLocale)
                        : null}
                </DataTable.Cell>
                <DataTable.Cell>
                    {item.to
                        ? formatDateTime(item.to, locale as AvailableLocale)
                        : null}
                </DataTable.Cell>
                <DataTable.Cell style={styles.sequenceColumn} numeric>
                    {item.sequence}
                </DataTable.Cell>
                <DataTable.Cell>
                    {formatDateTime(item.createdAt, locale as AvailableLocale)}
                </DataTable.Cell>
                <DataTable.Cell>
                    {formatDateTime(item.updatedAt, locale as AvailableLocale)}
                </DataTable.Cell>
            </DataTable.Row>
        ),
        [
            handleClick,
            locale,
            styles.iconColumn,
            styles.productCountColumn,
            styles.sequenceColumn,
            theme.styles.oddRow,
        ]
    );

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error?.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <PageTitle>
                {t("backoffice.cmp_event.cmp_events", "CMP events")}
            </PageTitle>
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "active" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("active")}
                            style={styles.iconColumn}
                        >
                            {t("backoffice.common.active", "Active")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("backoffice.cmp_event.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "externalId"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("externalId")}
                        >
                            {t("backoffice.common.external_id", "External id")}
                        </DataTable.Title>
                        <DataTable.Title
                            style={styles.productCountColumn}
                            numeric
                        >
                            {t(
                                "backoffice.cmp_event.product_count",
                                "No. products"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "from" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("from")}
                        >
                            {t("backoffice.cmp_event.from", "From")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "to" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("to")}
                        >
                            {t("backoffice.cmp_event.to", "To")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sequenceColumn} numeric>
                            {t("backoffice.common.sequence", "Sequence")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "createdAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("createdAt")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "updatedAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("updatedAt")}
                        >
                            {t("common.updated_at", "Updated at")}
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : data.cmpEvents.data.length === 0 ? (
                        <DataTable.Row style={theme.styles.oddRow}>
                            <DataTable.Cell>
                                {t(
                                    "backoffice.cmp_event.no_data",
                                    "No events found."
                                )}
                            </DataTable.Cell>
                        </DataTable.Row>
                    ) : (
                        data?.cmpEvents.data.map(renderCmpEventRow)
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.cmpEvents.pagination.pages}
                        itemCount={data?.cmpEvents.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = () => ({
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 60 },
    productCountColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 150 },
    sequenceColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 180 },
});
