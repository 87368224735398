import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "locales";
import { StyleProp, View, ViewStyle } from "react-native";
import { useThemedStyle, StyleFunction, Button } from "../..";

export function PrinterWrapper({
    containerStyle,
    printContainerStyle,
    children,
}: {
    containerStyle?: StyleProp<ViewStyle>;
    printContainerStyle?: StyleProp<ViewStyle>;
    children: React.ReactNode;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef });

    return (
        <View style={styles.container}>
            <View style={[styles.visual, containerStyle]}>
                <div ref={contentRef}>{children}</div>
            </View>
            <View style={[styles.printButtonContainer, printContainerStyle]}>
                <Button onPress={() => reactToPrintFn()}>
                    {t("common.print", "Print")}
                </Button>
            </View>
        </View>
    );
}
const styleFunc: StyleFunction = globalTheme => ({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
        paddingTop: globalTheme.spacingScale,
    },
    visual: {
        marginRight: globalTheme.spacingScale,
        alignItems: "flex-start",
    },
    printButtonContainer: {
        alignItems: "flex-start",
        borderRadius: globalTheme.borderRadius,
    },
});
