import {
    Button,
    CheckBox,
    ColorIndicator,
    ColorPickerInput,
    InputControl,
    InputLabel,
    Loading,
    Picker,
    StyleFunction,
    TextInput,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import { useProductGroupsQuery } from "graphql-sdk";
import { BaseLayoutButton, CustomItemButtonType } from "lib";
import { useTranslation } from "locales";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { FunctionButtonModalOutput } from "..";

export function CustomItemSubForm({
    values: mainFormValues,
    onSubmit,
}: {
    values: CustomItemButtonType;
    onSubmit: (values: FunctionButtonModalOutput) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const subForm = useForm<Omit<CustomItemButtonType, keyof BaseLayoutButton>>(
        {
            productGroupId: { required: true },
            label: { required: true },
        },
        {
            buttonType: "FUNCTION",
            function: "CUSTOM_ITEM",

            label: mainFormValues.label || "",
            color: mainFormValues.color || "",
            productGroupId: mainFormValues.productGroupId || "",
        }
    );

    const [{ errors, values }, { setValue, handleSubmit }] = subForm;

    const [productGroupColor, setProductGroupColor] = useState<string>("");
    const [activateColorPicker, setActivateColorPicker] = useState<boolean>(
        !!values.color || false
    );

    const productGroupsQuery = useProductGroupsQuery({
        variables: {
            pagination: {
                pageSize: 999999,
                sort: "name",
                sortDirection: "ASC",
            },
        },
    });
    const productGroups = productGroupsQuery.data?.productGroups.data;

    const handleGroupChange = useCallback(
        (selectedProductGroupId: string) => {
            if (!productGroups?.length) {
                return;
            }

            setValue("productGroupId", selectedProductGroupId);

            const groupData = productGroups.find(
                groupItr => groupItr.id === selectedProductGroupId
            );

            if (!groupData) {
                setProductGroupColor("");
                return;
            }

            setProductGroupColor(groupData.color);
        },
        [productGroups, setValue]
    );

    const handleOnSubmit = useCallback(() => {
        onSubmit({
            buttonType: "FUNCTION",
            formValues: values as CustomItemButtonType,
            displayValues: {
                color: values.color || productGroupColor,
                label: values.label,
            },
        });
    }, [onSubmit, productGroupColor, values]);

    // When a button is loaded, then wait for the product groups to load, so we can set the group color
    useEffect(() => {
        if (
            !productGroups ||
            productGroups.length === 0 ||
            !values.productGroupId
        ) {
            return;
        }

        const groupData = productGroups.find(
            groupItr => groupItr.id === values.productGroupId
        );

        if (!groupData) {
            return;
        }

        setProductGroupColor(groupData.color);
    }, [productGroups, values.productGroupId]);

    return (
        <>
            <View style={styles.rowContainer}>
                <InputControl error={errors.label} style={styles.rowContent}>
                    <TextInput
                        required
                        label={t("layout.function_button_form.label", "Label")}
                        placeholder={t(
                            "layout.function_button_form.label_placeholder",
                            "Write a short button text"
                        )}
                        value={values.label}
                        onChangeText={value => {
                            setValue("label", value);
                        }}
                        onClear={() => {
                            setValue("label", "");
                        }}
                    />
                </InputControl>
                <InputControl
                    error={errors.productGroupId}
                    style={styles.rowContent}
                >
                    {!productGroups || !productGroups.length ? (
                        <Loading
                            message={t(
                                "backoffice.layout.checking_product_groups",
                                "Checking for product groups. Are there any?"
                            )}
                        />
                    ) : (
                        <Picker
                            required
                            label={t(
                                "layout.function_button_form.product_group",
                                "Product group"
                            )}
                            selectedValue={values.productGroupId}
                            onValueChange={handleGroupChange}
                        >
                            <Picker.Item
                                label={t(
                                    "layout.function_button_form.choose_product_group",
                                    "Choose a product group"
                                )}
                                key="__select__"
                                value=""
                            />
                            {productGroups.map(productGroup => (
                                <Picker.Item
                                    key={productGroup.id}
                                    value={productGroup.id}
                                    label={productGroup.name}
                                />
                            ))}
                        </Picker>
                    )}
                </InputControl>
            </View>
            <View style={[styles.rowContainer, styles.onTop]}>
                <InputControl
                    description={t(
                        "layout.function_button_form.override_product_group_color",
                        "The button gets the colour of the product group, unless you select a different color here."
                    )}
                    style={styles.rowContent}
                >
                    <View style={styles.colorRow}>
                        <InputLabel>
                            {`${t(
                                "layout.button.product_group_color",
                                "Product group color"
                            )}:`}
                        </InputLabel>
                        <View style={styles.groupColor}>
                            <ColorIndicator
                                color={productGroupColor}
                                copyOnClick={true}
                                animate={false}
                            />
                        </View>
                    </View>
                    <CheckBox
                        value={activateColorPicker}
                        onValueChange={checkboxValue => {
                            if (!checkboxValue) {
                                setValue("color", "");
                            }
                            setActivateColorPicker(checkboxValue);
                        }}
                        label={t(
                            "layout.button.pick_color_question",
                            "Pick a custom color?"
                        )}
                    />
                </InputControl>
                {activateColorPicker ? (
                    <InputControl
                        error={errors.color}
                        style={styles.rowContent}
                    >
                        <ColorPickerInput
                            defaultColor={values.color}
                            label={t(
                                "layout.button.custom_color",
                                "Custom button color"
                            )}
                            onChangeColor={newColor => {
                                setValue("color", newColor);
                            }}
                        />
                    </InputControl>
                ) : (
                    <View style={styles.rowContent} />
                )}
            </View>
            <Button type="primary" onPress={handleSubmit(handleOnSubmit)}>
                {t("layout.function_button_form.types.apply", "Apply")}
            </Button>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContainer: {
        ...theme.styles.row,
        columnGap: theme.spacingScale * 2,
    },
    rowContent: {
        ...theme.styles.flex1,
    },
    onTop: { zIndex: 2 },
    colorRow: {
        ...theme.styles.row,

        alignItems: "baseline",
        marginBottom: 8,
    },
    groupColor: {
        marginLeft: theme.spacingScale / 2,
    },
});
