import {
    Button,
    DataTable,
    Icon,
    Loading,
    Surface,
    useAuth,
    usePagination,
    useTheme,
} from "@venuepos/react-common";
import { useRolesQuery } from "graphql-sdk";
import React, { useCallback } from "react";
import { useTranslation } from "locales";
import { FlatList, StyleSheet, View } from "react-native";

import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";

type ScreenProps = RootStackScreenProps<"ROLES">;

export function RoleListScreen({ navigation: { navigate } }: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["merchant.role", "admin.role"], "OR");

    const theme = useTheme();
    const [t] = useTranslation();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination();

    // GraphQL
    const { data, loading, error } = useRolesQuery({
        fetchPolicy: "no-cache",
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
    });

    // Actions
    const handleEdit = useCallback(
        (id: string) => {
            navigate("ROLE_EDIT", {
                id,
            });
        },
        [navigate]
    );

    return (
        <AdminContainer>
            <View style={styles.container}>
                <Button
                    onPress={() => {
                        navigate("ROLE_CREATE");
                    }}
                    style={styles.button}
                >
                    {t("backoffice.role_list.create_role", "Create")}
                </Button>
            </View>
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                            style={styles.nameColumn}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        {auth.may(["admin.role"]) && (
                            <DataTable.Title
                                sortDirection={
                                    sortBy === "merchantName"
                                        ? sortDirection
                                        : undefined
                                }
                                onPress={() => onSortChange("merchantName")}
                            >
                                {t("backoffice.role_list.merchant", "Merchant")}
                            </DataTable.Title>
                        )}
                        <DataTable.Title style={styles.sortIcon}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {!error && !loading && data && data.roles.data ? (
                        <FlatList
                            data={data && data.roles.data}
                            renderItem={({ item, index }) => (
                                <DataTable.Row
                                    onPress={() => handleEdit(item.id)}
                                    style={
                                        index % 2 ? theme.styles.oddRow : null
                                    }
                                >
                                    <DataTable.Cell style={styles.nameColumn}>
                                        {item.name}
                                    </DataTable.Cell>
                                    {auth.may(["admin.role"]) && (
                                        <DataTable.Cell>
                                            {item.merchant?.name}
                                        </DataTable.Cell>
                                    )}
                                </DataTable.Row>
                            )}
                            keyExtractor={(_, index) => index.toString()}
                        />
                    ) : (
                        <Loading />
                    )}

                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.roles.pagination.pages}
                        itemCount={data?.roles.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "space-between",
        marginBottom: 20,
        flexWrap: "wrap",
    },
    button: { width: 200, alignSelf: "flex-end" },
    sortIcon: {
        justifyContent: "flex-end",
    },
    nameColumn: {
        maxWidth: 300,
    },
});
