import {
    Alert,
    Button,
    CheckBox,
    DataTable,
    Icon,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    useMerchantConfig,
    useModal,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
    useToast,
} from "@venuepos/react-common";
import {
    GQAccountQuery,
    useAccountReissueGiftcardsMutation,
    useGiftCardsQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { RootStackScreenProps } from "../../../navigation";
import { useAdminSession } from "../../../session";
import { AdminContainer } from "../../container";
import { AccountRow } from "./account-row";

import type { AvailableLocale } from "locales";
import {
    useAccessToken,
    useMultiSelect,
    useSearchDefinition,
} from "../../../hooks";
import { SearchDefinition } from "lib";
import { ImportGiftcardsModal } from "./import-modal";
import { ReissueGiftcardsModal } from "./reissue-giftcards-modal";
type ScreenProps = RootStackScreenProps<"ACCOUNTS">;

export function AccountsListScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.accounts");

    const theme = useTheme();
    const [t] = useTranslation();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);
    const { createSearchDefinition } = useSearchDefinition();
    const formRef = useRef<HTMLFormElement>(null);
    const accessToken = useAccessToken();
    const { render } = useModal();
    const toast = useToast();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    const styles = useThemedStyle(styleFunc);

    const defaultAccountSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        amount: createSearchDefinition("amount"),
        status: {
            name: t("searching.status", "Status"),
            type: "type",
            value: "OPEN",
            values: [
                { name: t("searching.open", "Open"), value: "OPEN" },
                { name: t("searching.closed", "Closed"), value: "CLOSED" },
            ],
            enabled: false,
        },
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultAccountSearch, {
        visible: true,
        buttonSize: "small",
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const [reissueGiftcards] = useAccountReissueGiftcardsMutation();

    const {
        selectedItems: selectedGiftcards,
        handleMultiSelect,
        allItemsSelected: allRowsSelected,
        setAllItemsSelected: setAllRowsSelected,
    } = useMultiSelect<GQAccountQuery["account"]["id"]>();

    // GraphQL
    const { data, loading, error, refetch } = useGiftCardsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    // Actions
    const handleView = useCallback(
        (id: string) => {
            navigate("ACCOUNT_VIEW", {
                id,
            });
        },
        [navigate]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate("ACCOUNT_EDIT", {
                id,
            });
        },
        [navigate]
    );

    function handleDownloadPress() {
        if (!formRef.current) {
            // DOM Form not ready
            return;
        }

        formRef.current.submit();
    }

    const handleImportPress = useCallback(async () => {
        await render(onClose => <ImportGiftcardsModal onClose={onClose} />);

        refetch();
    }, [refetch, render]);

    const handleReissueGiftcards = useCallback(async () => {
        const response = await render(onClose => (
            <ReissueGiftcardsModal
                onClose={onClose}
                selectedGiftcards={selectedGiftcards}
            />
        ));

        if (!response) {
            refetch();
            return;
        }

        if (selectedGiftcards.length === 0) {
            return;
        }

        const result = await reissueGiftcards({
            variables: { ids: selectedGiftcards },
        });

        if (result?.data?.accountReissueGiftcards.status) {
            toast.success(
                t(
                    "backoffice.giftcards.reissued",
                    "The giftcards are closed and the related tags were removed, so they can be reused."
                )
            );

            refetch();
        } else {
            toast.warning(
                t(
                    "backoffice.giftcards.reissue_failed",
                    "Reissuing giftcards failed."
                )
            );
        }
    }, [refetch, reissueGiftcards, render, selectedGiftcards, t, toast]);

    return (
        <AdminContainer>
            <View style={styles.container}>
                <View style={styles.actionButtonContainer}>
                    <Button
                        onPress={handleReissueGiftcards}
                        size="small"
                        variant="invert"
                        style={styles.button}
                        testID="reissueGiftcards"
                    >
                        {t(
                            "backoffice.gift_card.reissue_list",
                            "Reissue giftcards"
                        )}
                    </Button>
                    <Button
                        testID="importGiftcards"
                        style={styles.button}
                        variant="invert"
                        size="small"
                        onPress={handleImportPress}
                    >
                        {t("common.import", "Import")}
                    </Button>
                    <Button
                        testID="exportGiftcards"
                        style={styles.button}
                        variant="invert"
                        size="small"
                        onPress={handleDownloadPress}
                    >
                        {t("common.export", "Eksport")}
                    </Button>
                    <Button
                        testID="createGiftcard"
                        style={styles.button}
                        type="secondary"
                        size="small"
                        onPress={() => {
                            navigate("ACCOUNT_CREATE", {
                                type: "GIFT_CARD",
                            });
                        }}
                    >
                        {t("common.create", "Create")}
                    </Button>
                    <Spacer space={1} />
                    {searchIndicator}
                </View>
            </View>
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title style={styles.iconColumn}>
                            <CheckBox
                                value={allRowsSelected}
                                onValueChange={value => {
                                    const allItemIds = data!.giftCards.data.map(
                                        p => p.id
                                    );
                                    handleMultiSelect(value, allItemIds);
                                    setAllRowsSelected(value);
                                }}
                                testID="check:selectAll"
                            />
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "status" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("status")}
                        >
                            {t("common.status", "Status")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t("common.tag", "Tag")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "createdAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("createdAt")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title
                            numeric
                            sortDirection={
                                sortBy === "amount" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("amount")}
                        >
                            {t("common.balance", "Balance")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.sortIconColumn}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {error ? (
                        <Alert type="error">
                            {t(
                                "backoffice.error.from_server",
                                "There was an error: {{errorText}}",
                                {
                                    errorText: error.message,
                                }
                            )}
                        </Alert>
                    ) : loading ? (
                        <>
                            <Spacer />
                            <Loading />
                            <Spacer />
                        </>
                    ) : (
                        data?.giftCards.data.map((item, index) => (
                            <AccountRow
                                key={item.id}
                                item={item}
                                onView={() => handleView(item.id)}
                                onEdit={() => handleEdit(item.id)}
                                locale={locale as AvailableLocale}
                                currency={currency}
                                selected={selectedGiftcards.includes(item.id)}
                                onMultiSelectChange={handleMultiSelect}
                                style={
                                    index % 2 ? theme.styles.oddRow : undefined
                                }
                            />
                        ))
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.giftCards.pagination.pages}
                        itemCount={data?.giftCards.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
            <form
                target="_blank"
                ref={formRef}
                action="/api/export/giftcard"
                method="post"
            >
                <input type="hidden" name="language" value={locale} />
                <input
                    type="hidden"
                    name="token"
                    id="token"
                    value={accessToken}
                />
            </form>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        justifyContent: "space-between",
        marginBottom: theme.spacingScale * 2,
        flexWrap: "wrap",
    },
    actionButtonContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    checkboxContainer: {
        marginTop: theme.spacingScale,
    },
    button: {
        marginLeft: theme.spacingScale,
    },
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
    },
    sortIconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
    },
});
