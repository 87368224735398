import {
    Alert,
    ColorIndicator,
    DataTable,
    Loading,
    PageTitle,
    StyleFunction,
    Surface,
    useAuth,
    useMerchantConfig,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQCmpProductsQuery, useCmpProductsQuery } from "graphql-sdk";
import { AvailableLocale, useTranslation } from "locales";
import React, { useCallback } from "react";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { formatAmount, formatDateTime } from "lib";

type ScreenProps = RootStackScreenProps<"CMP_PRODUCTS">;

export function CmpProductListScreen(_: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["merchant.cmp"]);

    const styles = useThemedStyle(styleFunc);
    const [{ locale }] = useAdminSession(["locale"]);
    const { currency } = useMerchantConfig();
    const theme = useTheme();
    const [t] = useTranslation();

    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
        initialSortDirection: "DESC",
    });

    const { data, loading, error } = useCmpProductsQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    const renderCmpProductRow = useCallback(
        (item: GQCmpProductsQuery["cmpProducts"]["data"][0], rowIndex) => (
            <DataTable.Row
                style={rowIndex % 2 ? theme.styles.oddRow : undefined}
                key={`cmpProductId_${item.id}`}
            >
                <DataTable.Cell style={styles.iconColumn}>
                    <ColorIndicator
                        status={item.active ? "success" : "warning"}
                    />
                </DataTable.Cell>
                <DataTable.Cell>{item.name}</DataTable.Cell>
                <DataTable.Cell>{item.externalId}</DataTable.Cell>
                <DataTable.Cell>{item.type}</DataTable.Cell>
                <DataTable.Cell numeric>
                    {formatAmount(item.amount, currency, {
                        locale: locale as AvailableLocale,
                    })}
                </DataTable.Cell>
                <DataTable.Cell style={styles.sequenceColumn} numeric>
                    {item.sequence}
                </DataTable.Cell>
                <DataTable.Cell>
                    {formatDateTime(item.createdAt, locale as AvailableLocale)}
                </DataTable.Cell>
                <DataTable.Cell>
                    {formatDateTime(item.updatedAt, locale as AvailableLocale)}
                </DataTable.Cell>
            </DataTable.Row>
        ),
        [
            currency,
            locale,
            styles.iconColumn,
            styles.sequenceColumn,
            theme.styles.oddRow,
        ]
    );

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error?.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <PageTitle>
                {t("backoffice.cmp_product.cmp_products", "CMP products")}
            </PageTitle>
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "active" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("active")}
                            style={styles.iconColumn}
                        >
                            {t("backoffice.common.active", "Active")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("common.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "externalId"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("externalId")}
                        >
                            {t("backoffice.common.external_id", "External id")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "type" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("type")}
                        >
                            {t("backoffice.common.type", "Type")}
                        </DataTable.Title>
                        <DataTable.Title numeric>
                            {t("common.amount", "Amount")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sequenceColumn} numeric>
                            {t("backoffice.common.sequence", "Sequence")}
                        </DataTable.Title>{" "}
                        <DataTable.Title
                            sortDirection={
                                sortBy === "createdAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("createdAt")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "updatedAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("updatedAt")}
                        >
                            {t("common.updated_at", "Updated at")}
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : data.cmpProducts.data.length === 0 ? (
                        <DataTable.Row style={theme.styles.oddRow}>
                            <DataTable.Cell>
                                {t(
                                    "backoffice.cmp_product.no_data",
                                    "No products found."
                                )}
                            </DataTable.Cell>
                        </DataTable.Row>
                    ) : (
                        data?.cmpProducts.data.map(renderCmpProductRow)
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.cmpProducts.pagination.pages}
                        itemCount={data?.cmpProducts.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = () => ({
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 60 },
    sequenceColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 180 },
});
