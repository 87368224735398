import {
    Alert,
    Loading,
    StyleFunction,
    Surface,
    Tabs,
    useAuth,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    useCmpEventsLazyQuery,
    useCmpProductsLazyQuery,
    useLayoutLazyQuery,
    useLayoutSaveMutation,
    useProductGroupsLazyQuery,
} from "graphql-sdk";
import { GridDimensions, ILayoutInput, schemaEditLayout } from "lib";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "locales";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { rinseLayoutData } from "../functions";
import { LayoutForm } from "../layout-form";
import { LayoutScreen } from "../layout-screen";
import { LayoutEditor } from "./layout-editor";

type ScreenProps = RootStackScreenProps<"LAYOUT_EDIT">;

export function LayoutEditScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.layout.write");

    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const { handleMutationError } = useHandleMutationError();
    const [layoutEdit] = useLayoutSaveMutation();
    const layoutId = route.params.id;

    const [getLayout, layoutQuery] = useLayoutLazyQuery({
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const form = useForm<ILayoutInput>(schemaEditLayout, null);
    const [{ values }, { setDefaultValues }] = form;

    // Get CMP Event, CMP Product and POS Product groups for the layout provider.
    const [getCmpEvents, cmpEventsQuery] = useCmpEventsLazyQuery({
        fetchPolicy: "cache-first",
        errorPolicy: "all",
        variables: {
            pagination: {
                sort: "name",
                sortDirection: "ASC",
                pageSize: 999999,
            },
        },
    });

    const [getCmpProducts, cmpProductsQuery] = useCmpProductsLazyQuery({
        fetchPolicy: "cache-first",
        errorPolicy: "all",
        variables: {
            pagination: {
                sort: "name",
                sortDirection: "ASC",
                pageSize: 999999,
            },
        },
    });

    const [getProductGroups, productGroupsQuery] = useProductGroupsLazyQuery({
        fetchPolicy: "cache-first",
        errorPolicy: "all",
        variables: {
            pagination: {
                sort: "name",
                sortDirection: "ASC",
                pageSize: 999999,
            },
        },
    });

    useEffect(() => {
        if (!layoutId) {
            navigate("LAYOUTS");
        } else {
            getLayout({ variables: { id: layoutId } });
            getProductGroups();
            getCmpEvents();
            getCmpProducts();
        }
    }, [
        layoutId,
        getLayout,
        navigate,
        getProductGroups,
        getCmpEvents,
        getCmpProducts,
    ]);

    useEffect(() => {
        if (!layoutQuery.data?.layout) {
            return;
        }

        setDefaultValues({
            name: layoutQuery.data.layout.name,
            data: JSON.parse(layoutQuery.data.layout.data),
        });
    }, [layoutQuery.data, setDefaultValues]);

    const redirect = useCallback(() => {
        // If the page was loaded with a referrer, then return to that referrer.
        // The referrer could be e.g. CASH_REGISTER_EDIT with a specific id, but could also be to the general MERCHANT_SETTINGS,
        // so the id could be missing from the referrer argument
        if (!route.params.referrer || !route.params.referrer.route) {
            return;
        }

        if (route.params.referrer.route === "MERCHANT_SETTINGS") {
            navigate("MERCHANT_SETTINGS");
        }
    }, [route.params, navigate]);

    const handleSubmit = useCallback(async () => {
        if (!layoutQuery.data?.layout || !values) {
            return;
        }

        // remove metaData object from buttons. There is no need to save them.
        const updatedNewLayoutData = values.data.map(rinseLayoutData);

        await handleMutationError(
            async () => {
                await layoutEdit({
                    variables: {
                        id: layoutId,
                        layout: {
                            name: values.name,
                            data: JSON.stringify(updatedNewLayoutData),
                        },
                    },
                });
            },
            t("common.saved", "Saved"),
            // Redirect after saving, if there was a referrer.
            redirect
        );
    }, [
        handleMutationError,
        layoutQuery.data,
        layoutEdit,
        layoutId,
        redirect,
        t,
        values,
    ]);

    const gridDimensions: GridDimensions = useMemo(() => {
        if (!layoutQuery.data?.layout) {
            return { columns: 1, rows: 1 };
        }

        return {
            columns: layoutQuery.data.layout.columns,
            rows: layoutQuery.data.layout.rows,
        };
    }, [layoutQuery.data]);

    return (
        <LayoutScreen>
            <Surface style={styles.container}>
                {layoutQuery.error ? (
                    <Alert type="error">
                        {t(
                            "backoffice.error.from_server",
                            "There was an error: {{errorText}}",
                            {
                                errorText: layoutQuery.error.message,
                            }
                        )}
                    </Alert>
                ) : null}
                {productGroupsQuery.error ? (
                    <Alert type="error">
                        {t(
                            "backoffice.error.from_server",
                            "There was an error: {{errorText}}",
                            {
                                errorText: productGroupsQuery.error.message,
                            }
                        )}
                    </Alert>
                ) : null}
                {layoutQuery.loading ||
                productGroupsQuery.loading ||
                cmpEventsQuery.loading ? (
                    <Loading />
                ) : layoutQuery.data?.layout &&
                  cmpEventsQuery.data?.cmpEvents &&
                  productGroupsQuery.data?.productGroups ? (
                    <Tabs
                        labels={[
                            t("backoffice.layouts.layout", "Layout"),
                            t("backoffice.details", "Details"),
                        ]}
                    >
                        <LayoutEditor
                            form={form}
                            productData={layoutQuery.data.layout.products.map(
                                p => ({
                                    ...p,
                                    buttonText: p.buttonText || null,
                                })
                            )}
                            productGroupData={
                                productGroupsQuery.data?.productGroups?.data.map(
                                    pg => ({
                                        id: pg.id,
                                        color: pg.color,
                                    })
                                ) || []
                            }
                            cmpEvents={cmpEventsQuery.data?.cmpEvents?.data.map(
                                item => ({
                                    id: item.id,
                                    name: item.name,
                                    active: item.active,
                                    externalId: item.externalId,
                                    products: item.products,
                                })
                            )}
                            cmpProducts={cmpProductsQuery.data?.cmpProducts.data.map(
                                item => ({
                                    id: item.id,
                                    name: item.name,
                                    active: item.active,
                                    externalId: item.externalId,
                                    amount: item.amount,
                                })
                            )}
                            onSubmit={handleSubmit}
                            dimensions={gridDimensions}
                        />
                        <LayoutForm
                            form={form}
                            onSubmit={handleSubmit}
                            submitButton={["common.save", "Save"]}
                        />
                    </Tabs>
                ) : null}
            </Surface>
        </LayoutScreen>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    container: {
        maxWidth: Math.min(dimensions.width, 1350),
    },
});
