import { useTranslation } from "locales";
import type { IMenuItem } from "@venuepos/react-common";
import { useAdminSession } from "../session";

// Note: This used to be the preferred method of adding menu items.
// Please use the navigation-routes.tsx file instead and only this for custom stuff without routes
export function useAdminMenuItems(): IMenuItem[] {
    const [t] = useTranslation();
    const [{ locale }, setSession] = useAdminSession(["locale"]);

    return [
        {
            name: t("menu.language.menu", "Language"),
            iconName: "language",
            items: [
                locale !== "en"
                    ? {
                          name: t("menu.language.english", "🇬🇧 English"),
                          link: () => setSession("locale", "en"),
                          testID: "language:en",
                      }
                    : null,
                locale !== "da"
                    ? {
                          name: t("menu.language.danish", "🇩🇰 Danish"),
                          link: () => setSession("locale", "da"),
                          testID: "language:da",
                      }
                    : null,
            ].filter(Boolean),
            testID: "menu:languages",
        },
    ];
}
