import {
    ConfirmModal,
    contrastToBlack,
    getButtonColor,
    Loading,
    StyleFunction,
    useModal,
    useTheme,
    useThemedStyle,
    useToast,
} from "@venuepos/react-common";
import {
    ButtonPosition,
    CmpEventButton,
    CmpEventProductButton,
    CmpProductButton,
    Currency,
    FunctionButton,
    GridDimensions,
    ILayoutProduct,
    LayoutButton,
    LayoutButtonCreate,
    LayoutButtonMetaData,
    LayoutSection,
    ProductButton,
    ProductSectionButton,
} from "lib";
import { useTranslation } from "locales";
import React, { useCallback, useRef } from "react";
import {
    Animated,
    FlexStyle,
    StyleProp,
    StyleSheet,
    TextStyle,
    View,
    ViewStyle,
} from "react-native";
import { v4 as uuidv4 } from "uuid";

import { isArray } from "lodash";
import {
    createDndContext,
    Draggable as DraggableType,
} from "../../../components/drag-and-drop";
import { useLayoutButtons, useLayoutButtonsDispatch } from "../buttons-context";
import {
    getButtonPositionsForSection,
    getMaxDimensionsForCoordinate,
} from "../functions";
import { GridEditorDroppable } from "./grid-editor-droppable";
import { LayoutButtonComponent } from "./layout-button";
import {
    CmpEventModalOutput,
    CmpEventProductModalOutput,
    CmpProductModalOutput,
    FunctionButtonModalOutput,
    SingleProductModalOutput,
    useAddButtonModal,
} from "./modal/add-button";
import { SendToSectionModal } from "./modal/send-to-section";
import {
    ProductLayoutAction,
    ProductLayoutActionType,
    productLayoutReducer,
} from "./product-layout-operation-reducer";

const UI_HEIGHT = 350;
const GRID_HEIGHT = 600;
export const GRID_MARGIN = 5;

// The opacity of the draggable element
const transparentDraggableColor = 0.625;
const opaqueDraggableColor = 1;

export function GridEditor({
    dimensions,
    width,
    height,
    testID,
}: {
    dimensions: GridDimensions;
    width: number;
    height: number;
    currency: Currency;
    testID?: string;
}) {
    const [t] = useTranslation();
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);
    const { render } = useModal();
    const toast = useToast();
    const renderAddButtonModal = useAddButtonModal();

    const {
        Provider: DragAndDropProvider,
        Droppable,
        Draggable,
    } = createDndContext();

    const dispatch = useLayoutButtonsDispatch();
    const layoutData = useLayoutButtons();

    const draggableOpacity = useRef(1);
    const handleDragStart = () => {
        draggableOpacity.current = transparentDraggableColor;
    };

    const handleDragEnd = () => {
        draggableOpacity.current = opaqueDraggableColor;
    };

    const handleDropButton = useCallback(
        (position: ButtonPosition, buttonId: LayoutButton["id"]) => {
            if (!dispatch || !layoutData) {
                return;
            }

            const dispatchPayload: ProductLayoutActionType = {
                type: ProductLayoutAction.MOVE_BUTTON_IN_SECTION,
                position,
                buttonId,
            };

            dispatch(dispatchPayload);

            // Callback to update the form values.
            return layoutData.onLayoutUpdate(
                productLayoutReducer(layoutData, dispatchPayload).sections
            );
        },
        [dispatch, layoutData]
    );

    const handleDeleteButton = useCallback(
        async (buttonId: LayoutButton["id"]) => {
            if (!dispatch || !layoutData) {
                return;
            }

            if (!buttonId) {
                return;
            }

            if (
                !(await render(onClose => (
                    <ConfirmModal
                        headerText={t(
                            "backoffice.layout.delete_button_header",
                            "Delete button?"
                        )}
                        bodyText={t(
                            "backoffice.layout.delete_button_body",
                            "Are you sure you want delete this button?"
                        )}
                        onClose={onClose}
                    />
                )))
            ) {
                return;
            }

            dispatch({
                type: ProductLayoutAction.REMOVE_BUTTON_FROM_SECTION,
                buttonId,
            });

            // Callback to update the form values.
            return layoutData.onLayoutUpdate(
                productLayoutReducer(layoutData, {
                    type: ProductLayoutAction.REMOVE_BUTTON_FROM_SECTION,
                    buttonId,
                }).sections
            );
        },
        [dispatch, layoutData, render, t]
    );

    const handleMoveButtonToSection = useCallback(
        async (buttonId: LayoutButton["id"]) => {
            if (!dispatch || !layoutData) {
                return;
            }

            if (!buttonId) {
                return;
            }

            const sections = !layoutData
                ? []
                : layoutData.sections.filter(
                      (sectionItr: LayoutSection) =>
                          sectionItr.id !==
                              layoutData.sections[
                                  layoutData.currentSectionIndex
                              ].id && // skip the current section
                          getButtonPositionsForSection(
                              sectionItr.buttons,
                              layoutData.dimensions
                          ).filter(positionItr => positionItr === "").length > 0
                  ); // skip this section, if the section is full

            const sendToResult: LayoutSection["id"] | "NEW" | undefined =
                await render(onClose => (
                    <SendToSectionModal onClose={onClose} sections={sections} />
                ));

            if (!sendToResult) {
                return;
            }

            const dispatchPayload: ProductLayoutActionType = {
                type: ProductLayoutAction.MOVE_BUTTON_TO_SECTION,
                buttonId,
                toSection: sendToResult,
                translationFunction: t,
            };

            dispatch(dispatchPayload);

            // Callback to update the form values.
            return layoutData.onLayoutUpdate(
                productLayoutReducer(layoutData, dispatchPayload).sections
            );
        },
        [dispatch, layoutData, render, t]
    );

    const handleShowButtonSettings = useCallback(
        async (
            buttonData: ProductSectionButton | LayoutButtonCreate,
            buttonMetaData: LayoutButtonMetaData
        ) => {
            if (!dispatch || !layoutData) {
                return;
            }

            const editButtonResult = await renderAddButtonModal({
                button: buttonData,
                buttonMetaData,
                addedProducts: layoutData.knownProducts.map(itr => itr.id),
                product: layoutData.knownProducts.find(
                    productItr =>
                        productItr.id ===
                        (buttonData as ProductButton).productId
                ),
            });

            if (!editButtonResult || !editButtonResult.buttonType) {
                return;
            }

            // There is an array of products, that should be added to the section. Do it, and then back off.
            if (
                editButtonResult.buttonType === "PRODUCT" &&
                editButtonResult.formValues === null &&
                isArray(editButtonResult.productData)
            ) {
                const dispatchPayload: ProductLayoutActionType = {
                    type: ProductLayoutAction.ADD_PRODUCTS_TO_SECTION,
                    products: editButtonResult.productData,
                    startingPosition: { x: buttonData.x, y: buttonData.y },
                    translationFunction: t,
                };

                dispatch(dispatchPayload);

                toast.success(
                    t(
                        "backoffice.layout.product_added_success",
                        "Added {{ count }} products to the layout",
                        { count: editButtonResult.productData.length }
                    )
                );

                return layoutData.onLayoutUpdate(
                    productLayoutReducer(layoutData, dispatchPayload).sections
                );
            }

            // Merge the existing button data and the values from the modal and handle the result:
            let newButton = {
                ...buttonData,
                ...editButtonResult.formValues,
                buttonType: editButtonResult.buttonType,
            };

            if (!newButton.id) {
                newButton.id = uuidv4();

                const dispatchPayload: ProductLayoutActionType = {
                    type: ProductLayoutAction.ADD_BUTTON_TO_SECTION, // if the button has no id, then add it to the current section ...
                    newButton:
                        newButton.buttonType === "PRODUCT"
                            ? (newButton as ProductButton)
                            : newButton.buttonType === "FUNCTION"
                            ? (newButton as FunctionButton)
                            : newButton.buttonType === "CMP_EVENT"
                            ? (newButton as CmpEventButton)
                            : newButton.buttonType === "CMP_EVENT_PRODUCT"
                            ? (newButton as CmpEventProductButton)
                            : newButton.buttonType === "CMP_PRODUCT"
                            ? (newButton as CmpProductButton)
                            : null,
                    productData:
                        editButtonResult.buttonType === "PRODUCT" // Include the product data if this button is a product button
                            ? (editButtonResult.productData as ILayoutProduct)
                            : undefined,
                    displayValues:
                        // Only some of the output types from the modals have display values, so we have to cast them to get them right.
                        (editButtonResult as SingleProductModalOutput)
                            .displayValues ||
                        (editButtonResult as CmpProductModalOutput)
                            .displayValues ||
                        (editButtonResult as CmpEventModalOutput)
                            .displayValues ||
                        (editButtonResult as CmpEventProductModalOutput)
                            .displayValues ||
                        (editButtonResult as FunctionButtonModalOutput)
                            .displayValues ||
                        undefined,

                    translationFunction: t,
                };

                dispatch(dispatchPayload);

                // Callback to update the form values.
                return layoutData.onLayoutUpdate(
                    productLayoutReducer(layoutData, dispatchPayload).sections
                );
            }

            const updateButtonDispatchPayload: ProductLayoutActionType = {
                type: ProductLayoutAction.UPDATE_BUTTON_IN_SECTION,
                updatedButton:
                    newButton.buttonType === "PRODUCT"
                        ? (newButton as ProductButton)
                        : newButton.buttonType === "FUNCTION"
                        ? (newButton as FunctionButton)
                        : newButton.buttonType === "CMP_PRODUCT"
                        ? (newButton as CmpProductButton)
                        : newButton.buttonType === "CMP_EVENT"
                        ? (newButton as CmpEventButton)
                        : newButton.buttonType === "CMP_EVENT_PRODUCT"
                        ? (newButton as CmpEventProductButton)
                        : null,
                productData:
                    editButtonResult.buttonType === "PRODUCT" // Include the product data if this button is a product button
                        ? (editButtonResult.productData as ILayoutProduct)
                        : undefined,
                displayValues:
                    // Only some of the output types from the modals have display values, so we have to cast them to get them right.
                    (editButtonResult as SingleProductModalOutput)
                        .displayValues ||
                    (editButtonResult as CmpProductModalOutput).displayValues ||
                    (editButtonResult as CmpEventModalOutput).displayValues ||
                    (editButtonResult as CmpEventProductModalOutput)
                        .displayValues ||
                    (editButtonResult as FunctionButtonModalOutput)
                        .displayValues ||
                    undefined,
                translationFunction: t,
            };

            dispatch(updateButtonDispatchPayload);

            // Callback to update the form values.
            return layoutData.onLayoutUpdate(
                productLayoutReducer(layoutData, updateButtonDispatchPayload)
                    .sections
            );
        },
        [dispatch, layoutData, renderAddButtonModal, t, toast]
    );

    const handleShowButtonById = useCallback(
        async (
            buttonId:
                | (FunctionButton | ProductButton | LayoutButtonCreate)["id"]
        ) => {
            if (!layoutData || !buttonId) {
                return;
            }

            const foundButtonIndex = layoutData.sections[
                layoutData.currentSectionIndex
            ].buttons.findIndex(button => button.id === buttonId);

            if (foundButtonIndex === -1) {
                console.error(
                    "Invalid input for handleShowButtonModal. Requested button",
                    buttonId,
                    "not found. Back off."
                );
                toast.warning(
                    t(
                        "backoffice.layout.warning_button_not_found",
                        "An error occured: The button was not found. Perhaps you could try a reload?"
                    )
                );
                return;
            }

            const button =
                layoutData.sections[layoutData.currentSectionIndex].buttons[
                    foundButtonIndex
                ];

            const maxDimensions = getMaxDimensionsForCoordinate(
                { x: button.x, y: button.y },
                dimensions,
                layoutData.sections[layoutData.currentSectionIndex].buttons
            );

            const dimensionsMetaData: LayoutButtonMetaData = {
                maxWidthValue: maxDimensions.width,
                maxHeightValue: maxDimensions.height,
            };

            let buttonMetaData: LayoutButtonMetaData;
            if (layoutData.buttonMetaData[buttonId]) {
                buttonMetaData = {
                    ...layoutData.buttonMetaData[buttonId],
                    ...dimensionsMetaData,
                };
            } else {
                buttonMetaData = dimensionsMetaData;
            }

            return handleShowButtonSettings(button, buttonMetaData);
        },
        [dimensions, handleShowButtonSettings, layoutData, t, toast]
    );

    const handleAddButton = useCallback(
        async (buttonPosition: ButtonPosition) => {
            if (!layoutData) {
                return;
            }

            const maxDimensions = getMaxDimensionsForCoordinate(
                buttonPosition,
                dimensions,
                layoutData.sections[layoutData.currentSectionIndex].buttons
            );

            let newLayoutButton: LayoutButtonCreate = {
                id: null,
                x: buttonPosition.x,
                y: buttonPosition.y,
                height: 1,
                width: 1,
                color: "",
                label: "",
                buttonType: null,
            };

            await handleShowButtonSettings(newLayoutButton, {
                maxWidthValue: maxDimensions.width || 1,
                maxHeightValue: maxDimensions.height || 1,
            });
        },
        [dimensions, handleShowButtonSettings, layoutData]
    );

    const widthMultiplier = width / dimensions.columns;
    const heightMultiplier = height / dimensions.rows;

    const droppableHolders = useCallback(() => {
        if (!dispatch || !layoutData) {
            return;
        }

        const droppableHolder = (
            position: ButtonPosition,
            // cellData: LayoutButtonMetaData,
            cellStyle: StyleProp<ViewStyle>
        ) => (
            <View
                style={[styles.positionAbsolute, styles.gridCell, cellStyle]}
                key={`droppable_cell_${position.x}_${position.y}`}
            >
                <Droppable
                    onDrop={(droppedElement: DraggableType) =>
                        handleDropButton(position, droppedElement.payload.id)
                    }
                >
                    {({ active, viewProps }) => (
                        <Animated.View
                            {...viewProps}
                            style={[
                                styles.flexContainer,
                                styles.droppableStyle,
                                active ? styles.activeDroppable : null,
                                viewProps.style,
                            ]}
                        >
                            <GridEditorDroppable
                                onPress={async () =>
                                    await handleAddButton(position)
                                }
                                testID={`layout:${position.x}:${position.y}`}
                            />
                        </Animated.View>
                    )}
                </Droppable>
            </View>
        );

        let elements = [];
        let cellWidth: number = widthMultiplier - GRID_MARGIN;
        let cellHeight: number = heightMultiplier - GRID_MARGIN;

        for (let _y = 0; _y < dimensions.rows; _y++) {
            for (let _x = 0; _x < dimensions.columns; _x++) {
                let cellStyle: StyleProp<ViewStyle> = {
                    left: _x * widthMultiplier,
                    top: _y * heightMultiplier,
                    height: cellHeight,
                    width: cellWidth,
                };

                const position = {
                    x: _x,
                    y: _y,
                };

                elements.push(droppableHolder(position, cellStyle));
            }
        }

        return elements;
    }, [
        dispatch,
        layoutData,
        styles.positionAbsolute,
        styles.gridCell,
        styles.flexContainer,
        styles.droppableStyle,
        styles.activeDroppable,
        Droppable,
        handleDropButton,
        handleAddButton,
        dimensions,
        widthMultiplier,
        heightMultiplier,
    ]);

    if (!layoutData) {
        return <Loading />;
    }

    return (
        <View style={styles.gridContainerParent} testID={testID}>
            <View style={styles.flexContainer}>
                <DragAndDropProvider>
                    {droppableHolders()}
                    {layoutData.sections[
                        layoutData.currentSectionIndex
                    ].buttons.map((button, buttonIndex) => {
                        if (!button.id) {
                            return null;
                        }

                        const visualWidth = button.width * widthMultiplier;
                        const visualHeight = button.height * heightMultiplier;

                        const buttonDimensionStyle: StyleProp<
                            Pick<FlexStyle, "width" | "height">
                        > = {
                            height: visualHeight - 2 * GRID_MARGIN,
                            width: visualWidth - 2 * GRID_MARGIN,
                        };
                        const buttonPositionStyle: StyleProp<ViewStyle> = {
                            left: button.x * widthMultiplier + GRID_MARGIN / 2,
                            top: button.y * heightMultiplier + GRID_MARGIN / 2,
                        };

                        const styleProps: StyleProp<ViewStyle> = [
                            buttonDimensionStyle,
                        ];

                        const buttonColor = getButtonColor(
                            button.color,
                            layoutData.buttonMetaData[button.id]?.color
                        );

                        styleProps.push({
                            backgroundColor:
                                layoutData.buttonMetaData[button.id]?.color,
                        });

                        const textStyle: StyleProp<TextStyle> = {
                            color: contrastToBlack(buttonColor)
                                ? theme.colors.textLight
                                : theme.colors.textDark,
                        };
                        return (
                            <View
                                key={`draggable_container_${button.id}`}
                                testID={`layout:draggable:${buttonIndex}`}
                                style={StyleSheet.flatten([
                                    styles.positionAbsolute,
                                    buttonDimensionStyle,
                                    buttonPositionStyle,
                                ])}
                            >
                                <Draggable
                                    customId={`draggable_${button.id}`}
                                    payload={button}
                                    onDragStart={handleDragStart}
                                    onDragEnd={handleDragEnd}
                                >
                                    {({ viewProps }) => {
                                        const styleObj = [
                                            {
                                                opacity:
                                                    draggableOpacity.current,
                                            },
                                            viewProps.style,
                                        ];

                                        // Get the formatted amount from the buttonMetaData array (only some of the button types pre-format the amount) or format it now.
                                        // If there is nothing in the metadata array, then format the amount from the button - if there is one.
                                        const formattedAmount =
                                            layoutData.buttonMetaData[button.id]
                                                ?.formattedAmount;

                                        return (
                                            <Animated.View
                                                {...viewProps}
                                                style={styleObj}
                                            >
                                                <LayoutButtonComponent
                                                    label={
                                                        layoutData
                                                            .buttonMetaData[
                                                            button.id
                                                        ]?.text ||
                                                        t(
                                                            "backoffice.layout.no_text",
                                                            "No text"
                                                        )
                                                    }
                                                    button={button}
                                                    formattedAmount={
                                                        formattedAmount
                                                    }
                                                    showWarning={
                                                        layoutData
                                                            .buttonMetaData[
                                                            button.id
                                                        ]
                                                            .missingConfigurationData
                                                    }
                                                    textStyle={textStyle}
                                                    buttonStyle={styleProps}
                                                    onPress={async () =>
                                                        await handleShowButtonById(
                                                            button.id
                                                        )
                                                    }
                                                    onDelete={async () =>
                                                        await handleDeleteButton(
                                                            button.id
                                                        )
                                                    }
                                                    onSendTo={() =>
                                                        handleMoveButtonToSection(
                                                            button.id
                                                        )
                                                    }
                                                    testID={`layout:button:label:${
                                                        layoutData
                                                            .buttonMetaData[
                                                            button.id
                                                        ]?.text
                                                    }`}
                                                />
                                            </Animated.View>
                                        );
                                    }}
                                </Draggable>
                            </View>
                        );
                    })}
                </DragAndDropProvider>
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    flexContainer: {
        flex: 1,
    },
    positionAbsolute: {
        position: "absolute",
    },
    gridContainerParent: {
        // The editor grid should be at least GRID_HEIGHT high. If the screen is taller than that, then use the full space.
        // The UI_HEIGHT, that is deducted, is the approximate height of the top bar and remaining UI in the screen.
        minHeight:
            dimensions.height - UI_HEIGHT < GRID_HEIGHT
                ? GRID_HEIGHT
                : dimensions.height - UI_HEIGHT,
    },
    gridCell: {
        padding: GRID_MARGIN / 2,
    },
    droppableStyle: {
        borderColor: "transparent",
        borderStyle: "dashed",
        borderWidth: 2,
    },
    activeDroppable: {
        borderColor: theme.colors.success,
        borderStyle: "dashed",
        borderWidth: 2,
        borderRadius: theme.borderRadiusTiny,
    },
});
