import { t } from "i18next";
import {
    ProductButton,
    LayoutButtonMetaData,
    ILayoutProduct,
    baseButtonColor,
} from "lib";

export const handleProductButtonMetaData = (
    button: ProductButton,
    buttonMetaData: Record<string, LayoutButtonMetaData>,
    knownProducts: ILayoutProduct[],
    localFormatAmount: (amount: number) => string
): Record<string, LayoutButtonMetaData> => {
    if (button.buttonType !== "PRODUCT" || !button.id) {
        return buttonMetaData;
    }

    const buttonProduct = knownProducts.find(
        productItr => productItr.id === (button as ProductButton).productId
    );
    if (!buttonProduct) {
        // Product not found for id. Back off. Button will not be shown in layout.
        return buttonMetaData;
    }

    // Get product data and update metadata for button
    buttonMetaData[button.id] = {
        ...buttonMetaData[button.id],

        color: button.color || buttonProduct.group.color || baseButtonColor,
        text:
            button.label ||
            buttonProduct.buttonText ||
            buttonProduct.name ||
            t("backoffice.layout.no_text", "No text"),
        formattedAmount: localFormatAmount(buttonProduct.amount),
    };

    return buttonMetaData;
};
