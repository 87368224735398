import {
    DataTable,
    IconButton,
    StyleFunction,
    useThemedStyle,
} from "@venuepos/react-common";
import type { GQCardTransactionsQuery } from "graphql-sdk";
import { formatAmount, formatDateTime, SupportedCurrenciesType } from "lib";
import { useTranslation, type AvailableLocale } from "locales";
import { StyleProp, View, ViewStyle } from "react-native";
import React from "react";

export function CardTransactionRow({
    item,
    currency,
    locale,
    onSelect,
    onGoToInvoice,
    style,
}: {
    item: GQCardTransactionsQuery["cardTransactions"]["data"][0];
    currency: SupportedCurrenciesType;
    locale: string;
    onSelect: () => void;
    onGoToInvoice: () => void;
    style?: StyleProp<ViewStyle>;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    return (
        <DataTable.Row onPress={onSelect} style={style}>
            <DataTable.Cell>{item.cashRegister.name}</DataTable.Cell>
            <DataTable.Cell>{item.status}</DataTable.Cell>
            <DataTable.Cell>{item.scheme}</DataTable.Cell>
            <DataTable.Cell>{item.maskedPan}</DataTable.Cell>
            <DataTable.Cell numeric>
                {formatAmount(item.amount, currency, {
                    locale: locale as AvailableLocale,
                })}
            </DataTable.Cell>
            <DataTable.Cell numeric>
                {formatDateTime(item.transactionAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                {item.receipt ? (
                    <IconButton
                        color={styles.iconButton.color}
                        name="order"
                        onPress={onSelect}
                        tooltip={t(
                            "backoffice.card_transaction.view_transaction",
                            "Go to transaction"
                        )}
                    />
                ) : (
                    <View style={styles.iconPlaceholder} />
                )}
                {item.invoiceId ? (
                    <IconButton
                        color={styles.iconButton.color}
                        name="receipt"
                        onPress={onGoToInvoice}
                        tooltip={t(
                            "backoffice.card_transaction.view_invoice",
                            "Go to invoice"
                        )}
                    />
                ) : (
                    <View style={styles.iconPlaceholder} />
                )}
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styleFunc: StyleFunction = theme => ({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: theme.spacingScale * 8,
    },
    iconButton: {
        color: theme.colors.secondary,
    },

    iconPlaceholder: {
        width: 28.5, // The width of the other icons in the row.
        height: 0,
    },
});
