import {
    DataTable,
    Icon,
    Loading,
    Spacer,
    StyleFunction,
    Surface,
    useAuth,
    usePagination,
    useSearch,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQPrintersQuery,
    usePrinterDeleteMutation,
    usePrintersQuery,
} from "graphql-sdk";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";

import { useHandleMutationError } from "../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../navigation";
import { AdminContainer } from "../container";
import { PrinterRow } from "./printer-row";
import { SearchDefinition } from "lib";
import { View } from "react-native";
import { useSearchDefinition } from "../../hooks";

type ScreenProps = RootStackScreenProps<"PRINTERS">;

export function Printers({ navigation: { navigate }, route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.printer");

    const theme = useTheme();
    const [t] = useTranslation();
    const { createSearchDefinition } = useSearchDefinition();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
    });

    const styles = useThemedStyle(styleFunc);
    const defaultPrinterSearch: SearchDefinition = {
        name: createSearchDefinition("name"),
        type: {
            name: t("searching.type", "Type"),
            type: "string",
            value: "",
            enabled: false,
        },
        manufacturer: {
            name: t("searching.manufacturer", "Manufacturer"),
            type: "string",
            value: "",
            enabled: false,
        },
        serial: {
            name: t("searching.serial", "Serial"),
            type: "string",
            value: "",
            enabled: false,
        },
        createdAt: createSearchDefinition("createdAt"),
    };

    const {
        component: searchComponent,
        indicator: searchIndicator,
        search,
    } = useSearch(defaultPrinterSearch, {
        visible: true,
        onSubmit: () => {
            // When the search form is submitted, then return the pagination to the first page.
            onPageChange(0);
        },
    });

    const [deletePrinter] = usePrinterDeleteMutation();
    const { handleMutationError } = useHandleMutationError();

    // GraphQL
    const { data, loading, refetch } = usePrintersQuery({
        variables: {
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
            searching: search,
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (route.params?.refetch) {
            refetch();
        }
    }, [refetch, route.params?.refetch]);

    const handleView = useCallback(
        (id: string) => {
            navigate("PRINTER_EDIT", {
                id,
            });
        },
        [navigate]
    );

    const handleDelete = useCallback(
        async (id: string) => {
            await handleMutationError(
                async () => await deletePrinter({ variables: { id } }),
                t("backoffice.printer.deleted", "Printer deleted"),
                async () => {
                    await refetch();
                }
            );
        },
        [deletePrinter, handleMutationError, refetch, t]
    );

    const renderPrinterRow = useCallback(
        (item: GQPrintersQuery["printers"]["data"][0], index: number) => (
            <PrinterRow
                key={item.id}
                item={item}
                onSelect={handleView}
                onDelete={handleDelete}
                style={index % 2 ? theme.styles.oddRow : null}
            />
        ),
        [handleDelete, handleView, theme.styles.oddRow]
    );

    return (
        <AdminContainer>
            <View style={[theme.styles.row, styles.container]}>
                {searchIndicator}
            </View>
            <Spacer space={2} />
            <Surface>
                {searchComponent}
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("backoffice.printer.name.header", "Name")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "manufacturer"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("manufacturer")}
                            style={styles.smallColumn}
                        >
                            {t(
                                "backoffice.printer.manufacturer.header",
                                "Manufacturer"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "type" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("type")}
                            style={styles.smallColumn}
                        >
                            {t("backoffice.printer.type.header", "Type")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "serial" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("serial")}
                        >
                            {t("backoffice.printer.serial.header", "Serial")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "class" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("class")}
                            style={styles.smallColumn}
                        >
                            {t("backoffice.printer.class.header", "Class")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "cashRegisterName"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("cashRegisterName")}
                        >
                            {t(
                                "backoffice.printer.cash_register.header",
                                "Cash register"
                            )}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "createdAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("createdAt")}
                        >
                            {t("backoffice.printer.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title numeric style={styles.iconColumn}>
                            <Icon name="sort" color={theme.colors.black} />
                        </DataTable.Title>
                    </DataTable.Header>
                    {!loading ? (
                        data?.printers.data.map(renderPrinterRow)
                    ) : (
                        <Loading />
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.printers.pagination.pages}
                        itemCount={data?.printers.pagination.resultCount}
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = () => ({
    container: {
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 40 },
    smallColumn: {
        flexBasis: 140,
        flexGrow: 0,
    },
});
