import React, { View } from "react-native";
import { useTranslation } from "locales";
import {
    useThemedStyle,
    Button,
    StyleFunction,
    useTheme,
} from "@venuepos/react-common";
import { ProductLayoutButtonType } from "lib";

/**
 * Show the list of possible button types and let the user choose one of them
 */
export function ButtonTypeSelector({
    hasCmpIntegration,
    onButtonTypeSelect,
}: {
    hasCmpIntegration: boolean;
    onButtonTypeSelect: (buttonType: ProductLayoutButtonType) => void;
}) {
    const [t] = useTranslation();
    const theme = useTheme();
    const styles = useThemedStyle(styleFunc);

    return (
        <View testID="layout:add">
            <View style={styles.buttons}>
                <View style={styles.row}>
                    <View style={theme.styles.flex1}>
                        <Button
                            type="primary"
                            onPress={() => {
                                onButtonTypeSelect("PRODUCT");
                            }}
                            iconName="products"
                            testID="layout:addProduct"
                        >
                            {t(
                                "layout.add_product_button",
                                "Add product button"
                            )}
                        </Button>
                    </View>
                    <View style={theme.styles.flex1}>
                        <Button
                            onPress={() => {
                                onButtonTypeSelect("FUNCTION");
                            }}
                            iconName="cashRegister"
                            testID="layout:addFunctionButton"
                        >
                            {t(
                                "layout.add_function_button",
                                "Add function button"
                            )}
                        </Button>
                    </View>
                </View>
                {hasCmpIntegration ? (
                    <View style={styles.row}>
                        <View style={theme.styles.flex1}>
                            <Button
                                onPress={() => {
                                    onButtonTypeSelect("CMP_EVENT");
                                }}
                                iconName="calendarClock"
                                testID="layout:addCmpEventButton"
                            >
                                {t(
                                    "layout.add_cmp_event_button",
                                    "CMP Event button"
                                )}
                            </Button>
                        </View>
                        <View style={theme.styles.flex1}>
                            <Button
                                onPress={() => {
                                    onButtonTypeSelect("CMP_EVENT_PRODUCT");
                                }}
                                iconName="ticket"
                                testID="layout:addCmpEventProductButton"
                            >
                                {t(
                                    "layout.add_cmp_event_product_button",
                                    "CMP Event Product button"
                                )}
                            </Button>
                        </View>
                    </View>
                ) : null}
                {hasCmpIntegration ? (
                    <View style={styles.row}>
                        <View style={theme.styles.flex1}>
                            <Button
                                onPress={() => {
                                    onButtonTypeSelect("CMP_PRODUCT");
                                }}
                                iconName="box"
                                testID="layout:addCmpProductButton"
                            >
                                {t(
                                    "layout.add_cmp_product_button",
                                    "CMP Product button"
                                )}
                            </Button>
                        </View>
                        <View style={styles.button} />
                    </View>
                ) : null}
            </View>
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    buttons: {
        rowGap: theme.spacingScale * 3,
    },
    row: {
        ...theme.styles.row,
        columnGap: theme.spacingScale,
    },
    button: {
        flex: 1,
    },
});
