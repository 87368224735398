import { Picker as PickerImpl } from "@react-native-picker/picker";
import React from "react";
import { InputLabel, RequiredIndicator } from "..";
import { useThemedStyle } from "../..";
import { pickerStyleFunc } from "./styles";
import { PickerItemProps, PickerProps } from "./types";

export function Picker(props: PickerProps) {
    const styles = useThemedStyle(pickerStyleFunc);

    return (
        <>
            {props.label && (
                <InputLabel>
                    {props.label} {props.required && <RequiredIndicator />}
                </InputLabel>
            )}
            <PickerImpl
                {...props}
                style={[
                    styles.picker,
                    props.style,
                    props.disabled ? styles.disabled : undefined,
                ]}
                itemStyle={[styles.itemStyle]}
                enabled={!props.disabled}
                selectedValue={
                    typeof props.selectedValue === "string" ||
                    typeof props.selectedValue === "number"
                        ? String(props.selectedValue)
                        : ""
                }
                onValueChange={props.onValueChange}
            />
        </>
    );
}

Picker.Item = (props: PickerItemProps) => <PickerImpl.Item {...props} />;
