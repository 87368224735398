import type {
    BuyAccountFundsButtonType,
    CustomItemButtonType,
    DefaultFunctionButtonType,
    FunctionButtonInput,
    ILayoutCreate,
    ILayoutInput,
    PluSearchButtonType,
    ValidationSchema,
    VenueAccessTicketButtonType,
} from "..";

export const schemaLayout: ValidationSchema<ILayoutCreate> = {
    name: { required: true },
    data: {},
    dimensions: { required: true },
};

export const schemaEditLayout: ValidationSchema<ILayoutInput> = {
    name: { required: true },
    data: {},
};

// Function button validations

export const customItemButtonSchema: ValidationSchema<CustomItemButtonType> = {
    function: { required: true },
    label: { required: true },
    productGroupId: { required: true },
};

export const buyAccountFundsButtonSchema: ValidationSchema<BuyAccountFundsButtonType> =
    {
        function: { required: true },
        label: { required: true },
        amount: { required: true, number: true, minValue: { value: 0 } },
    };

export const pluSearchButtonSchema: ValidationSchema<PluSearchButtonType> = {
    function: { required: true },
    label: { required: true },
};

export const venueAccessTicketButtonSchema: ValidationSchema<VenueAccessTicketButtonType> =
    {
        function: { required: true },
        label: { required: true },
        venueAccessEvent: {
            required: true,
            number: true,
            minValue: { value: 0 },
        },
        venueAccessTypes: { required: true, minLength: { length: 1 } },
        amount: { required: true, number: true, minValue: { value: 0 } },
    };

export const defaultFunctionButton: DefaultFunctionButtonType = {
    buttonType: "FUNCTION",
    function: null,
    label: "",
    color: "",
    x: 0,
    y: 0,
    width: 1,
    height: 1,
    id: "",
};

export const defaultFunctionButtonSchema: ValidationSchema<FunctionButtonInput> =
    {
        function: { required: true },
    };
