import {
    ModalWrapper,
    StyleFunction,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import React from "react";
import { useTranslation } from "locales";
import { JsonView, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { ScrollView } from "react-native";

export function CmpEventProductCalendar({
    onClose,
    output,
}: {
    onClose: (val: boolean) => void;
    output: string;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();

    return (
        <ModalWrapper
            onClose={onClose}
            title={t("backoffice.cmp_event.calendar", "Calendar")}
        >
            <ScrollView style={styles.container}>
                {isJSON(output) ? (
                    <JsonView
                        data={JSON.parse(output)}
                        shouldExpandNode={(level: number) => level < 2}
                        style={defaultStyles}
                    />
                ) : (
                    <Text style={styles.textOutput}>{output}</Text>
                )}
            </ScrollView>
        </ModalWrapper>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    container: {
        minHeight: 400,
        maxHeight: dimensions.height * 0.8,
        minWidth: theme.dimensions.maxFormWidth,
        maxWidth: dimensions.width * 0.8,
        margin: theme.spacingScale,
    },
    textOutput: {
        padding: theme.spacingScale,
        backgroundColor: theme.colors.grey100,
    },
});

function isJSON(str: string) {
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
}
