import { Divider } from "react-native-paper";
import {
    Button,
    ColorPickerInput,
    Form,
    InputControl,
    Loading,
    Picker,
    Spacer,
    StyleFunction,
    TextInput,
    useForm,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    ButtonDimensions,
    CmpEventProductButton,
    formatAmount,
    LayoutButtonMetaData,
    ValidationErrors,
    ValidationSchema,
} from "lib";
import React, { View } from "react-native";
import { ButtonSizeForm } from "../../../../button-size-form";
import { CmpEventProductModalOutput } from "..";
import { useCallback } from "react";
import { AvailableLocale, useTranslation } from "locales";
import {
    GQCmpEventProductsQuery,
    GQCmpEventsQuery,
    useCmpEventProductsQuery,
    useCmpEventsQuery,
} from "graphql-sdk";
import { useAdminSession } from "../../../../../../session";
import { schemaButtonDimensions } from "../validation";

type CmpEventProductButtonInput = {
    eventId: string;
    productId: string;
    color: string;
    label: string;
};

const schemaCmpEventProductButton: ValidationSchema<CmpEventProductButtonInput> =
    {
        eventId: { required: true },
        productId: { required: true },
        color: { required: true, color: true },
    };

export function CmpEventProductForm({
    metaData,
    values,
    onSubmit,
}: {
    values: CmpEventProductButtonInput & ButtonDimensions;
    metaData: LayoutButtonMetaData;
    onSubmit: (values?: CmpEventProductModalOutput) => void;
}) {
    const [t] = useTranslation();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);

    const dimensionForm = useForm<ButtonDimensions>(schemaButtonDimensions, {
        width: values.width || 1,
        height: values.height || 1,
    });
    const form = useForm<CmpEventProductButtonInput>(
        schemaCmpEventProductButton,
        {
            eventId: values.eventId || "",
            productId: values.productId || "",
            color: values.color || "",
            label: values.label || "",
        }
    );

    const [{ values: formValues }] = form;

    const { data: cmpEventData, loading } = useCmpEventsQuery({
        variables: {
            pagination: {
                sort: "name",
                sortDirection: "ASC",
                pageSize: 999999,
            },
        },
    });
    const { data: cmpEventProductData } = useCmpEventProductsQuery({
        variables: {
            externalEventId: formValues.eventId,
            pagination: {
                sort: "name",
                sortDirection: "ASC",
                pageSize: 999999,
            },
        },
    });

    const currentEvent = cmpEventData?.cmpEvents.data.find(
        item => item.externalId === formValues.eventId
    );
    const currentProduct = cmpEventProductData?.cmpEventProducts.data.find(
        item => item.externalId === formValues.productId
    );

    let currentConfigurationErrors: ValidationErrors<CmpEventProductButtonInput> =
        {};
    if (
        values.eventId &&
        values.productId &&
        metaData.missingConfigurationData
    ) {
        if (!currentEvent) {
            currentConfigurationErrors.eventId = {
                message: t(
                    "layout.error.missing_cmp_event_data",
                    "CMP Event not found: {{ cmpEventId }}",
                    { cmpEventId: values.eventId }
                ),
            };
        }

        if (currentEvent && !currentProduct) {
            currentConfigurationErrors.productId = {
                message: t(
                    "layout.error.missing_cmp_event_product_data",
                    "CMP Event product not found: {{ cmpEventProductId }}",
                    { cmpEventProductId: values.productId }
                ),
            };
        }
    }

    const handleSubmit = useCallback(() => {
        if (!formValues || !currentEvent || !currentProduct) {
            return;
        }

        onSubmit({
            buttonType: "CMP_EVENT_PRODUCT",
            formValues: {
                ...formValues,
                ...dimensionForm[0].values,
            } as CmpEventProductButton,

            displayValues: {
                color: formValues.color,
                label: currentProduct.name,
                formattedAmount: formatAmount(currentProduct.amount, currency, {
                    locale: locale as AvailableLocale,
                }),
            },
        });
    }, [
        currency,
        currentEvent,
        currentProduct,
        dimensionForm,
        formValues,
        locale,
        onSubmit,
    ]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    return (
        <>
            <ButtonSizeForm metaData={metaData} form={dimensionForm} />
            <Divider />
            <Spacer space={2} />
            <EventProductForm
                form={form}
                events={cmpEventData}
                eventProducts={cmpEventProductData}
                configurationErrors={currentConfigurationErrors}
                onSubmit={handleSubmit}
            />
        </>
    );
}

function EventProductForm({
    form,
    events,
    eventProducts,
    configurationErrors,
    onSubmit,
}: {
    form: Form<CmpEventProductButtonInput>;
    events: GQCmpEventsQuery;
    eventProducts?: GQCmpEventProductsQuery;
    configurationErrors: Form<CmpEventProductButtonInput>[0]["errors"];
    onSubmit: () => void;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = form;

    if (!values) {
        return null;
    }

    return (
        <>
            <View style={styles.rowContainer}>
                <InputControl
                    error={errors.eventId || configurationErrors.eventId}
                    style={styles.rowContent}
                >
                    <Picker
                        label={t(
                            "layout.cmp_event_product_button.eventId",
                            "Event"
                        )}
                        onValueChange={newValue => {
                            setValue("eventId", newValue);
                        }}
                        selectedValue={values.eventId}
                        testID="layout.modal.eventId"
                        style={
                            configurationErrors.eventId ? styles.warning : null
                        }
                    >
                        {configurationErrors.eventId ? (
                            <Picker.Item
                                label={values.eventId}
                                value={values.eventId}
                                key={`cmp_event_${values.eventId}`}
                            />
                        ) : null}
                        {events.cmpEvents.data.length === 0 ? (
                            <Picker.Item
                                label={t(
                                    "layout.modal.cmp_event_product.no_events",
                                    "No events"
                                )}
                                value=""
                                key="__none__"
                            />
                        ) : (
                            <Picker.Item
                                label={t(
                                    "layout.modal.cmp_event_product.pick_an_event",
                                    "Pick an event"
                                )}
                                value=""
                                key="__select__"
                            />
                        )}
                        {events.cmpEvents.data.map(item => (
                            <Picker.Item
                                label={item.name}
                                value={item.externalId}
                                key={`cmp_event_${item.id}`}
                            />
                        ))}
                    </Picker>
                </InputControl>
                <InputControl
                    error={errors.productId || configurationErrors.productId}
                    style={styles.rowContent}
                >
                    <Picker
                        label={t(
                            "layout.cmp_event_product_button.productId",
                            "Product"
                        )}
                        disabled={!values.eventId}
                        onValueChange={newValue => {
                            setValue("productId", newValue);
                        }}
                        selectedValue={values.productId}
                        testID="layout.modal.productId"
                        style={
                            configurationErrors.productId
                                ? styles.warning
                                : null
                        }
                    >
                        {configurationErrors.productId ? (
                            <Picker.Item
                                label={values.productId}
                                value={values.productId}
                                key={`cmp_event_${values.productId}`}
                            />
                        ) : null}
                        {eventProducts?.cmpEventProducts.data.length === 0 ? (
                            <Picker.Item
                                label={t(
                                    "layout.modal.cmp_event_product.no_products",
                                    "No products for this event"
                                )}
                                value=""
                                key="__none__"
                            />
                        ) : (
                            <Picker.Item
                                label={t(
                                    "layout.modal.cmp_event_product.pick_a_product",
                                    "Pick a product"
                                )}
                                value=""
                                key="__select__"
                            />
                        )}
                        {eventProducts?.cmpEventProducts.data.map(item => (
                            <Picker.Item
                                label={item.name}
                                value={item.externalId}
                                key={`cmp_event_product_${item.id}`}
                            />
                        ))}
                    </Picker>
                </InputControl>
            </View>
            <View style={[styles.rowContainer, styles.onTop]}>
                <InputControl
                    error={errors.label}
                    description={t(
                        "layout.cmp_event_product_button.label_description",
                        "If this field is left blank, the button will be labelled with the name of the selected event / product."
                    )}
                    style={styles.rowContent}
                >
                    <TextInput
                        label={t(
                            "layout.cmp_event_product_button.label",
                            "The button's text"
                        )}
                        placeholder={t(
                            "layout.cmp_event_product_button.label_placeholder",
                            "Write a short button text"
                        )}
                        value={values.label}
                        onChangeText={text => {
                            setValue("label", text);
                        }}
                        onClear={() => {
                            setValue("label", "");
                        }}
                    />
                </InputControl>
                <InputControl error={errors.color} style={styles.rowContent}>
                    <ColorPickerInput
                        defaultColor={values.color ?? undefined}
                        label={t("layout.button.color", "Button color")}
                        onChangeColor={newColor => {
                            setValue("color", newColor);
                        }}
                    />
                </InputControl>
            </View>
            <Button type="primary" onPress={handleSubmit(onSubmit)}>
                {t("common.save", "Save")}
            </Button>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContent: {
        flex: 1,
    },
    rowContainer: {
        ...theme.styles.row,
        columnGap: theme.spacingScale * 2,
    },
    onTop: { zIndex: 2 },
    colorRow: {
        ...theme.styles.row,
        alignItems: "baseline",
        marginBottom: 8, // To match the height/layout of the right side of the row.
    },
    groupColor: {
        marginLeft: theme.spacingScale / 2,
    },

    warning: {
        borderColor: theme.colors.warning,
    },
});
