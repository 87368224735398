import React, { useCallback } from "react";
import type { OrderConditions } from "lib/src/entity-condition";
import { produce } from "immer";
import type { GQDiscountFormQuery } from "graphql-sdk";
import { View } from "react-native";
import type { Conditions } from "lib";
import { Picker, StyleFunction, useThemedStyle } from "@venuepos/react-common";

export function GroupConditionInput({
    condition,
    productId,
    conditions,
    onConditionChange,
    formData,
}: {
    condition: OrderConditions["hasGroupQuantity"];
    productId: string;
    conditions: Conditions;
    onConditionChange: (
        conditionArgs: OrderConditions["hasGroupQuantity"]
    ) => void;
    formData: GQDiscountFormQuery;
}) {
    const styles = useThemedStyle(styleFunc);

    const onPickerValueChange = useCallback(
        (pId: string) => {
            onConditionChange(
                produce(condition, draft => {
                    const idx = draft.productIds.findIndex(
                        itr => itr === productId
                    );
                    draft.productIds[idx] = pId;
                })
            );
        },
        [condition, onConditionChange, productId]
    );

    return (
        <View style={styles.row}>
            <Picker
                selectedValue={productId}
                onValueChange={onPickerValueChange}
                style={styles.itemPicker}
            >
                {formData.products.data
                    .filter(productItr => {
                        // If the product is the same as the current condition, then it's allowed
                        if (productItr.id === productId) {
                            return true;
                        }

                        // Filter out products used by other conditions
                        return (
                            // Only group conditions
                            conditions
                                .filter(
                                    conditionItr =>
                                        conditionItr.condition ===
                                        "hasGroupQuantity"
                                )
                                // Check if the condition has the product
                                .find(conditionAssignmentItr =>
                                    (
                                        conditionAssignmentItr.args as OrderConditions["hasGroupQuantity"]
                                    ).productIds.find(
                                        pIdItr => pIdItr === productItr.id
                                    )
                                ) === undefined // the product should not have been found
                        );
                    })
                    .map(product => {
                        return (
                            <Picker.Item
                                key={product.id}
                                value={product.id}
                                label={product.name}
                            />
                        );
                    })}
            </Picker>
        </View>
    );
}

const styleFunc: StyleFunction = () => ({
    row: {
        flexDirection: "row",
        flex: 1,
    },
    quantityInput: {
        width: 55,
        textAlign: "center",
        marginRight: 10,
    },
    times: {
        lineHeight: 34,
        marginRight: 10,
    },
    itemPicker: {
        marginBottom: 0,
    },
});
