import {
    CheckBox,
    Icon,
    InputControl,
    InputLabel,
    Spacer,
    StyleFunction,
    Text,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    camelToSnake,
    IEntityConfig,
    SchemaProperty,
    validateField,
    ValidationError,
} from "lib";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { useRoute, Link, RouteProp } from "@react-navigation/native";

import { EntityConfigFormEntities, entityConfigInputs } from ".";
import { EntityFormPickerInput } from "./input/picker";
import { EntityFormSwitchInput } from "./input/switch";
import { EntityFormTextInput } from "./input/text";
import { VALUE_EDITORS, VALUE_CREATORS } from "..";
import { RootStackParamList } from "../../../navigation";

export function EntityFormInputRow<T>({
    entityId,
    property,
    value,
    defaultValue,
    entities,
    entityConfigs,
    schema,
}: {
    entityId: string;
    property: string;
    value: any;
    defaultValue: any;
    entities: EntityConfigFormEntities;
    entityConfigs: (IEntityConfig | null)[];
    schema: SchemaProperty<T>;
}) {
    const styles = useThemedStyle(styleFunc);
    const { t, i18n } = useTranslation();
    const route =
        useRoute<
            RouteProp<
                RootStackParamList,
                "MERCHANT_SETTINGS" | "CASH_REGISTER_EDIT" | "DEPARTMENT_EDIT"
            >
        >();

    const [error, setError] = useState<ValidationError | undefined>();
    const [enabled, setEnabled] = useState<boolean>(
        typeof value !== "undefined"
    );

    // Validate the field with given rules from the schema
    const validate = useCallback(() => {
        if (enabled && schema.validation) {
            const val = entityConfigInputs[entityId][property].value;
            if (typeof val !== "undefined") {
                const v = validateField(val, null, schema.validation, t);
                if (v !== null) {
                    setError(v);
                    return;
                }
            }
            setError(undefined);
        }
    }, [enabled, entityId, property, schema.validation, t]);

    // Find the value of a higher order entity
    const higherOrder = useMemo(() => {
        // Skip last entity as it's the current entity
        for (let i = entityConfigs.length - 2; i >= 0; i--) {
            if (typeof entityConfigs[i]?.data[property] !== "undefined") {
                return {
                    value: entityConfigs[i]!.data[property],
                    type: entities[i].type,
                };
            }
        }
        return { value: defaultValue, type: "default" };
    }, [defaultValue, entities, entityConfigs, property]);

    // Handle enabling/disabling the field
    // Enable: Sets the value of the field to given current value from props, or the derived value of the higher order entities
    // Disabled: Sets the value of the field to undefined
    const onEnable = useCallback(
        (checked: boolean) => {
            setEnabled(checked);
            if (entityConfigInputs[entityId][property]) {
                entityConfigInputs[entityId][property].value = checked
                    ? value || higherOrder.value
                    : undefined;
                validate();
            } else {
                entityConfigInputs[entityId][property] = {
                    value: checked ? value || higherOrder.value : undefined,
                    setError,
                };
            }
        },
        [entityId, higherOrder.value, property, validate, value]
    );

    // Update global entityConfigInputs map with the new value
    const onValueChange = useCallback(
        (newValue: any) => {
            if (entityConfigInputs[entityId][property]) {
                entityConfigInputs[entityId][property].value = newValue;
            } else {
                entityConfigInputs[entityId][property] = {
                    value: newValue,
                    setError,
                };
            }

            validate();
        },
        [entityId, property, validate]
    );

    // Show the correct input component based on the field type
    let input: ReactElement | null = null;
    const type = schema.type;
    if (type === "text" || type === "number") {
        input = (
            <EntityFormTextInput
                higherOrder={higherOrder.value}
                value={value || higherOrder.value}
                onValueChange={onValueChange}
                disabled={!enabled || schema?.locked === true}
                type={type}
                testID={"entityInput:" + property}
            />
        );
    } else if (type === "boolean") {
        input = (
            <EntityFormSwitchInput
                higherOrder={higherOrder.value}
                value={value || higherOrder.value}
                onValueChange={onValueChange}
                disabled={!enabled || schema?.locked === true}
                testID={"entitySwitch:" + property}
            />
        );
    } else if (
        type === "text_enum" ||
        type === "number_enum" ||
        type === "select"
    ) {
        input = (
            <EntityFormPickerInput
                higherOrder={higherOrder.value}
                value={value || higherOrder.value}
                onValueChange={onValueChange}
                disabled={!enabled || schema?.locked === true}
                schema={schema}
                property={property}
                testID={"entityPicker:" + property}
            />
        );
    }

    const title = t(`entity_config.label.${camelToSnake(property)}`);
    const description =
        i18n.exists(
            `entity_config.label.${camelToSnake(property)}_description`
        ) && t(`entity_config.label.${camelToSnake(property)}_description`);

    // Render form input row
    return (
        <View style={styles.row}>
            <View style={styles.checkbox}>
                <CheckBox
                    value={enabled && !schema?.locked}
                    onValueChange={onEnable}
                    disabled={schema?.locked === true}
                    testID={"entity:checkbox:" + property}
                />
            </View>
            <Spacer />
            <View style={styles.input}>
                <InputLabel>
                    {title}
                    <HelperLinks
                        enabled={enabled}
                        value={value}
                        higherOrderValue={higherOrder.value}
                        locked={schema?.locked}
                        entityConfigProperty={property}
                        routeName={route.name}
                        routeId={route.params?.id}
                        superadminOnly={schema?.superadminOnly}
                    />
                    <Text style={styles.higherOrder}>
                        {enabled
                            ? null
                            : `${t("entity_config.entity_type.value_from")} ${t(
                                  `entity_config.entity_type.${camelToSnake(
                                      higherOrder.type
                                  )}`
                              ).toUpperCase()}`}
                    </Text>
                </InputLabel>
                <InputControl error={error} description={description}>
                    {input}
                </InputControl>
            </View>
        </View>
    );
}

function HelperLinks({
    enabled,
    locked,
    value,
    higherOrderValue,
    entityConfigProperty,
    routeName,
    routeId,
    superadminOnly,
}: {
    enabled: boolean;
    locked: boolean;
    value: unknown;
    higherOrderValue: any;
    entityConfigProperty: string;
    routeName: string;
    routeId: string;
    superadminOnly: boolean;
}) {
    const styles = useThemedStyle(styleFunc);

    const linkToValueCreator = VALUE_CREATORS[entityConfigProperty]
        ? {
              screen: VALUE_CREATORS[entityConfigProperty].route,
              params: {
                  referrer: {
                      route: routeName,
                      id: routeId,
                  },
              },
          }
        : undefined;

    const idToLinkTo = enabled ? value || higherOrderValue : higherOrderValue;
    const linkToValueEditor =
        VALUE_EDITORS[entityConfigProperty] && !!idToLinkTo
            ? {
                  screen: VALUE_EDITORS[entityConfigProperty].route,
                  params: {
                      id: idToLinkTo,
                      referrer: {
                          route: routeName,
                          id: routeId,
                      },
                  },
              }
            : undefined;

    if (locked) {
        return null;
    }

    if (!linkToValueCreator && !linkToValueEditor && superadminOnly !== true) {
        return null;
    }

    return (
        <View style={styles.valueHelpers}>
            {superadminOnly === true ? (
                <Icon
                    name="admin"
                    size="small"
                    color={styles.labelIcon.color}
                    style={styles.labelIcon}
                />
            ) : null}
            {linkToValueCreator ? (
                <Link to={linkToValueCreator}>
                    <Icon name="plus" size="small" style={styles.labelIcon} />
                </Link>
            ) : null}
            {linkToValueEditor ? (
                <Link to={linkToValueEditor}>
                    <Icon name="edit" size="small" style={styles.labelIcon} />
                </Link>
            ) : null}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    higherOrder: {
        marginLeft: theme.spacingScale / 2,
        fontSize: 12,
        textTransform: "none",
        textDecorationStyle: "dotted",
        textDecorationColor: theme.colors.grey800,
        textDecorationLine: "underline",
    },
    row: {
        ...theme.styles.row,
    },
    checkbox: {
        paddingTop: theme.spacingScale * 2.5,
    },
    input: {
        ...theme.styles.flex1,
    },
    valueHelpers: {
        ...theme.styles.row,
        marginLeft: theme.spacingScale / 2,
    },
    labelIcon: {
        paddingVertical: 0,
        paddingHorizontal: theme.spacingScale / 2,
        color: theme.colors.secondary,
    },
});
