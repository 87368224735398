import {
    Button,
    ColorPickerInput,
    Form,
    InputControl,
    Loading,
    Picker,
    Spacer,
    StyleFunction,
    TextInput,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    ButtonDimensions,
    CmpEventButton,
    LayoutButtonMetaData,
    ValidationErrors,
    ValidationSchema,
} from "lib";
import { useTranslation } from "locales";
import { useCallback } from "react";
import React, { View } from "react-native";
import { Divider } from "react-native-paper";
import { ButtonSizeForm } from "../../../../button-size-form";
import { GQCmpEventsQuery, useCmpEventsQuery } from "graphql-sdk";
import { schemaButtonDimensions } from "../validation";
import { CmpEventModalOutput } from "..";

type CmpEventButtonInput = { eventId: string; color: string; label: string };

const schemaCmpEventButton: ValidationSchema<CmpEventButtonInput> = {
    eventId: { required: true },
    color: { required: true, color: true },
};

export function CmpEventForm({
    metaData,
    values,
    onSubmit,
}: {
    values: CmpEventButtonInput & ButtonDimensions;
    metaData: LayoutButtonMetaData;
    onSubmit: (values?: CmpEventModalOutput) => void;
}) {
    const [t] = useTranslation();
    const dimensionForm = useForm<ButtonDimensions>(schemaButtonDimensions, {
        width: values.width || 1,
        height: values.height || 1,
    });
    const form = useForm<CmpEventButtonInput>(schemaCmpEventButton, {
        eventId: values.eventId || "",
        color: values.color || "",
        label: values.label || "",
    });

    const [{ values: formValues }] = form;
    const [{ values: dimensionFormValues }] = dimensionForm;

    const { data: cmpEventData, loading } = useCmpEventsQuery({
        variables: {
            pagination: {
                sort: "name",
                sortDirection: "ASC",
                pageSize: 999999,
            },
        },
    });

    const currentEvent = cmpEventData?.cmpEvents.data.find(
        item => item.externalId === formValues.eventId
    );

    let currentConfigurationErrors: ValidationErrors<CmpEventButtonInput> = {};
    if (values.eventId && metaData.missingConfigurationData) {
        if (!currentEvent) {
            currentConfigurationErrors.eventId = {
                message: t(
                    "layout.error.missing_cmp_event_data",
                    "CMP Event not found: {{ cmpEventId }}",
                    { cmpEventId: values.eventId }
                ),
            };
        }
    }

    const handleSubmit = useCallback(() => {
        if (!formValues || !currentEvent) {
            return;
        }

        onSubmit({
            buttonType: "CMP_EVENT",
            formValues: {
                ...formValues,
                ...dimensionFormValues,
            } as CmpEventButton,
            displayValues: {
                color: formValues.color,
                label: formValues.label || currentEvent.name,
            },
        });
    }, [currentEvent, dimensionFormValues, formValues, onSubmit]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    return (
        <>
            <ButtonSizeForm metaData={metaData} form={dimensionForm} />
            <Divider />
            <Spacer space={2} />
            <EventForm
                form={form}
                configurationErrors={currentConfigurationErrors}
                events={cmpEventData}
                onSubmit={handleSubmit}
            />
        </>
    );
}

function EventForm({
    form,
    configurationErrors,
    events,
    onSubmit,
}: {
    form: Form<CmpEventButtonInput>;
    configurationErrors: Form<CmpEventButtonInput>[0]["errors"];
    events: GQCmpEventsQuery;
    onSubmit: () => void;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = form;

    if (!values) {
        return null;
    }

    return (
        <>
            <InputControl error={errors.eventId || configurationErrors.eventId}>
                <Picker
                    label={t("layout.cmp_event_button.eventId", "Event")}
                    onValueChange={newValue => {
                        setValue("eventId", newValue);
                    }}
                    selectedValue={values.eventId}
                    testID="layout.modal.eventId"
                    style={configurationErrors.eventId ? styles.warning : null}
                >
                    {configurationErrors.eventId ? (
                        <Picker.Item
                            label={values.eventId}
                            value={values.eventId}
                            key={`cmp_event_${values.eventId}`}
                        />
                    ) : null}
                    {events.cmpEvents.data.length === 0 ? (
                        <Picker.Item
                            label={t(
                                "layout.modal.cmp_event.no_events",
                                "No events"
                            )}
                            value=""
                            key="__none__"
                        />
                    ) : (
                        <Picker.Item
                            label={t(
                                "layout.modal.cmp_event.pick_an_event",
                                "Pick an event"
                            )}
                            value=""
                            key="__select__"
                        />
                    )}
                    {events.cmpEvents.data.map(item => (
                        <Picker.Item
                            label={item.name}
                            value={item.externalId}
                            key={`cmp_event_${item.id}`}
                        />
                    ))}
                </Picker>
            </InputControl>
            <View style={[styles.rowContainer, styles.onTop]}>
                <InputControl
                    error={errors.label}
                    description={t(
                        "layout.cmp_event_button.label_description",
                        "If this field is left blank, the button will be labelled with the name of the selected event."
                    )}
                    style={styles.rowContent}
                >
                    <TextInput
                        label={t(
                            "layout.cmp_event_button.label",
                            "The button's text"
                        )}
                        placeholder={t(
                            "layout.cmp_event_button.label_placeholder",
                            "Write a short button text"
                        )}
                        value={values.label}
                        onChangeText={text => {
                            setValue("label", text);
                        }}
                        onClear={() => {
                            setValue("label", "");
                        }}
                    />
                </InputControl>
                <InputControl error={errors.color} style={styles.rowContent}>
                    <ColorPickerInput
                        defaultColor={values.color ?? undefined}
                        label={t("layout.button.color", "Button color")}
                        onChangeColor={newColor => {
                            setValue("color", newColor);
                        }}
                    />
                </InputControl>
            </View>
            <Button type="primary" onPress={handleSubmit(onSubmit)}>
                {t("common.save", "Save")}
            </Button>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContent: {
        flex: 1,
    },
    rowContainer: {
        ...theme.styles.row,
        columnGap: theme.spacingScale * 2,
    },
    onTop: { zIndex: 2 },
    colorRow: {
        ...theme.styles.row,

        alignItems: "baseline",
        marginBottom: 8, // To match the height/layout of the right side of the row.
    },
    groupColor: {
        marginLeft: theme.spacingScale / 2,
    },

    warning: {
        borderColor: theme.colors.warning,
    },
});
