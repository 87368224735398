import { Divider } from "react-native-paper";
import {
    ButtonDimensions,
    CmpProductButton,
    formatAmount,
    LayoutButtonMetaData,
    ValidationErrors,
    ValidationSchema,
} from "lib";
import { CmpProductModalOutput } from "..";
import { AvailableLocale, useTranslation } from "locales";
import {
    Button,
    ColorPickerInput,
    Form,
    InputControl,
    Loading,
    Picker,
    Spacer,
    StyleFunction,
    TextInput,
    useForm,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import { schemaButtonDimensions } from "../validation";
import { ButtonSizeForm } from "../../../../button-size-form";
import React, { useCallback } from "react";
import { View } from "react-native";
import { GQCmpProductsQuery, useCmpProductsQuery } from "graphql-sdk";
import { useAdminSession } from "../../../../../../session";

type CmpProductButtonInput = {
    productId: string;
    color: string;
    label: string;
};

const schemaCmpEventButton: ValidationSchema<CmpProductButtonInput> = {
    productId: { required: true },
    color: { required: true, color: true },
};

export function CmpProductForm({
    metaData,
    values,
    onSubmit,
}: {
    values: CmpProductButtonInput & ButtonDimensions;
    metaData: LayoutButtonMetaData;
    onSubmit: (values?: CmpProductModalOutput) => void;
}) {
    const [t] = useTranslation();
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);

    const dimensionForm = useForm<ButtonDimensions>(schemaButtonDimensions, {
        width: values.width || 1,
        height: values.height || 1,
    });
    const form = useForm<CmpProductButtonInput>(schemaCmpEventButton, {
        productId: values.productId || "",
        color: values.color || "",
        label: values.label || "",
    });

    const [{ values: formValues }] = form;
    const [{ values: dimensionFormValues }] = dimensionForm;

    const { data: cmpProductData, loading } = useCmpProductsQuery({
        variables: {
            pagination: {
                sort: "name",
                sortDirection: "ASC",
                pageSize: 999999,
            },
        },
    });

    const currentProduct = cmpProductData?.cmpProducts.data.find(
        item => item.externalId === formValues.productId
    );

    let currentConfigurationErrors: ValidationErrors<CmpProductButtonInput> =
        {};
    if (values.productId && metaData.missingConfigurationData) {
        if (!currentProduct) {
            currentConfigurationErrors.productId = {
                message: t(
                    "layout.error.missing_cmp_product_data",
                    "CMP Product not found: {{ cmpProductId }}",
                    { cmpProductId: values.productId }
                ),
            };
        }
    }

    const handleSubmit = useCallback(() => {
        if (!formValues || !currentProduct) {
            return;
        }

        onSubmit({
            buttonType: "CMP_PRODUCT",
            formValues: {
                ...formValues,
                ...dimensionFormValues,
            } as CmpProductButton,
            displayValues: {
                color: formValues.color,
                label: formValues.label || currentProduct.name,
                formattedAmount: formatAmount(currentProduct.amount, currency, {
                    locale: locale as AvailableLocale,
                }),
            },
        });
    }, [
        currency,
        currentProduct,
        dimensionFormValues,
        formValues,
        locale,
        onSubmit,
    ]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    return (
        <>
            <ButtonSizeForm metaData={metaData} form={dimensionForm} />
            <Divider />
            <Spacer space={2} />
            <ProductForm
                form={form}
                configurationErrors={currentConfigurationErrors}
                products={cmpProductData}
                onSubmit={handleSubmit}
            />
        </>
    );
}

function ProductForm({
    form,
    configurationErrors,
    products,
    onSubmit,
}: {
    form: Form<CmpProductButtonInput>;
    configurationErrors: Form<CmpProductButtonInput>[0]["errors"];
    products: GQCmpProductsQuery;
    onSubmit: () => void;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = form;

    if (!values) {
        return null;
    }

    return (
        <>
            <InputControl
                error={errors.productId || configurationErrors.productId}
            >
                <Picker
                    label={t("layout.cmp_product_button.productId", "Product")}
                    onValueChange={newValue => {
                        setValue("productId", newValue);
                    }}
                    selectedValue={values.productId}
                    testID="layout.modal.productId"
                    style={
                        configurationErrors.productId ? styles.warning : null
                    }
                >
                    {configurationErrors.productId ? (
                        <Picker.Item
                            label={values.productId}
                            value={values.productId}
                            key={`cmp_product_${values.productId}`}
                        />
                    ) : null}
                    {products?.cmpProducts.data.length === 0 ? (
                        <Picker.Item
                            label={t(
                                "layout.modal.cmp_product.no_products",
                                "No products"
                            )}
                            value=""
                            key="__none__"
                        />
                    ) : (
                        <Picker.Item
                            label={t(
                                "layout.modal.cmp_product.pick_a_product",
                                "Pick a product"
                            )}
                            value=""
                            key="__select__"
                        />
                    )}
                    {products.cmpProducts.data.map(item => (
                        <Picker.Item
                            label={`${item.name} (${item.type})`}
                            value={item.externalId}
                            key={`cmp_product_${item.id}`}
                        />
                    ))}
                </Picker>
            </InputControl>
            <View style={[styles.rowContainer, styles.onTop]}>
                <InputControl
                    error={errors.label}
                    description={t(
                        "layout.cmp_product_button.label_description",
                        "If this field is left blank, the button will be labelled with the name of the selected product."
                    )}
                    style={styles.rowContent}
                >
                    <TextInput
                        label={t(
                            "layout.cmp_product_button.label",
                            "The button's text"
                        )}
                        placeholder={t(
                            "layout.cmp_product_button.label_placeholder",
                            "Write a short button text"
                        )}
                        value={values.label}
                        onChangeText={text => {
                            setValue("label", text);
                        }}
                        onClear={() => {
                            setValue("label", "");
                        }}
                    />
                </InputControl>
                <InputControl error={errors.color} style={styles.rowContent}>
                    <ColorPickerInput
                        defaultColor={values.color ?? undefined}
                        label={t("layout.button.color", "Button color")}
                        onChangeColor={newColor => {
                            setValue("color", newColor);
                        }}
                    />
                </InputControl>
            </View>
            <Button type="primary" onPress={handleSubmit(onSubmit)}>
                {t("common.save", "Save")}
            </Button>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContent: {
        flex: 1,
    },
    rowContainer: {
        ...theme.styles.row,
        columnGap: theme.spacingScale * 2,
    },
    onTop: { zIndex: 2 },
    colorRow: {
        ...theme.styles.row,

        alignItems: "baseline",
        marginBottom: 8, // To match the height/layout of the right side of the row.
    },
    groupColor: {
        marginLeft: theme.spacingScale / 2,
    },

    warning: {
        borderColor: theme.colors.warning,
    },
});
