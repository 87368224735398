import {
    Button,
    ColorPickerInput,
    InputControl,
    NumberInput,
    StyleFunction,
    TextInput,
    useForm,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    BaseLayoutButton,
    BuyAccountFundsButtonType,
    formatAmount,
    parseAmount,
} from "lib";
import { AvailableLocale, useTranslation } from "locales";
import { useCallback } from "react";
import React, { View } from "react-native";
import { FunctionButtonModalOutput } from "..";
import { useAdminSession } from "../../../../../../session";

export function BuyAccountFundsSubForm({
    values: mainFormValues,
    onSubmit,
}: {
    values: BuyAccountFundsButtonType;
    onSubmit: (values: FunctionButtonModalOutput) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const [{ locale }] = useAdminSession(["locale"]);
    const merchantConfig = useMerchantConfig();

    const functionForm = useForm<
        Omit<BuyAccountFundsButtonType, keyof BaseLayoutButton>
    >(
        {
            label: { required: true },
            color: { required: true, color: true },
            amount: { number: true, integer: true },
        },
        {
            buttonType: "FUNCTION",
            function: "BUY_ACCOUNT_FUNDS",

            label: mainFormValues.label || "",
            color: mainFormValues.color || "",

            amount: mainFormValues.amount || 0,
        }
    );

    const [{ values, errors }, { setValue, handleSubmit }] = functionForm;

    const handleOnSubmit = useCallback(() => {
        onSubmit({
            buttonType: "FUNCTION",
            formValues: values as BuyAccountFundsButtonType,

            displayValues: {
                color: values.color,
                label: values.label,
                formattedAmount: values.amount
                    ? formatAmount(values.amount, merchantConfig.currency, {
                          locale: locale as AvailableLocale,
                      })
                    : null,
            },
        });
    }, [locale, merchantConfig.currency, onSubmit, values]);

    return (
        <>
            <InputControl error={errors.label}>
                <TextInput
                    required
                    label={t("layout.function_button_form.label", "Label")}
                    placeholder={t(
                        "layout.function_button_form.label_placeholder",
                        "Write a short button text"
                    )}
                    value={values.label}
                    onChangeText={value => {
                        setValue("label", value);
                    }}
                    onClear={() => {
                        setValue("label", "");
                    }}
                />
            </InputControl>

            <View style={[styles.rowContainer, styles.onTop]}>
                <InputControl
                    error={errors.amount}
                    description={t(
                        "layout.function_button_form.buy_funds_amount",
                        "Leave field blank to let the amount be decided by the cashier"
                    )}
                    style={styles.flex1}
                >
                    <NumberInput
                        label={t("common.amount", "Amount")}
                        defaultValue={
                            values.amount ? values.amount / 100 : null
                        }
                        onChange={value => {
                            setValue("amount", value ? parseAmount(value) : 0);
                        }}
                        min={0}
                        unit={merchantConfig.currency}
                    />
                </InputControl>
                <InputControl error={errors.color} style={styles.flex1}>
                    <ColorPickerInput
                        required
                        label={t(
                            "layout.function_button_form.button_color",
                            "Color of the button"
                        )}
                        defaultColor={values.color}
                        onChangeColor={newColor => {
                            setValue("color", newColor);
                        }}
                    />
                </InputControl>
            </View>
            <Button type="primary" onPress={handleSubmit(handleOnSubmit)}>
                {t("layout.function_button_form.types.apply", "Apply")}
            </Button>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContainer: {
        ...theme.styles.row,
        columnGap: theme.spacingScale * 2,
    },
    onTop: { zIndex: 2 },
    flex1: {
        ...theme.styles.flex1,
    },
});
