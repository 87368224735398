import axios, { AxiosInstance, AxiosResponse } from "axios";
import { IEventStore } from "event-store";
import { v4 as uuidv4 } from "uuid";
import {
    SoftpayCloudRequestErrorV1,
    SoftpayCloudRequestEvent,
    SoftpayCloudResponseEvent,
    VP_ENV,
} from "../..";
import type { JsonBody, QueryParameters } from "./types";

// To get TS to accept that we add a custom id to the config object. This is used in the logging interceptors.
declare module "axios" {
    export interface AxiosRequestConfig {
        id?: string;
    }
}

type ClientParameters = {
    baseUrl?: string;
    token?: string;
    contentType?: string;
    merchantReference?: string;
};

export class SoftpayClient {
    private _axios: AxiosInstance;

    constructor(
        { baseUrl, token, contentType, merchantReference }: ClientParameters,
        transactionId?: string,
        eventStore?: IEventStore,
        userId?: string
    ) {
        const requestHeaders: HeadersInit = token
            ? {
                  authorization: `Bearer ${token}`,
              }
            : {};

        const merchantReferenceHeader = merchantReference
            ? { "X-Softpay-Merchant-Reference": merchantReference }
            : {};

        this._axios = axios.create({
            baseURL: baseUrl,
            timeout: 5000,
            headers: {
                ...requestHeaders,
                ...merchantReferenceHeader,
                "Content-Type": contentType ?? "application/json",
            },
        });

        this._axios.interceptors.request.use(
            config => {
                config.id = uuidv4();

                if (eventStore && transactionId) {
                    let eventName: SoftpayCloudRequestEvent["eventName"] =
                        "REQUEST";

                    const url = config.url;
                    const method = config.method?.toLowerCase();

                    switch (method) {
                        case "post":
                            if (url === "/v2/api/cloud/transactions") {
                                eventName = "CREATE_TRANSACTION_REQUEST";
                            }
                            break;
                        case "put":
                            if (
                                url?.startsWith(
                                    "/v2/api/cloud/transactions/"
                                ) &&
                                url?.endsWith("/abort")
                            ) {
                                eventName = "ABORT_TRANSACTION_REQUEST";
                            } else if (
                                url?.startsWith("/v2/api/cloud/transactions/")
                            ) {
                                eventName = "START_TRANSACTION_REQUEST";
                            }
                            break;
                        case "get":
                            if (
                                url?.startsWith(
                                    "/v2/api/cloud/transactions/"
                                ) &&
                                url?.endsWith("/receipt")
                            ) {
                                eventName = "GET_PAYMENT_RECEIPT_REQUEST";
                            } else if (
                                url?.startsWith("/v2/api/cloud/transactions/")
                            ) {
                                eventName = "GET_TRANSACTION_DETAILS_REQUEST";
                            }
                            break;
                    }

                    const event: SoftpayCloudRequestEvent = {
                        aggregateId: transactionId,
                        aggregateName: "SOFTPAY_CLOUD",
                        eventName: eventName,
                        data: {
                            id: config.id,
                            method: config.method,
                            baseURL: config.baseURL,
                            url: config.url,
                            params: config.params,
                            headers: config.headers,
                            body: config.data,
                        },
                        version: 1,
                        eventDate: new Date().toISOString(),
                        meta: { userId: userId ?? undefined },
                        owner: "",
                        ownerSequence: 0,
                    };

                    eventStore.dispatch([event]);
                }
                return config;
            },
            error => {
                console.error("Softpay request failed", error);

                return errorLogging(error, eventStore, transactionId, userId);
            }
        );

        this._axios.interceptors.response.use(
            response => {
                if (eventStore && transactionId) {
                    let eventName: SoftpayCloudResponseEvent["eventName"] =
                        "RESPONSE";

                    const url = response.config.url;
                    const method = response.config.method?.toLowerCase();

                    switch (method) {
                        case "post":
                            if (url === "/v2/api/cloud/transactions") {
                                eventName = "CREATE_TRANSACTION_RESPONSE";
                            }
                            break;
                        case "put":
                            if (
                                url?.startsWith(
                                    "/v2/api/cloud/transactions/"
                                ) &&
                                url?.endsWith("/abort")
                            ) {
                                eventName = "ABORT_TRANSACTION_RESPONSE";
                            } else if (
                                url?.startsWith("/v2/api/cloud/transactions/")
                            ) {
                                eventName = "START_TRANSACTION_RESPONSE";
                            }
                            break;
                        case "get":
                            if (
                                url?.startsWith(
                                    "/v2/api/cloud/transactions/"
                                ) &&
                                url?.endsWith("/receipt")
                            ) {
                                eventName = "GET_PAYMENT_RECEIPT_RESPONSE";
                            } else if (
                                url?.startsWith("/v2/api/cloud/transactions/")
                            ) {
                                eventName = "GET_TRANSACTION_DETAILS_RESPONSE";
                            }
                            break;
                    }

                    const event: SoftpayCloudResponseEvent = {
                        aggregateId: transactionId,
                        aggregateName: "SOFTPAY_CLOUD",
                        eventName: eventName,
                        data: {
                            id: response.config.id!,
                            method: response.config.method,
                            url: response.config.url,
                            status: response.status,
                            data: response.data,
                        },
                        version: 1,
                        eventDate: new Date().toISOString(),
                        meta: { userId: userId ?? undefined },
                        owner: "",
                        ownerSequence: 0,
                    };
                    eventStore.dispatch([event]);
                }

                return response;
            },
            function (error) {
                console.error("Softpay response failed", error);

                return errorLogging(error, eventStore, transactionId, userId);
            }
        );
    }

    async get<T = any>(
        url: string,
        parameters?: QueryParameters
    ): Promise<AxiosResponse<T> | undefined> {
        return await this._axios.get<T>(url, {
            params: parameters,
        });
    }

    async post<T>(url: string, body?: JsonBody): Promise<AxiosResponse<T>> {
        return await this._axios.post<T>(url, body);
    }

    async patch<T = any>(
        url: string,
        body?: JsonBody
    ): Promise<AxiosResponse<AxiosResponse<T>>["data"]> {
        return await this._axios.patch<T>(url, body);
    }

    async put<T = any>(
        url: string,
        body?: JsonBody
    ): Promise<AxiosResponse<AxiosResponse<T>>["data"]> {
        return await this._axios.put<T>(url, body);
    }

    async delete<T = any>(
        url: string,
        parameters?: QueryParameters
    ): Promise<AxiosResponse<AxiosResponse<T>>["data"]> {
        return await this._axios.delete<T>(url, {
            params: parameters,
        });
    }
}

export function getSoftpayClient(
    parameters: ClientParameters,
    transactionId?: string,
    eventStore?: IEventStore,
    userId?: string
) {
    if (!parameters.baseUrl) {
        let softpayCloudUrl = "https://api.sandbox.softpay.io/api-gateway/";
        if (VP_ENV === "cloud.venuepos.net") {
            softpayCloudUrl = `https://api.softpayapp.io/api-gateway/`;
        }

        parameters.baseUrl = softpayCloudUrl;
    }
    return new SoftpayClient(parameters, transactionId, eventStore, userId);
}

function errorLogging(
    error: any,
    eventStore?: IEventStore,
    transactionId?: string,
    userId?: string
) {
    if (!error.config) {
        return Promise.reject(error);
    }

    const {
        config: { method, baseURL, params, url, id, headers },
        response,
    } = error;

    if (eventStore && transactionId) {
        const event: SoftpayCloudRequestErrorV1 = {
            aggregateId: transactionId,
            aggregateName: "SOFTPAY_CLOUD",
            eventName: "REQUEST_ERROR",
            data: {
                id: id,
                method: method,
                baseURL: baseURL,
                url: url,
                params: params,
                headers: headers,
                status: response?.status,
                data: response?.data,
            },
            version: 1,
            eventDate: new Date().toISOString(),
            meta: { userId: userId ?? undefined },
            owner: "",
            ownerSequence: 0,
        };

        eventStore.dispatch([event]);
    }

    return Promise.reject(error);
}
