import {
    Button,
    ColorPickerInput,
    InputControl,
    StyleFunction,
    TextInput,
    useForm,
    useThemedStyle,
} from "@venuepos/react-common";
import { BaseLayoutButton, PluSearchButtonType } from "lib";
import { useTranslation } from "locales";
import { useCallback } from "react";
import React, { View } from "react-native";
import { FunctionButtonModalOutput } from "..";

export function PLUSearchSubForm({
    values: mainFormValues,
    onSubmit,
}: {
    values: PluSearchButtonType;
    onSubmit: (values: FunctionButtonModalOutput) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const subForm = useForm<Omit<PluSearchButtonType, keyof BaseLayoutButton>>(
        { label: { required: true }, color: { required: true, color: true } },
        {
            buttonType: "FUNCTION",
            function: "PLU_SEARCH",

            label: mainFormValues.label || "",
            color: mainFormValues.color || "",
        }
    );

    const [{ values, errors }, { setValue, handleSubmit }] = subForm;

    const handleOnSubmit = useCallback(() => {
        onSubmit({
            buttonType: "FUNCTION",
            formValues: values as PluSearchButtonType,
            displayValues: {
                color: values.color,
                label: values.label,
            },
        });
    }, [onSubmit, values]);

    return (
        <>
            <View style={[styles.rowContainer, styles.onTop]}>
                <InputControl error={errors.label} style={styles.rowContent}>
                    <TextInput
                        required
                        label={t("layout.function_button_form.label", "Label")}
                        placeholder={t(
                            "layout.function_button_form.label_placeholder",
                            "Write a short button text"
                        )}
                        value={values.label}
                        onChangeText={value => {
                            setValue("label", value);
                        }}
                        onClear={() => {
                            setValue("label", "");
                        }}
                    />
                </InputControl>
                <InputControl error={errors.color} style={styles.rowContent}>
                    <ColorPickerInput
                        required
                        label={t(
                            "layout.function_button_form.button_color",
                            "Color of the button"
                        )}
                        defaultColor={values.color}
                        onChangeColor={newColor => {
                            setValue("color", newColor);
                        }}
                    />
                </InputControl>
            </View>
            <Button type="primary" onPress={handleSubmit(handleOnSubmit)}>
                {t("layout.function_button_form.types.apply", "Apply")}
            </Button>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContainer: {
        ...theme.styles.row,
        columnGap: theme.spacingScale * 2,
    },
    rowContent: {
        ...theme.styles.flex1,
    },
    onTop: { zIndex: 2 },
});
