import type {
    CmpProductSettingsInput,
    EconomicSettingsInput,
    IIntegrationInput,
    ValidationSchema,
} from "lib";

export const defaultIntegration: IIntegrationInput = {
    merchantId: "",
    name: "",
    apiKey: "",
    type: undefined,
    active: true,
    merchantChartOfAccounts: [],
};

export const schemaEconomicSettings: ValidationSchema<EconomicSettingsInput> = {
    journalId: {
        required: true,
        integer: true,
        minValue: { value: 0 },
    },
};

export const schemaCmpProductSettings: ValidationSchema<CmpProductSettingsInput> =
    {
        hostname: {
            required: true,
        },
        port: {
            required: true,
            integer: true,
            minValue: { value: 0 },
        },
        vhost: {
            required: true,
        },
        username: {
            required: true,
        },
        password: {
            required: true,
        },
        exchangeName: {
            required: true,
        },
        queueName: {
            required: true,
        },
        routingKey: {
            required: true,
        },
        cmpOrderUrl: { required: true },
    };
