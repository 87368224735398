import {
    Button,
    Loading,
    ModalWrapper,
    Spacer,
    StyleFunction,
    useModal,
    useThemedStyle,
} from "@venuepos/react-common";
import type {
    FunctionButtonInput,
    ILayoutProduct,
    FunctionButton,
    ProductButton,
    LayoutButtonMetaData,
    CmpEventButton,
    CmpEventProductButton,
    ProductSectionButton,
    ProductLayoutButtonType,
    CmpProductButton,
} from "lib";
import React, { useCallback, useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { Divider } from "react-native-paper";
import { ButtonTypeSelector } from "./button-type-selector";
import { FunctionButtonForm } from "./function-button/function-button-form";
import { ProductButtonMainForm } from "./product-button/product-button-main-form";
import { ProductSelector } from "./product-button/product-selector";
import { CmpEventForm } from "./cmp-event/cmp-event-form";
import { CmpEventProductForm } from "./cmp-event/cmp-event-product-form";
import { CmpProductForm } from "./cmp-event/cmp-product-form";
import { useIntegrationsQuery } from "graphql-sdk";

export type CmpProductModalOutput = {
    buttonType: "CMP_PRODUCT";
    formValues: CmpProductButton;
    displayValues: { label: string; color: string; formattedAmount: string };
};

export type CmpEventModalOutput = {
    buttonType: "CMP_EVENT";
    formValues: CmpEventButton;
    displayValues: { label: string; color: string };
};

export type CmpEventProductModalOutput = {
    buttonType: "CMP_EVENT_PRODUCT";
    formValues: CmpEventProductButton;
    displayValues: { label: string; color: string; formattedAmount: string };
};

export type SingleProductModalOutput = {
    buttonType: "PRODUCT";
    formValues: ProductButton;
    productData: ILayoutProduct;
    displayValues: { label: string; color: string; formattedAmount: string };
};

export type FunctionButtonModalOutput = {
    buttonType: "FUNCTION";
    formValues: FunctionButton;
    displayValues?: { label: string; color: string; formattedAmount?: string }; // Only some of the types allow the color to be overridden, so displayValues is nullable.
};

export type AddButtonModalOutput =
    | CmpProductModalOutput
    | CmpEventModalOutput
    | CmpEventProductModalOutput
    | SingleProductModalOutput
    | {
          // Add multiple selected products in one go.
          buttonType: "PRODUCT";
          formValues: null; // If the values are returned from the "select products" section, then there are no formValues
          productData: ILayoutProduct[];
      }
    | FunctionButtonModalOutput;

export type AddButtonModalProps = {
    button: Partial<ProductSectionButton>;

    buttonMetaData: LayoutButtonMetaData;

    // If a product has already been selected for this button, this argument is defined.
    product?: ILayoutProduct;

    // The set of products, that has already been added to this layout.
    addedProducts: ILayoutProduct["id"][];

    onClose: (value?: AddButtonModalOutput) => void;
};

export function AddButtonModal({
    button,
    buttonMetaData,
    product,
    addedProducts,
    onClose,
}: AddButtonModalProps) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const { data: integrationsData, loading: integrationsLoading } =
        useIntegrationsQuery();

    const [buttonType, setButtonType] =
        useState<ProductLayoutButtonType | null>(button.buttonType || null);

    const [productId, setProductId] = useState<string>(
        (button as ProductButton).productId
            ? (button as ProductButton).productId
            : ""
    );
    const [buttonProduct, setButtonProduct] = useState<ILayoutProduct | null>(
        product || null
    );

    function resetButtonData() {
        setButtonProduct(null);
        setProductId(null);
        setButtonType(null);
    }

    function handleSelectProduct({
        selectedProduct,
    }: {
        selectedProduct: ILayoutProduct;
    }) {
        setProductId(selectedProduct.id);
        setButtonProduct(selectedProduct);
    }

    const handleAddProductsToLayout = useCallback(
        (selectedProducts: ILayoutProduct[]) => {
            onClose({
                buttonType: "PRODUCT",
                formValues: null,
                productData: selectedProducts,
            });
        },
        [onClose]
    );

    if (integrationsLoading) {
        return <Loading />;
    }

    return (
        <ModalWrapper
            title={t("backoffice.layout.edit_button", "Edit button")}
            onClose={() => onClose(undefined)}
        >
            <View style={styles.container} testID="layout:editButton">
                {buttonType ? (
                    <Button variant="text" onPress={resetButtonData}>
                        {t("layout.change_button_type", "Change button type")}
                    </Button>
                ) : null}
                <Divider />

                <Spacer space={2} />
                {/* If no button type has been selected yet, then let the user select either Product, Function, CMP Event, or CMP Product */}
                {!buttonType ? (
                    <ButtonTypeSelector
                        hasCmpIntegration={
                            integrationsData?.integrations.data.some(
                                itr => itr.type === "CMP-PRODUCT"
                            ) || false
                        }
                        onButtonTypeSelect={setButtonType}
                    />
                ) : null}

                {buttonType === "PRODUCT" ? (
                    /* If no product has been selected yet, then present the ProductSelector */
                    !productId ? (
                        <ProductSelector
                            addedProducts={addedProducts}
                            onSelectProduct={handleSelectProduct}
                            onAddProductsToLayout={handleAddProductsToLayout}
                        />
                    ) : (
                        /* If a product has been selected, then present the Product button Main form */
                        <ProductButtonMainForm
                            button={button as ProductButton}
                            buttonProduct={buttonProduct!}
                            onClose={onClose}
                            metaData={buttonMetaData}
                        />
                    )
                ) : null}

                {buttonType === "FUNCTION" ? (
                    <FunctionButtonForm
                        onSubmit={onClose}
                        values={button as FunctionButtonInput}
                        metaData={buttonMetaData}
                    />
                ) : null}

                {buttonType === "CMP_EVENT" ? (
                    <CmpEventForm
                        metaData={buttonMetaData}
                        values={button as CmpEventButton}
                        onSubmit={onClose}
                    />
                ) : null}

                {buttonType === "CMP_EVENT_PRODUCT" ? (
                    <CmpEventProductForm
                        metaData={buttonMetaData}
                        values={button as CmpEventProductButton}
                        onSubmit={onClose}
                    />
                ) : null}

                {buttonType === "CMP_PRODUCT" ? (
                    <CmpProductForm
                        metaData={buttonMetaData}
                        values={button as CmpProductButton}
                        onSubmit={onClose}
                    />
                ) : null}
            </View>
        </ModalWrapper>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    container: {
        padding: theme.spacingScale * 2,
        height: dimensions.height - theme.spacingScale * 10,
        width:
            dimensions.width <= theme.breakpoint.portraitWidth
                ? dimensions.width - theme.spacingScale * 2
                : 800,
        borderBottomEndRadius: theme.borderRadius,
        borderBottomStartRadius: theme.borderRadius,
    },
});

export function useAddButtonModal() {
    const { render } = useModal<AddButtonModalOutput | undefined>();
    return async (props: Omit<AddButtonModalProps, "onClose">) =>
        render(onClose => <AddButtonModal {...props} onClose={onClose} />);
}
