import { t } from "i18next";
import {
    baseButtonColor,
    CmpEventProductCalendar,
    formatAmount,
    GridDimensions,
    ILayoutInput,
    ProductSection,
    VenueAccessTicketButtonType,
} from "lib";
import React, { createContext, useReducer } from "react";
import {
    ProductLayoutActionType,
    productLayoutReducer,
    ProductLayoutReducerState,
} from "./edit/product-layout-operation-reducer";
import {
    getMaxDimensionsForCoordinate,
    handleProductButtonMetaData,
} from "./functions";
import { LayoutActionType } from "./layout-operation-reducer";
import { useAdminSession } from "../../session";
import { useMerchantConfig } from "@venuepos/react-common";
import { AvailableLocale } from "locales";

export const LayoutButtonsContext =
    createContext<ProductLayoutReducerState | null>(null);

export const LayoutDispatchContext = createContext<React.Dispatch<
    LayoutActionType | ProductLayoutActionType
> | null>(null);

export function ProductLayoutProvider({
    sections,
    knownProducts,
    knownProductGroups,
    knownEvents,
    knownCmpProducts,
    gridDimensions,
    onLayoutUpdate,
    children,
}: {
    sections: ProductSection[];
    knownProducts: ProductLayoutReducerState["knownProducts"];
    knownProductGroups: ProductLayoutReducerState["knownProductGroups"];
    knownEvents: ProductLayoutReducerState["knownEvents"];
    knownCmpProducts: ProductLayoutReducerState["knownCmpProducts"];
    gridDimensions: GridDimensions;
    onLayoutUpdate: (value: ILayoutInput["data"]) => void;
    children: React.ReactNode;
}) {
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);

    const [layoutState, dispatch] = useReducer(
        productLayoutReducer,
        {
            sections,
            knownProducts,
            knownProductGroups,
            knownEvents,
            knownCmpProducts,
            dimensions: gridDimensions,
            currentSectionIndex: 0,
            buttonMetaData: {},
            onLayoutUpdate,
            currency,
            locale: locale as AvailableLocale,
        },
        createInitialState
    );

    return (
        <LayoutButtonsContext.Provider value={layoutState}>
            <LayoutDispatchContext.Provider value={dispatch}>
                {children}
            </LayoutDispatchContext.Provider>
        </LayoutButtonsContext.Provider>
    );
}

function createInitialState({
    sections,
    knownProducts,
    knownProductGroups,
    knownEvents,
    knownCmpProducts,
    dimensions,
    currentSectionIndex,
    buttonMetaData,
    onLayoutUpdate,
    currency,
    locale,
}: ProductLayoutReducerState) {
    let returnValue: ProductLayoutReducerState = {
        sections,
        knownProducts,
        knownProductGroups,
        knownEvents,
        knownCmpProducts,
        dimensions,
        currentSectionIndex,
        buttonMetaData,
        onLayoutUpdate,
        currency,
        locale,
    };

    const formatAmountWithCurrency = (amount: number) =>
        formatAmount(amount, currency, { locale });

    // Check the buttons of all sections for product and product group references, update button metadata array, etc.
    return {
        ...returnValue,

        sections: returnValue.sections.map(section => ({
            ...section,

            // loop the buttons of this section to find product and product group references
            buttons: section.buttons
                .map(button => {
                    if (!button) {
                        return;
                    }

                    // Set all basic properties for the button in the grid here.
                    returnValue.buttonMetaData[button.id] = {
                        maxHeightValue: 1,
                        maxWidthValue: 1,
                    };

                    if (button.buttonType === "PRODUCT") {
                        returnValue.buttonMetaData =
                            handleProductButtonMetaData(
                                button,
                                returnValue.buttonMetaData,
                                returnValue.knownProducts,
                                formatAmountWithCurrency
                            );
                    } else if (button.buttonType === "FUNCTION") {
                        returnValue.buttonMetaData[button.id] = {
                            ...returnValue.buttonMetaData[button.id],

                            text:
                                button.label ||
                                t("backoffice.layout.no_text", "No text"),
                        };

                        if (
                            button.function === "CUSTOM_ITEM" ||
                            button.function === "VENUE_ACCESS_TICKETS"
                        ) {
                            const foundGroup = knownProductGroups.find(
                                groupItr =>
                                    groupItr.id === button.productGroupId
                            );

                            if (foundGroup) {
                                returnValue.buttonMetaData[button.id] = {
                                    ...returnValue.buttonMetaData[button.id],

                                    color:
                                        button.color ||
                                        foundGroup.color ||
                                        baseButtonColor,
                                    text: button.label,

                                    formattedAmount:
                                        (button as VenueAccessTicketButtonType)
                                            .amount !== undefined
                                            ? formatAmountWithCurrency(
                                                  (
                                                      button as VenueAccessTicketButtonType
                                                  ).amount
                                              )
                                            : "",
                                };
                            } else {
                                returnValue.buttonMetaData[button.id] = {
                                    ...returnValue.buttonMetaData[button.id],

                                    color: button.color || baseButtonColor,
                                    text: button.label,

                                    formattedAmount:
                                        (button as VenueAccessTicketButtonType)
                                            .amount !== undefined
                                            ? formatAmountWithCurrency(
                                                  (
                                                      button as VenueAccessTicketButtonType
                                                  ).amount
                                              )
                                            : "",
                                };
                            }
                        } else if (button.function === "BUY_ACCOUNT_FUNDS") {
                            returnValue.buttonMetaData[button.id] = {
                                ...returnValue.buttonMetaData[button.id],

                                color: button.color || baseButtonColor,
                                text: button.label,

                                formattedAmount: button.amount
                                    ? formatAmountWithCurrency(button.amount)
                                    : null,
                            };
                        } else if (button.function === "PLU_SEARCH") {
                            returnValue.buttonMetaData[button.id] = {
                                ...returnValue.buttonMetaData[button.id],

                                color: button.color || baseButtonColor,
                                text: button.label,

                                formattedAmount: null,
                            };
                        }
                    } else if (button.buttonType === "CMP_EVENT") {
                        let buttonLabel = "N/A";
                        let missingConfigurationData = false;

                        const foundEvent = returnValue.knownEvents.find(
                            item => item.externalId === button.eventId
                        );

                        if (foundEvent) {
                            buttonLabel = foundEvent.name;
                        } else {
                            missingConfigurationData = true;
                            buttonLabel = t(
                                "layout.error.missing_cmp_event_data",
                                "CMP Event not found: {{ cmpEventId }} ",
                                { cmpEventId: button.eventId }
                            );
                        }

                        if (!missingConfigurationData && button.label) {
                            buttonLabel = button.label;
                        }

                        returnValue.buttonMetaData[button.id] = {
                            ...returnValue.buttonMetaData[button.id],

                            color: button.color,
                            text: buttonLabel,

                            missingConfigurationData,
                        };
                    } else if (button.buttonType === "CMP_EVENT_PRODUCT") {
                        let buttonLabel = "N/A";
                        let buttonAmount = "N/A";
                        let missingConfigurationData = false;

                        const foundEvent = returnValue.knownEvents.find(
                            item => item.externalId === button.eventId
                        );

                        let foundProduct;
                        if (foundEvent) {
                            buttonLabel = foundEvent.name;

                            foundProduct = foundEvent.products.find(
                                item => item.externalId === button.productId
                            );
                        } else {
                            buttonLabel = t(
                                "layout.error.missing_cmp_event_data",
                                "CMP Event not found: {{ cmpEventId }} ",
                                { cmpEventId: button.eventId }
                            );
                            missingConfigurationData = true;
                        }

                        if (foundProduct) {
                            buttonLabel = foundProduct.name;

                            let calendar: CmpEventProductCalendar;
                            if (foundProduct.calendar) {
                                // There is a calendar, so just get the amount from the first date (or use 0 as fallback) and display it.
                                // It does not matter much if the date is right, we just want to show an amount here.

                                calendar = JSON.parse(foundProduct.calendar);

                                buttonAmount = formatAmountWithCurrency(
                                    calendar.dates[0]?.amount || 0
                                );
                                // }
                            } else if (foundProduct.amount) {
                                buttonAmount = formatAmountWithCurrency(
                                    foundProduct.amount
                                );
                            } else {
                                // The fallback if there's no amount in foundProduct - which should be unlikely.
                                buttonAmount = formatAmountWithCurrency(0);
                            }
                        } else {
                            // Only change the "error label" if we found an event, but the product is missing.
                            buttonLabel = !missingConfigurationData
                                ? t(
                                      "layout.error.missing_cmp_event_product_data",
                                      "CMP Event product not found: {{ cmpEventProductId }}",
                                      { cmpEventProductId: button.productId }
                                  )
                                : buttonLabel;
                            missingConfigurationData = true;
                        }

                        // If there were no missing data, then use the button label - if one is set.
                        if (!missingConfigurationData && button.label) {
                            buttonLabel = button.label;
                        }

                        returnValue.buttonMetaData[button.id] = {
                            ...returnValue.buttonMetaData[button.id],

                            color: button.color || baseButtonColor,
                            text: buttonLabel,

                            formattedAmount: buttonAmount,

                            // If the button is configured with missing CMP event/product data, then we present a GUI button with warnings.
                            missingConfigurationData,
                        };
                    } else if (button.buttonType === "CMP_PRODUCT") {
                        let buttonLabel = "N/A";
                        let buttonAmount = "N/A";
                        let missingConfigurationData = false;

                        const foundCmpProduct =
                            returnValue.knownCmpProducts.find(
                                item => item.externalId === button.productId
                            );

                        if (foundCmpProduct) {
                            buttonLabel = foundCmpProduct.name;

                            if (foundCmpProduct.amount) {
                                buttonAmount = formatAmountWithCurrency(
                                    foundCmpProduct.amount
                                );
                            }
                        } else {
                            missingConfigurationData = true;
                            buttonLabel = t(
                                "layout.error.missing_cmp_product_data",
                                "CMP Product not found: {{ cmpProductId }} ",
                                { cmpProductId: button.productId }
                            );
                        }

                        if (!missingConfigurationData && button.label) {
                            buttonLabel = button.label;
                        }

                        returnValue.buttonMetaData[button.id] = {
                            ...returnValue.buttonMetaData[button.id],

                            color: button.color || baseButtonColor,
                            text: buttonLabel,

                            formattedAmount: buttonAmount,

                            // If the button is configured with missing CMP event/product data, then we present a GUI button with warnings.
                            missingConfigurationData,
                        };
                    } else {
                        // This is a button of unknown type. Back off. The button will not be shown in layout.
                        return;
                    }

                    // find the dimension bounds for the button
                    const { width: maxWidth, height: maxHeight } =
                        getMaxDimensionsForCoordinate(
                            {
                                x: button.x,
                                y: button.y,
                            },
                            dimensions,
                            returnValue.sections[
                                returnValue.currentSectionIndex
                            ]?.buttons,
                            []
                        );

                    returnValue.buttonMetaData[button.id] = {
                        ...returnValue.buttonMetaData[button.id],

                        maxWidthValue: maxWidth,
                        maxHeightValue: maxHeight,
                    };

                    return button;
                })
                // Remove buttons with a "dead" product reference
                .filter(Boolean),
        })),
    };
}
