import React from "react";
import { Headline, Loading, useThemedStyle } from "@venuepos/react-common";
import { theme } from "@venuepos/react-common/src/theme/themes/venue-default";
import { format } from "date-fns";
import { GQDashboard } from "graphql-sdk";
import { useTranslation } from "locales";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from "victory";
import { dashboardStyleFunc } from "../styles";
import { formatAmount } from "lib";

export function InvoiceAmountPerHour({ data }: { data?: GQDashboard }) {
    const [t] = useTranslation();
    const sharedStyles = useThemedStyle(dashboardStyleFunc);

    // To get the x-axis working we need the element to be Date
    const amountData = data?.amountsByHour?.map(element => {
        return {
            datetime: new Date(element?.datetime),
            sum: element?.sum || 0,
        };
    });

    // If we dont have data for the whole day we only get ticks for the date and not the whole day
    // Build list of ticks for 24 hours starting at 06:00.
    const ticks = [];
    const offset = 6; // Start at 06:00

    for (let i = 0 + offset; i < 24 + offset; i++) {
        ticks.push(new Date(data?.from).setHours(i));
    }

    const padding = { left: 80, bottom: 40, top: 20, right: 10 };
    return (
        <>
            <Headline style={sharedStyles.totalHeadline} size="h5">
                {t(
                    "dashboard.widgets.invoice_amount_per_hour",
                    "Invoice amount per hour"
                )}
            </Headline>
            {data ? (
                <VictoryChart
                    height={300}
                    width={1200}
                    theme={VictoryTheme.material}
                    padding={padding}
                >
                    <VictoryAxis
                        dependentAxis
                        crossAxis={false}
                        label={t("common.amount", "Amount")}
                        style={{ axisLabel: { padding: 70 } }}
                    />
                    <VictoryAxis
                        tickFormat={tick => {
                            const date = new Date(tick);
                            return `${format(date, "H")}`;
                        }}
                        tickValues={ticks}
                        label={t("dashboard.invoice_amount_stat_time", "Time")}
                        style={{ axisLabel: { padding: 30 } }}
                    />

                    <VictoryBar
                        y={d => d.sum / 100}
                        data={amountData}
                        x="datetime"
                        labels={({ datum }) => formatAmount(datum.sum)}
                        style={{
                            data: {
                                width: 35,
                                fill: ({ datum }) =>
                                    datum.sum > 0
                                        ? theme.colors.secondary
                                        : theme.colors.error,
                            },
                        }}
                    />
                </VictoryChart>
            ) : (
                <Loading message={t("common.loading", "Loading")} />
            )}
        </>
    );
}
