import {
    LoadingScreen,
    StyleFunction,
    Surface,
    Tabs,
    Text,
    useAuth,
    useForm,
    useMe,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQIntegrationQuery,
    useIntegrationLazyQuery,
    useIntegrationSaveMutation,
} from "graphql-sdk";
import {
    IIntegrationInput,
    IMerchantChartOfAccountInput,
    IntegrationType,
    schemaIntegrationInput,
} from "lib";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";

import { useHandleMutationError } from "../../../hooks/use-handle-mutation-error";
import { RootStackScreenProps } from "../../../navigation";
import { EconomicSettingsTab } from "../economic-settings-tab";
import { GeneralForm } from "../general-form";
import { IntegrationScreen } from "../integration-screen";
import { CmpProductSettingsTab } from "../cmp-product-settings-tab";

type ScreenProps = RootStackScreenProps<"INTEGRATION_EDIT">;

export function EditIntegrationScreen({
    navigation: { navigate },
    route,
}: ScreenProps) {
    const auth = useAuth();
    auth.enforce("merchant.merchant_settings");

    const me = useMe();
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const integrationId = route.params.id;

    const { handleMutationError } = useHandleMutationError();
    const [integrationEdit] = useIntegrationSaveMutation();
    const [getIntegration, integration] = useIntegrationLazyQuery({
        fetchPolicy: "no-cache",
    });

    const form = useForm<IIntegrationInput>(schemaIntegrationInput, null);
    const [{ values }, { setDefaultValues }] = form;

    useEffect(() => {
        if (!integrationId) {
            navigate("INTEGRATIONS");
            return;
        }

        getIntegration({ variables: { id: integrationId } });
    }, [getIntegration, navigate, integrationId]);

    useEffect(() => {
        if (integration.data && integration.data.integration && me.merchant) {
            const p = integration.data.integration;
            setDefaultValues({
                type: p.type as IntegrationType,
                active: p.active,
                name: p.name,
                apiKey: p.apiKey,
                merchantChartOfAccounts: p.merchantChartOfAccounts,
                merchantId: me.merchant.id,
            });
        }
    }, [integration.data, setDefaultValues, me]);

    const edit = useCallback(async () => {
        if (
            !integration ||
            !integration.data ||
            !integration.data.integration ||
            !values ||
            values.type === undefined
        ) {
            return;
        }

        await handleMutationError(async () => {
            const merchantChartOfAccounts: IMerchantChartOfAccountInput[] = [];
            if (values.merchantChartOfAccounts.length > 0) {
                values.merchantChartOfAccounts.forEach(mcoa => {
                    let merchantChartOfAccount: IMerchantChartOfAccountInput = {
                        chartOfAccountsId: mcoa.chartOfAccountsId,
                        merchantAccountNo: mcoa.merchantAccountNo,
                        name: mcoa.name,
                    };
                    merchantChartOfAccounts.push(merchantChartOfAccount);
                });
            }

            await integrationEdit({
                variables: {
                    id: integration.data!.integration!.id,
                    integration: {
                        type: values.type!,
                        active: values.active,
                        name: values.name,
                        apiKey: values.apiKey,
                        merchantChartOfAccounts: merchantChartOfAccounts,
                        merchantId: values.merchantId,
                    },
                },
            });
        }, t("common.saved", "Saved"));
    }, [integration, values, handleMutationError, t, integrationEdit]);

    const renderTab = useCallback(
        (integrationType: GQIntegrationQuery["integration"]) => {
            switch (integrationType.type) {
                case "E-CONOMIC":
                    return (
                        <EconomicSettingsTab integration={integrationType} />
                    );
                case "CMP-PRODUCT":
                    return (
                        <CmpProductSettingsTab integration={integrationType} />
                    );
                default:
                    return (
                        <View>
                            <Text>
                                {t(
                                    "backoffice.integration.settings.none",
                                    "There are no settings for this integration"
                                )}
                            </Text>
                        </View>
                    );
            }
        },
        [t]
    );

    if (!integrationId || !integration?.data?.integration) {
        return <LoadingScreen style="light" />;
    }

    return (
        <IntegrationScreen>
            <View style={styles.container}>
                <Surface>
                    <Tabs
                        labels={[
                            t("backoffice.common.integration", "Integration"),
                            t(
                                "backoffice.common.configuration",
                                "Configuration"
                            ),
                        ]}
                    >
                        <GeneralForm
                            form={form}
                            onSubmit={edit}
                            submitButton={["common.edit", "Edit"]}
                            pickerDisabled={true}
                        />
                        {renderTab(integration.data.integration)}
                    </Tabs>
                </Surface>
            </View>
        </IntegrationScreen>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        width: "100%",
        maxWidth: theme.dimensions.maxFormWidth,
        alignSelf: "flex-start",
    },
});
