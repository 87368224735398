import type { AppliedDiscount } from "../discount";
import type { OrderItem, OrderItemGroup, Payment } from "./types";
import type { OrderContext } from "./machine";
import type { OrderInvoiceStartedEvent } from "./machine-events";
import {
    Fraction,
    divideFractions,
    subtractFractions,
    addFractions,
    multiplyFractions,
    minFractions,
} from "../utility";
import type { Currency } from "../currencies/currencies";
import { v4 as uuidv4 } from "uuid";

export type InvoiceLine = {
    itemGroupIndex: number;
    itemGroupType: OrderItemGroup["type"];
    itemGroupNumberOfItems: number;
    itemGroupNumberOfRemovableItems: number;
    itemGroupProductId: OrderItemGroup["productId"];
    itemGroupProductGroupId: OrderItemGroup["productGroupId"];
    itemGroupProductGroupName: OrderItemGroup["productGroupName"];
    fraction: Fraction;
    amount: number;
    costAmount: number;
    vatAmount: number;
    vat: OrderItem["vat"];
    discountAmount: number;
    discountLineDescription?: string;
    quantity: number;
    name: string;
    note?: string;
    external?: OrderItem["external"][];
};

export type InvoiceLines = InvoiceLine[];

export type Invoice = {
    id: string;
    lines: InvoiceLines;
    currency: Currency;
    itemAmount: number;
    totalAmount: number;
    totalVatAmount: number;
    discountAmount: number;
    paymentAmount: number;
    remainder: number;
    payments: Payment[];
    departmentId?: string;
    shiftId: string;
    orderId: string;
    appliedDiscounts: AppliedDiscount[];
    date: string;
    name: string;
    externalReceipts?: { integrationId: string; receipt: string }[];
};

export type InvoiceOpen = Invoice & {
    status: "OPEN";
    externalId?: string;
};

export type InvoiceClosed = Invoice & {
    status: "CLOSED";
    receiptText: string;
    receiptNumber: number;
    externalId?: string;
};

export type SplitInvoiceLineResult = {
    to: InvoiceLines;
    from: InvoiceLines;
    lineDeletedFromOrigin: boolean;
};

function itemGroupOrderDiscountAmount(
    {
        itemGroups,
        appliedDiscounts,
    }: Pick<OrderContext, "itemGroups" | "appliedDiscounts">,
    itemAmount: number,
    itemGroupIndex: number
) {
    const itemGroup = itemGroups[itemGroupIndex];
    if (
        itemGroup.excludeFromDiscounts ||
        itemGroup.amount < 0 ||
        itemGroup.type === "RETURN"
    ) {
        return 0;
    }

    const orderDiscount = appliedDiscounts.find(
        itr => itr.discount.type === "ORDER"
    );
    if (!orderDiscount) {
        return 0;
    }

    // If the item group is the last one of the non excluding groups,
    // we'll then put all the remaining rounding error amount on this.
    let lastItemGroupIndex: number = 0;
    for (let i = 0; i < itemGroups.length; i++) {
        if (
            !itemGroups[i].excludeFromDiscounts &&
            itemGroups[i].amount >= 0 &&
            itemGroups[i].type !== "RETURN"
        ) {
            lastItemGroupIndex = i;
        }
    }

    const itemAmountWithoutExcluded =
        itemAmount -
        itemGroups.reduce(
            (sum, itr) =>
                sum +
                (itr.excludeFromDiscounts ||
                itr.amount < 0 ||
                itr.type === "RETURN"
                    ? itr.amount
                    : 0),
            0
        );

    if (itemGroupIndex === lastItemGroupIndex) {
        let savedAmount = 0;
        for (let i = 0; i < lastItemGroupIndex; i++) {
            if (
                itemGroups[i].excludeFromDiscounts ||
                itemGroups[i].amount < 0 ||
                itemGroups[i].type === "RETURN"
            ) {
                continue;
            }
            savedAmount += Math.round(
                (itemGroups[i].amount / itemAmountWithoutExcluded) *
                    orderDiscount.amount
            );
        }

        return orderDiscount.amount - savedAmount;
    }

    // Else we just use the propositional amount saved
    return Math.round(
        (itemGroup.amount / itemAmountWithoutExcluded) * orderDiscount.amount
    );
}

function itemGroupDiscount(
    {
        itemGroups,
        appliedDiscounts,
    }: Pick<OrderContext, "itemGroups" | "appliedDiscounts">,
    itemAmount: number,
    itemGroupIndex: number
) {
    const itemGroup = itemGroups[itemGroupIndex];

    const orderDiscountSavedAmount = itemGroupOrderDiscountAmount(
        { itemGroups, appliedDiscounts },
        itemAmount,
        itemGroupIndex
    );

    if (itemGroup.discountKey === undefined || itemGroup.discountKey < 0) {
        return { discountAmount: orderDiscountSavedAmount };
    }

    const discountItemGroupsIndices: number[] = [];
    for (let i = 0; i < itemGroups.length; i++) {
        if (itemGroups[i].discountKey === itemGroup.discountKey) {
            discountItemGroupsIndices.push(i);
        }
    }

    // Get total amount of all item groups that uses the same product discount
    let totalAmount = 0;
    for (let i = 0; i < discountItemGroupsIndices.length; i++) {
        totalAmount += itemGroups[discountItemGroupsIndices[i]].amount;
    }

    // If the item group is the last one of the item groups for the product discount,
    // we'll then put all the remaining rounding error amount on this.
    if (
        discountItemGroupsIndices[discountItemGroupsIndices.length - 1] ===
        itemGroupIndex
    ) {
        let savedAmount = 0;
        for (let i = 0; i < discountItemGroupsIndices.length - 1; i++) {
            savedAmount += Math.round(
                (itemGroups[discountItemGroupsIndices[i]].amount /
                    totalAmount) *
                    appliedDiscounts[itemGroup.discountKey].amount
            );
        }

        return {
            discountLineDescription:
                appliedDiscounts[itemGroup.discountKey].discount
                    .lineDescription,
            discountAmount:
                appliedDiscounts[itemGroup.discountKey].amount -
                savedAmount +
                orderDiscountSavedAmount,
        };
    } else {
        return {
            discountLineDescription:
                appliedDiscounts[itemGroup.discountKey].discount
                    .lineDescription,
            discountAmount:
                Math.round(
                    (itemGroup.amount / totalAmount) *
                        appliedDiscounts[itemGroup.discountKey].amount
                ) + orderDiscountSavedAmount,
        };
    }
}

export function createInvoiceLines({
    items,
    returnedItems,
    itemGroups,
    appliedDiscounts,
}: Pick<
    OrderContext,
    "items" | "returnedItems" | "itemGroups" | "appliedDiscounts"
>): InvoiceLines {
    // Calculate item amount, as the total `itemAmount` from order context is based on the
    // lines created from this function, so it could result in a delayed invalid amount.
    const itemAmount =
        items.reduce((sum, itr) => sum + itr.amount - itr.paid.amount, 0) -
        returnedItems.reduce(
            (sum, itr) => sum - (itr.amount - itr.paid.amount),
            0
        );

    const lines: InvoiceLines = [];
    for (let i = 0; i < itemGroups.length; i++) {
        const itemGroupIndex = i;
        const itemGroup = itemGroups[itemGroupIndex];

        const filteredItems =
            itemGroup.type === "ITEM"
                ? items.filter(itr => itemGroup.items.includes(itr.itemId))
                : returnedItems.filter(itr =>
                      itemGroup.items.includes(itr.itemId)
                  );

        // Ignore line if all items are fully paid
        if (
            !filteredItems.find(
                itr =>
                    itr.paid.fraction.numerator === 0 ||
                    itr.paid.fraction.numerator !==
                        itr.paid.fraction.denominator
            )
        ) {
            continue;
        }

        const paid = filteredItems.reduce(
            (sum, itr) => ({
                fraction: addFractions(sum.fraction, itr.paid.fraction),
                amount: sum.amount + itr.paid.amount,
                vatAmount: sum.vatAmount + itr.paid.vatAmount,
                costAmount: sum.costAmount + itr.paid.costAmount,
            }),
            {
                fraction: { numerator: 0, denominator: 0 },
                amount: 0,
                vatAmount: 0,
                costAmount: 0,
            }
        );

        const paidFraction = multiplyFractions(paid.fraction, {
            numerator: 1,
            denominator: itemGroup.items.length,
        });

        const paidFactor =
            paidFraction.denominator === 0
                ? 0
                : paidFraction.numerator / paidFraction.denominator;

        const itemGroupNumberOfItems = filteredItems.length;
        const itemGroupNumberOfRemovableItems = filteredItems.reduce(
            (counter, item) => counter - (item.paid.amount > 0 ? 1 : 0),
            itemGroupNumberOfItems
        );

        const amountSign = itemGroup.type === "ITEM" ? 1 : -1;
        const amount = (itemGroup.amount - paid.amount) * amountSign;
        const vatAmount = (itemGroup.vatAmount - paid.vatAmount) * amountSign;
        // Just get the vat from the first item as they should be the same for the itemGroup
        const vat = filteredItems[0].vat;

        // We don't need amount sign on `discountAmount` since discounts can't be applied to return items
        let { discountAmount, discountLineDescription } = itemGroupDiscount(
            { itemGroups, appliedDiscounts },
            itemAmount,
            itemGroupIndex
        );
        discountAmount = Math.round(
            discountAmount - discountAmount * paidFactor
        );
        if (discountAmount !== 0) {
            discountAmount = discountAmount * amountSign;
        }
        const quantity = itemGroup.quantity - itemGroup.quantity * paidFactor;

        const costAmount =
            itemGroup.costAmount - itemGroup.costAmount * paidFactor;

        const fraction = subtractFractions(
            {
                numerator: 1,
                denominator: 1,
            },
            paidFraction
        );

        const external: OrderItem["external"][] = [];
        for (let j = 0; j < filteredItems.length; j++) {
            if (filteredItems[j].external) {
                external.push(filteredItems[j].external);
            }
        }

        const name = itemGroup.name;
        const note = itemGroup.note;

        lines.push({
            itemGroupIndex: i,
            itemGroupType: itemGroup.type,
            itemGroupNumberOfRemovableItems,
            itemGroupNumberOfItems,
            itemGroupProductId: itemGroup.productId,
            itemGroupProductGroupId: itemGroup.productGroupId,
            itemGroupProductGroupName: itemGroup.productGroupName,
            fraction,
            amount,
            vatAmount,
            costAmount,
            vat,
            discountAmount,
            discountLineDescription,
            quantity,
            name,
            note,
            external: external && external.length > 0 ? external : undefined,
        });
    }

    return lines;
}

function updateLines(
    lineIndex: number,
    from: InvoiceLines,
    to: InvoiceLines,
    fraction: Fraction
): SplitInvoiceLineResult {
    const line = from[lineIndex];

    const newLine = {
        ...line,
    };
    const itemGroupFraction = multiplyFractions(line.fraction, fraction);

    const result: SplitInvoiceLineResult = {
        from: [...from],
        to: [...to],
        lineDeletedFromOrigin: false,
    };

    const oldLine = result.from[lineIndex];
    const size = fraction.numerator / fraction.denominator;
    newLine.quantity = oldLine.quantity * size;
    newLine.amount = Math.round(oldLine.amount * size);
    newLine.vatAmount = Math.round(oldLine.vatAmount * size);
    newLine.discountAmount = Math.round(oldLine.discountAmount * size);
    newLine.costAmount = Math.round(oldLine.costAmount * size);

    const matchingItemGroup = to.findIndex(
        itr => itr.itemGroupIndex === line.itemGroupIndex
    );

    if (matchingItemGroup >= 0) {
        newLine.fraction = addFractions(
            result.to[matchingItemGroup].fraction,
            itemGroupFraction
        );
    } else {
        newLine.fraction = itemGroupFraction;
    }

    if (matchingItemGroup >= 0) {
        const matchingLine = result.to[matchingItemGroup];
        matchingLine.fraction = newLine.fraction;
        matchingLine.quantity += newLine.quantity;
        matchingLine.amount += newLine.amount;
        matchingLine.vatAmount += newLine.vatAmount;
        matchingLine.discountAmount += newLine.discountAmount;
        matchingLine.costAmount += newLine.costAmount;
    } else {
        result.to.push(newLine);
    }

    const subtracted = subtractFractions(line.fraction, itemGroupFraction);
    if (subtracted.numerator === 0) {
        result.from.splice(lineIndex, 1);
        result.lineDeletedFromOrigin = true;
    } else {
        result.from[lineIndex] = {
            itemGroupIndex: oldLine.itemGroupIndex,
            itemGroupType: oldLine.itemGroupType,
            itemGroupNumberOfRemovableItems:
                oldLine.itemGroupNumberOfRemovableItems,
            itemGroupNumberOfItems: oldLine.itemGroupNumberOfItems,
            itemGroupProductId: oldLine.itemGroupProductId,
            itemGroupProductGroupId: oldLine.itemGroupProductGroupId,
            itemGroupProductGroupName: oldLine.itemGroupProductGroupName,
            fraction: subtracted,
            amount: oldLine.amount - newLine.amount,
            costAmount: oldLine.costAmount - newLine.costAmount,
            vatAmount: oldLine.vatAmount - newLine.vatAmount,
            vat: oldLine.vat,
            quantity: oldLine.quantity - newLine.quantity,
            discountAmount: oldLine.discountAmount - newLine.discountAmount,
            discountLineDescription: oldLine.discountLineDescription,
            name: oldLine.name,
            note: oldLine.note,
            external: oldLine.external,
        };
    }

    return result;
}

export function splitInvoiceLine(
    lineIndex: number,
    splitBy: number,
    from: InvoiceLines,
    to: InvoiceLines
): SplitInvoiceLineResult {
    if (lineIndex >= from.length || lineIndex < 0) {
        throw new Error("Line does not exist");
    }

    return updateLines(lineIndex, from, to, {
        numerator: 1,
        denominator: splitBy,
    });
}

export function moveInvoiceLine(
    lineIndex: number,
    fraction: Fraction,
    from: InvoiceLines,
    to: InvoiceLines
): SplitInvoiceLineResult {
    if (lineIndex >= from.length || lineIndex < 0) {
        throw new Error("Line does not exist");
    }

    return updateLines(
        lineIndex,
        from,
        to,
        divideFractions(
            minFractions(from[lineIndex].fraction, fraction),
            from[lineIndex].fraction
        )
    );
}

export function moveInvoiceLineUnit(
    { itemGroups }: Pick<OrderContext, "itemGroups">,
    lineIndex: number,
    from: InvoiceLines,
    to: InvoiceLines
): SplitInvoiceLineResult {
    if (lineIndex >= from.length || lineIndex < 0) {
        throw new Error("Line does not exist");
    }

    const line = from[lineIndex];
    return moveInvoiceLine(
        lineIndex,
        {
            numerator: 1,
            denominator: itemGroups[line.itemGroupIndex].quantity,
        },
        from,
        to
    );
}

export function splitInvoice(
    splitBy: number,
    from: InvoiceLines,
    to: InvoiceLines
): Pick<SplitInvoiceLineResult, "from" | "to"> {
    let expiredIndices = 0;
    let result: SplitInvoiceLineResult = {
        from,
        to,
        lineDeletedFromOrigin: false,
    };

    for (let i = 0; i < from.length; i++) {
        result = splitInvoiceLine(
            i - expiredIndices,
            splitBy,
            result.from,
            result.to
        );

        if (result.lineDeletedFromOrigin) {
            expiredIndices++;
        }
    }

    return { from: result.from, to: result.to };
}

export function invoiceTotals(lines: InvoiceLines) {
    let itemAmount = 0;
    let totalVatAmount = 0;
    let discountAmount = 0;

    for (let j = 0; j < lines.length; j++) {
        const line = lines[j];

        itemAmount += line.amount;

        totalVatAmount += line.vatAmount;
        discountAmount += line.discountAmount;
    }

    return {
        itemAmount,
        totalVatAmount,
        discountAmount,
        totalAmount: itemAmount - discountAmount,
    };
}

export function createOpenInvoiceFromLines(
    {
        shiftId,
        id: orderId,
        appliedDiscounts,
        currency,
        name,
        lines: orderLines,
        departmentId,
    }: OrderContext,
    event?: OrderInvoiceStartedEvent
): InvoiceOpen {
    const lines = event ? event.data.lines : orderLines;

    const { itemAmount, totalAmount, totalVatAmount, discountAmount } =
        invoiceTotals(lines);

    return {
        id: event?.data.id || uuidv4(),
        status: "OPEN",
        lines,
        totalAmount,
        remainder: totalAmount,
        itemAmount,
        totalVatAmount,
        discountAmount,
        paymentAmount: 0,
        payments: [],
        departmentId,
        shiftId,
        orderId,
        appliedDiscounts: [...appliedDiscounts],
        date: event?.eventDate || new Date().toISOString(),
        currency: event?.data.currency || currency,
        name: event ? event.data.name : name,
        externalId: event.data.externalId,
    };
}
