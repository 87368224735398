import type { TFunction } from "i18next";
import {
    ButtonPosition,
    GridDimensions,
    ProductButton,
    ProductSection,
} from "lib";
import { positionButtonInFirstAvailableSpot, produceNewLayoutSection } from ".";
import { getButtonPositionsForSection } from "./get-button-positions-for-section";
import { setButtonPosition } from "./set-button-position";

export const positionProductButtonsInSections = (
    layoutProductButtons: ProductButton[],
    layoutSections: ProductSection[],
    currentSectionIndex: number,
    buttonPositions: string[],
    startAtPosition: ButtonPosition,
    gridDimensions: GridDimensions,
    t: TFunction | string
): { updatedSections: ProductSection[]; updatedSectionIndex: number } => {
    let updatedSections = [...layoutSections];
    let currentSection = { ...updatedSections[currentSectionIndex] };
    let currentSectionButtons = [...currentSection.buttons];
    let skipToPosition =
        startAtPosition.y * gridDimensions.columns + startAtPosition.x;

    layoutProductButtons.forEach((layoutButton: ProductButton) => {
        // If there is no more available space in this section, produce a new one.
        let nextAvailableButtonPosition = buttonPositions.findIndex(
            (position, index) => index >= skipToPosition && !position
        );

        if (nextAvailableButtonPosition === -1) {
            // Update the section with the added buttons.
            currentSection.buttons = currentSectionButtons;

            // Update the collection of sections
            updatedSections[currentSectionIndex] = currentSection;

            // produce a new section and add buttons to it.
            const newSection: ProductSection = produceNewLayoutSection(
                t,
                String(updatedSections.length + 1)
            ) as ProductSection;

            // Put the new section in after the currently selected section
            currentSectionIndex++;

            // insert new section in the sections array after the currently selected section
            updatedSections.splice(currentSectionIndex, 0, newSection);

            // Prepare a new buttonPositions array
            buttonPositions = getButtonPositionsForSection([], gridDimensions);
            currentSection = newSection;
            nextAvailableButtonPosition = 0;
            currentSectionButtons = [];
            skipToPosition = 0;
        }

        const _x = nextAvailableButtonPosition % gridDimensions.columns;
        const _y = Math.floor(
            nextAvailableButtonPosition / gridDimensions.columns
        );

        // put the button in the first available position
        const positionedButton = positionButtonInFirstAvailableSpot(
            layoutButton,
            buttonPositions,
            skipToPosition,
            gridDimensions.columns
        );

        // update the button position cache
        buttonPositions = setButtonPosition(
            buttonPositions,
            gridDimensions,
            { x: _x, y: _y },
            { width: layoutButton.width, height: layoutButton.height },
            layoutButton!.id as string
        );

        currentSectionButtons.push(positionedButton);
    });

    // Update the section with the added buttons.
    currentSection.buttons = currentSectionButtons;

    // Update the collection of sections
    updatedSections[currentSectionIndex] = currentSection;

    return {
        updatedSections,
        updatedSectionIndex: currentSectionIndex,
    };
};
