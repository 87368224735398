/* istanbul ignore file */
import type { IMerchantChartOfAccountInput } from "../..";

export type IntegrationType =
    | "E-CONOMIC"
    | "PARTNER-API"
    | "VENUE-ACCESS"
    | "CMP-PRODUCT";

export const integrationTypes: IntegrationType[] = [
    "E-CONOMIC",
    "PARTNER-API",
    "VENUE-ACCESS",
    "CMP-PRODUCT",
];

export type IIntegration<Type extends IntegrationType = IntegrationType> = {
    id: string;
    merchantId: string;
    type?: Type;
    active: boolean;
    name: string;
    apiKey: string;
    createdAt: Date;
    deletedAt: Date | null;
    updatedAt: Date | null;
    merchantChartOfAccounts: IMerchantChartOfAccountInput[];
};

export type IIntegrationInput = Omit<
    IIntegration,
    "id" | "createdAt" | "deletedAt" | "updatedAt"
>;

export type IIntegrationApiKey = Pick<
    IIntegration,
    "apiKey" | "merchantId" | "type"
>;

export interface IntegrationSettings {
    id: string;
    type: IntegrationType;
}

export interface EconomicSettings extends IntegrationSettings {
    journalId: number;
    type: "E-CONOMIC";
}

export interface PartnerApiSettings extends IntegrationSettings {
    type: "PARTNER-API";
}

export interface VenueAccessSettings extends IntegrationSettings {
    type: "VENUE-ACCESS";
}

export interface CmpProductSettings extends IntegrationSettings {
    type: "CMP-PRODUCT";
    hostname: string;
    port: number;
    vhost: string;
    exchangeName: string;
    queueName: string;
    routingKey: string;
    username: string;
    password: string;
    cmpOrderUrl: string;
}

// Input types

export type IntegrationSettingsInput = Omit<IntegrationSettings, "id" | "type">;

export type EconomicSettingsInput = Omit<EconomicSettings, "id" | "type">;

export type CmpProductSettingsInput = Omit<CmpProductSettings, "id" | "type">;
