import {
    InputControl,
    Loading,
    Picker,
    RequiredText,
    Spacer,
    useForm,
} from "@venuepos/react-common";
import {
    ButtonDimensions,
    BuyAccountFundsButtonType,
    CustomItemButtonType,
    defaultFunctionButtonSchema,
    FunctionButton,
    FunctionButtonInput,
    LayoutButtonMetaData,
    PluSearchButtonType,
    VenueAccessTicketButtonType,
} from "lib";
import { useTranslation } from "locales";
import React, { useCallback } from "react";
import { Divider } from "react-native-paper";
import { AddButtonModalOutput, FunctionButtonModalOutput } from "..";
import { ButtonSizeForm } from "../../../../button-size-form";
import { schemaButtonDimensions } from "../validation";
import { BuyAccountFundsSubForm } from "./buy-account-funds-sub-form";
import { CustomItemSubForm } from "./custom-item-sub-form";
import { PLUSearchSubForm } from "./plu-sub-form";
import { VenueAccessTicketsSubForm } from "./venue-access-tickets-sub-form";

export function FunctionButtonForm({
    values: buttonDefaultValues,
    onSubmit: submitCallback,
    metaData,
}: {
    onSubmit: (button: AddButtonModalOutput) => void;
    values: FunctionButton;
    metaData: LayoutButtonMetaData;
}) {
    const [t] = useTranslation();

    const dimensionForm = useForm<ButtonDimensions>(schemaButtonDimensions, {
        width: buttonDefaultValues.width || 1,
        height: buttonDefaultValues.height || 1,
    });

    const [{ values: dimensionFormValues }] = dimensionForm;

    const functionButtonMainForm = useForm<FunctionButtonInput>(
        defaultFunctionButtonSchema,
        {
            ...buttonDefaultValues,
            buttonType: "FUNCTION",
        }
    );
    const [{ values, errors }, { setValue }] = functionButtonMainForm;

    const onSubmit = useCallback(
        (returnValues: FunctionButtonModalOutput) => {
            if (!values) {
                return;
            }

            submitCallback({
                ...returnValues,

                buttonType: "FUNCTION",

                formValues: {
                    ...dimensionFormValues,
                    ...returnValues.formValues,
                },

                displayValues: {
                    ...returnValues.displayValues,
                },
            });
        },
        [dimensionFormValues, submitCallback, values]
    );

    if (!values) {
        return <Loading />;
    }

    return (
        <>
            <ButtonSizeForm form={dimensionForm} metaData={metaData} />
            <InputControl error={errors.function}>
                <Picker
                    required
                    label={t(
                        "layout.function_button_form.function",
                        "Function"
                    )}
                    onValueChange={value => {
                        setValue(
                            "function",
                            value as FunctionButton["function"]
                        );
                    }}
                    selectedValue={values.function || undefined}
                >
                    <Picker.Item
                        label={t(
                            "layout.function_button_form.types.none",
                            "Select"
                        )}
                        value=""
                    />
                    <Picker.Item
                        value="CUSTOM_ITEM"
                        label={t(
                            "layout.function_button_form.types.custom_item",
                            "Custom item"
                        )}
                    />
                    <Picker.Item
                        value="BUY_ACCOUNT_FUNDS"
                        label={t(
                            "layout.function_button_form.types.buy_account_funds",
                            "Buy account funds"
                        )}
                    />
                    <Picker.Item
                        value="VENUE_ACCESS_TICKETS"
                        label={t(
                            "layout.function_button_form.types.venue_access_tickets",
                            "Buy ticket from Venue Access"
                        )}
                    />
                    <Picker.Item
                        value="PLU_SEARCH"
                        label={t(
                            "layout.function_button_form.types.plu_search",
                            "PLU search"
                        )}
                    />
                </Picker>
            </InputControl>
            <Divider />
            <Spacer space={2} />
            <SubForm
                buttonForm={values.function}
                values={buttonDefaultValues}
                onSubmit={onSubmit}
            />
            <RequiredText />
        </>
    );
}

function SubForm({
    buttonForm,
    values,
    onSubmit,
}: {
    buttonForm: FunctionButton["function"];
    values: FunctionButton;
    onSubmit: (values: FunctionButtonModalOutput) => void;
}) {
    switch (buttonForm) {
        case "CUSTOM_ITEM":
            return (
                <CustomItemSubForm
                    values={values as CustomItemButtonType}
                    onSubmit={onSubmit}
                />
            );
        case "BUY_ACCOUNT_FUNDS":
            return (
                <BuyAccountFundsSubForm
                    values={values as BuyAccountFundsButtonType}
                    onSubmit={onSubmit}
                />
            );
        case "VENUE_ACCESS_TICKETS":
            return (
                <VenueAccessTicketsSubForm
                    values={values as VenueAccessTicketButtonType}
                    onSubmit={onSubmit}
                />
            );
        case "PLU_SEARCH":
            return (
                <PLUSearchSubForm
                    values={values as PluSearchButtonType}
                    onSubmit={onSubmit}
                />
            );
        default: {
            return null;
        }
    }
}
