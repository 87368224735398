import type {
    AppliedDiscount,
    DiscountType,
    ItemPool,
    ItemsWithSavedAmount,
    PartialOrderContext,
    SuggestedAppliedDiscount,
    SuggestedDiscount,
} from "./types";
import {
    checkConditions,
    filterOrderItems,
    SuccessfulConditions,
} from "../entity-condition";
import "lodash.combinations";

// This method takes an order and an arbitrary number of discounts, and will
// return the best possible combination of the discounts
export function calculateDiscounts(
    orderContext: PartialOrderContext,
    discounts: DiscountType[]
): AppliedDiscount[] {
    // First start by check if we even received any discounts,
    // if not, then no discounts could ever be applied
    if (
        (orderContext.items.length === 0 &&
            orderContext.returnedItems.length === 0) ||
        discounts.length === 0
    ) {
        return [];
    }

    // Exclude items that has been marked as being excluded from discount calculations
    const excludedOrderContext = {
        ...orderContext,
        // Filter away the products that should be excluded from discounts
        items: orderContext.items.filter(
            itr => !itr.excludeFromDiscounts && itr.amount >= 0
        ),
    };

    const appliedGroupDiscounts = calculateGroupDiscounts(
        excludedOrderContext,
        discounts
    );

    const excludedOrderContextWithoutGroupDiscountItems = {
        ...orderContext,
        // Filter away the products that has already been used for group discounts
        items: excludedOrderContext.items.filter(
            itr =>
                !appliedGroupDiscounts.find(i => i.items?.includes(itr.itemId))
        ),
    };

    const appliedProductDiscounts = calculateProductDiscounts(
        excludedOrderContextWithoutGroupDiscountItems,
        discounts
    );
    const appliedReturnDiscounts = calculateReturnDiscounts(
        excludedOrderContextWithoutGroupDiscountItems,
        discounts
    );

    // Before applying order discounts, we need to split the product discount between their items,
    // so we can make sure that manual order discounts dont exceed products minimum amount
    const itemsWithSavedAmount = getItemWithSavedAmount(
        excludedOrderContext,
        appliedProductDiscounts
    );

    const appliedOrderDiscount = calculateOrderDiscount(
        excludedOrderContext,
        itemsWithSavedAmount,
        discounts
    );

    if (appliedOrderDiscount !== false) {
        return appliedGroupDiscounts
            .concat(appliedProductDiscounts)
            .concat(appliedReturnDiscounts)
            .concat(appliedOrderDiscount);
    } else {
        return appliedGroupDiscounts
            .concat(appliedProductDiscounts)
            .concat(appliedReturnDiscounts);
    }
}

export function getItemWithSavedAmount(
    orderContext: PartialOrderContext,
    productDiscounts: AppliedDiscount[]
): ItemsWithSavedAmount {
    // Get items sorted by minimum price, from highest to lowest
    const items = orderContext.items.reduce(
        (concatItems, item) =>
            concatItems.concat([
                {
                    ...item,
                    savedAmount: 0,
                    minimumAmount: item.minimumAmount || 0,
                },
            ]),
        [] as ItemsWithSavedAmount
    );

    productDiscounts.forEach(discount => {
        // Get total item amount for all items for discount
        const discountItems = discount
            .items!.reduce(
                (filteredItems, itemId) =>
                    filteredItems.concat([
                        items.find(item => item.itemId === itemId)!,
                    ]),
                [] as ItemsWithSavedAmount
            )
            .sort((a, b) =>
                a.minimumAmount === b.minimumAmount
                    ? 0
                    : a.minimumAmount < b.minimumAmount
                    ? 1
                    : -1
            );
        let itemTotal = discountItems.reduce(
            (total, item) => total + item.amount,
            0
        );

        let amountLeftToBeSplit = discount.amount;
        discountItems.forEach(item => {
            // We check of the discount is a manual discount, as the minimum product amount should only be applied if so
            const minimumAmount =
                discount.discount.manual && item.minimumAmount
                    ? item.minimumAmount
                    : 0;

            // Round to 5 decimals
            const splitSavedAmount =
                Math.round(
                    Math.min(
                        item.amount - minimumAmount,
                        (item.amount / itemTotal) * amountLeftToBeSplit
                    ) * 100000
                ) / 100000;

            item.savedAmount = splitSavedAmount;
            amountLeftToBeSplit -= splitSavedAmount;
            itemTotal -= item.amount;
        });
    });

    return items;
}

export function calculateOrderDiscount(
    orderContext: PartialOrderContext,
    itemsWithSavedAmount: ItemsWithSavedAmount,
    discounts: DiscountType[]
): AppliedDiscount | false {
    // Get the suggested discounts
    let suggestedOrderDiscounts: SuggestedDiscount[] = [];
    for (let i = 0; i < discounts.length; i++) {
        if (discounts[i].type === "ORDER") {
            const suggestion = attemptApplyingOrderDiscount(
                orderContext,
                itemsWithSavedAmount,
                discounts[i]
            );
            if (suggestion) {
                suggestedOrderDiscounts.push(suggestion);
            }
        }
    }

    // Sort suggested discounts by best discount amount and type
    suggestedOrderDiscounts.sort(compareSuggestedOrderDiscounts);

    // Apply ORDER discounts, by just take the first suggested discount,
    // as we've just sorted them by best saving amount
    if (suggestedOrderDiscounts.length > 0) {
        return {
            discount: suggestedOrderDiscounts[0].discount,
            amount: suggestedOrderDiscounts[0].amount,
        };
    }

    return false;
}

export function calculateProductDiscounts(
    orderContext: PartialOrderContext,
    discounts: DiscountType[]
): AppliedDiscount[] {
    // Get the suggested discounts
    let suggestedProductDiscounts: Readonly<SuggestedDiscount>[] = [];
    for (let i = 0; i < discounts.length; i++) {
        if (discounts[i].type === "PRODUCT") {
            const suggestion = attemptApplyingProductDiscount(
                orderContext,
                discounts[i]
            );
            if (suggestion) {
                suggestedProductDiscounts.push(suggestion);
            }
        }
    }

    const appliedDiscounts = combineSuggestedProductDiscountsForBestSavings(
        suggestedProductDiscounts
    );

    return applyProductDiscountToDesiredItems(
        orderContext.items,
        appliedDiscounts
    );
}

export function calculateReturnDiscounts(
    orderContext: PartialOrderContext,
    discounts: DiscountType[]
): AppliedDiscount[] {
    // Get the suggested discounts
    let suggestedProductDiscounts: Readonly<SuggestedDiscount>[] = [];
    for (let i = 0; i < discounts.length; i++) {
        if (discounts[i].type === "RETURN") {
            const suggestion = attemptApplyingProductDiscount(
                orderContext,
                discounts[i]
            );
            if (suggestion) {
                suggestedProductDiscounts.push(suggestion);
            }
        }
    }

    const appliedDiscounts = combineSuggestedProductDiscountsForBestSavings(
        suggestedProductDiscounts
    );

    return applyProductDiscountToDesiredItems(
        orderContext.items,
        appliedDiscounts
    );
}

type CombinationWithSavings = {
    itemIds: string[];
    savedAmount: number;
    totalAmount: number;
};

export function calculateGroupDiscounts(
    orderContext: PartialOrderContext,
    discounts: DiscountType[]
): AppliedDiscount[] {
    const appliedDiscounts: AppliedDiscount[] = [];

    const itemIdsUsed: number[] = [];

    // Only handle group discounts
    const groupDiscounts = discounts.filter(itr => itr.type === "GROUP");

    // Sort all discounts with the highest savings first. This means that we start applying the discount with the highest savings first.
    // Note that this only applies for discounts of the same valueType (PERCENTAGE, ABSOLUTE and RELATIVE)
    const sortedDiscounts = groupDiscounts.sort((a, b) => {
        if (a.valueType !== b.valueType) {
            return 0;
        }

        if (a.value === b.value) {
            return 0;
        }

        if (a.valueType === "PERCENTAGE") {
            return a.value > b.value ? -1 : 1;
        }
        if (a.valueType === "RELATIVE") {
            return a.value > b.value ? -1 : 1;
        } else {
            return a.value > b.value ? 1 : -1;
        }
    });

    for (let i = 0; i < sortedDiscounts.length; i++) {
        const discount = sortedDiscounts[i];
        if (discount.type !== "GROUP") {
            continue;
        }
        const suggestion = attemptApplyingGroupDiscount(orderContext, discount);

        // The discount can be applied (conditions fulfilled)
        if (!suggestion) {
            continue;
        }
        const combinationsBySuggestion: CombinationWithSavings[][] = [];

        for (let j = 0; j < suggestion.order.length; j++) {
            // Build list of all combinations of items of requiredQuantity length
            const requiredQuantity =
                suggestion.order![j].output.requiredQuantity;

            // Remove already used itemIds
            const itemIds = suggestion.order[j].output.itemIds.filter(
                itr => !itemIdsUsed.includes(itr)
            );

            if (itemIds.length === 0) {
                break;
            }

            // Sort the items by their amount as this should make sure that we use the items with the largest savings first.
            const itemIdsSortedByAmount = itemIds.sort((a, b) => {
                const itemA = orderContext.items.find(itr => itr.itemId === a);
                const itemB = orderContext.items.find(itr => itr.itemId === b);
                if (itemA === undefined || itemB === undefined) {
                    return 0;
                }
                if (itemA.amount === itemB.amount) {
                    return 0;
                }
                return itemA.amount > itemB.amount ? -1 : 1;
            });

            // How many combinations that use the requires quantity can we use
            const combinationsToBuild = Math.floor(
                itemIdsSortedByAmount.length / requiredQuantity
            );

            const combinations = [];
            for (let k = 0; k < combinationsToBuild; k++) {
                combinations.push(
                    itemIdsSortedByAmount
                        .slice(
                            k * requiredQuantity,
                            k * requiredQuantity + requiredQuantity
                        )
                        .map(String)
                );
            }

            if (combinations.length === 0) {
                break;
            }

            // Calculate the savings the different combinations gives us
            const combinationsWithSavings =
                calculateGroupSavingsForCombinations(
                    combinations,
                    discount,
                    orderContext
                );

            // Sort the combinations with the largest savings first
            const combinationsWithSavingsSorted = combinationsWithSavings.sort(
                (a, b) => {
                    if (a.savedAmount === b.savedAmount) {
                        return 0;
                    }
                    return a.savedAmount > b.savedAmount ? -1 : 1;
                }
            );

            // Find the best combinations, starting with the one that gives the largest saving.
            // then iterate through the list of combinations excluding the ones that has itemIds already used
            const items = findBestGroupCombinations(
                combinationsWithSavingsSorted
            );

            combinationsBySuggestion.push(items);
        }

        if (combinationsBySuggestion.length === 0) {
            continue;
        }

        // If we have multiple groups in the discount we have to have the same number of suggestions from each group
        // Find the lowest number of combinations in the suggestions
        const numberOfCombinationsToUse = Math.min(
            ...combinationsBySuggestion.map(itr => itr.length)
        );

        const ids = [];
        let savedAmount = 0;
        let total = 0;
        for (let j = 0; j < combinationsBySuggestion.length; j++) {
            const combinations = combinationsBySuggestion[j].slice(
                0,
                numberOfCombinationsToUse
            );
            ids.push(combinations.map(itr => itr.itemIds).flat());
            total += combinations.reduce(
                (acc, cur) => acc + cur.totalAmount,
                0
            );

            switch (discount.valueType) {
                case "PERCENTAGE":
                    // Use the savedAmount found as a percentage discount does not depend on the number of groups in the discount
                    savedAmount += combinations.reduce(
                        (acc, cur) => acc + cur.savedAmount,
                        0
                    );
                    break;
                case "ABSOLUTE":
                    // Sum the total amounts across the groups and set savedAmount as the groups total - the discount
                    savedAmount =
                        total - discount.value * numberOfCombinationsToUse;
                    break;
                case "RELATIVE":
                    // Use the discount value as savedAmount as a relative discount does not depend on the number of groups in the discount
                    savedAmount = discount.value * numberOfCombinationsToUse;
                    break;
            }
        }

        // Make sure that the saved amount is never negative
        savedAmount = Math.min(total, Math.max(0, savedAmount));

        // Update list of used item ids
        itemIdsUsed.push(...ids.flat().map(Number));

        appliedDiscounts.push({
            items: ids.flat().map(Number),
            discount: discount,
            amount: savedAmount,
        });
    }
    return appliedDiscounts;
}

// Combine suggested product discount for best savings by using the "exclude" method.
// This is not a method I have any research on, or have seen being use anywhere else,
// so I'll have to describe it here, as best as I can. It seems to cover most cases as
// of now, where we don't have product-group support. Also it's waaay faster than
// trying to do all combinations and then choose to best one. @PeterBech
//
// The exclude method starts by sorting the suggested discount from lowest to highest
// savings amount. Next it creates a pool of the item required by suggestions. This
// pool is the shared finite set of items the discounts can use from. When the pool is
// empty, the discounts will have to start excluding each other, only if the savings
// becomes greater than the previous. When the pool is ready, we move on to the apply-
// phases where it will run n number of phases, where n is depending on if the exclusion
// in the previous apply phase left any items in the pool, that would still be able to
// apply a suggested discount to. An apply phase is pretty simple. Start with the first
// suggestion in the sorted suggested discounts array, test if it can apply, by checking
// if the required items is available in the pool, if so, apply the discount by inserting
// it sorted into the intermediate applied discounts array. If it could not apply, exclude
// the lowest matching applied discounts until pool becomes big enough for the suggestion
// to apply, when it do, insert it sorted into the intermediate applied discounts array as
// well. If in any phase there was excluded any applied discounts, it'll have to run a phase
// again to cover any left over items from the excluded discounts. As a last step it'll
// combine any applied discounts that uses the same suggested discount.
export function combineSuggestedProductDiscountsForBestSavings(
    suggestedDiscounts: SuggestedDiscount[]
): AppliedDiscount[] {
    // Sort suggestions from low savings amount to high
    const sortedSuggestions = suggestedDiscounts.sort((a, b) => {
        return a.amount === b.amount ? 0 : a.amount > b.amount ? 1 : -1;
    });

    // Create a item pool from the given suggested discounts
    const pool = createItemPool(suggestedDiscounts);

    // Apply phases: Keep trying to apply all suggested discounts, only if it's the first phase,
    // or if in the last phase was excluded any suggested applied discount
    const intermediateAppliedDiscounts: SuggestedAppliedDiscount[] = [];
    let excludedNumberOfDiscounts = -1;
    while (excludedNumberOfDiscounts < 0 || excludedNumberOfDiscounts > 0) {
        excludedNumberOfDiscounts = 0;

        // Try to apply every suggested discount
        let suggestedDiscountItr = 0;
        let numberOfSame = 0;
        while (suggestedDiscountItr < sortedSuggestions.length) {
            const suggestion = sortedSuggestions[suggestedDiscountItr];

            // Test if suggestion hasn't exceeded the max per order limit
            if (
                suggestion.discount.maxPerOrder !== undefined &&
                intermediateAppliedDiscounts.reduce(
                    (count, discount) =>
                        discount.suggestedDiscountKey === suggestedDiscountItr
                            ? count + 1
                            : count,
                    0
                ) >= suggestion.discount.maxPerOrder
            ) {
                // If suggestion has exceeded the limit, we'll simply just move
                // on to the next suggestion
                numberOfSame = 0;
                suggestedDiscountItr++;
                continue;
            }

            // If suggestion can not apply, then we will try to exclude previous applied discounts,
            // that use the items of product needed for the suggest discount, until the pool has the
            // required items. Then we compare the excluded discounts with the suggested discount.
            // If the suggestion has better savings, we use that and remove the excluded discounts
            // from the intermediate applied discounts array.
            if (!suggestionCanApply(pool, suggestion)) {
                const excludedKeys: number[] = [];
                let excludedAmount = 0;

                // Create an object/pool with remaining products required for the suggestion
                const remainingProducts: { [key: string]: number } = {};
                for (const productId in suggestion.products) {
                    remainingProducts[productId] = Math.max(
                        0,
                        suggestion.products[productId].requiredQuantity -
                            pool[productId].length
                    );
                }

                // Start from the beginning of the sorted applied suggestions, meaning that
                // we start with the lowest amount of savings. Then for every applied suggestion
                // we test if it's items matches the required remaining products, if so, we'll mark
                // that applied suggestion as excluded.
                for (
                    let appliedKey = 0;
                    // Make sure we don't try to exclude discounts of same type
                    // as suggestion by subtracting `numberOfSame` from length
                    appliedKey <
                    intermediateAppliedDiscounts.length - numberOfSame;
                    appliedKey++
                ) {
                    const applied = intermediateAppliedDiscounts[appliedKey];
                    let foundAnyProducts = false;

                    // Test if applied suggestion matches the required remaining products
                    for (const productId in suggestion.products) {
                        // Skip check on products that has been fulfilled
                        if (remainingProducts[productId] <= 0) {
                            continue;
                        }

                        const productInfo = applied.products![productId];
                        if (productInfo) {
                            // Subtract number of items of product from the pool
                            // of remaining products, clamped to never become
                            // a negative number.
                            remainingProducts[productId] = Math.max(
                                0,
                                remainingProducts[productId] -
                                    productInfo.itemIds.length
                            );
                            foundAnyProducts = true;
                        }
                    }

                    // If any matching products were found, we mark the applied suggestion
                    // as excluded, and add it's saved amount to the sum of excluded amount
                    if (foundAnyProducts) {
                        excludedAmount +=
                            intermediateAppliedDiscounts[appliedKey].amount;
                        excludedKeys.push(appliedKey);
                    }

                    // If all remaining product was fulfilled, then we can stop checking for
                    // additional applied suggestion to exclude.
                    if (productQuantitiesEmpty(remainingProducts)) {
                        break;
                    }
                }

                // Check if there are any remaining required products, if so, then the suggestion
                // could not be applied, and we'll move on to the next suggestion.
                if (!productQuantitiesEmpty(remainingProducts)) {
                    numberOfSame = 0;
                    suggestedDiscountItr++;
                    continue;
                }

                // If we have come this far, it means we're still handling a suggestion that needs
                // to exclude other applied suggestions to be applied. The next step is to remove
                // the applied suggestions from the applied pool of suggested discounts, ONLY IF
                // the sum of savings of the excluded suggestions is less than the amount of
                // the suggested discount savings amount.
                if (
                    excludedKeys.length > 0 && // Should never happen, but just for good measure
                    excludedAmount < suggestion.amount
                ) {
                    // Removed excluded discounts from the array of applied suggestion.
                    // When removing them, we also have to move the the items back to the
                    // pool of items. Then later, then the current suggestion will pick
                    // them out, and only use the required items. Maybe then there would
                    // be a leftover of items not used by the current suggested discount.
                    // Those leftovers will be handled in the next apply phase.
                    const reversedExcludedKeys = excludedKeys.reverse();
                    for (let j = 0; j < reversedExcludedKeys.length; j++) {
                        const key = reversedExcludedKeys[j];
                        const excludedDiscount =
                            intermediateAppliedDiscounts[key];
                        for (const productId in excludedDiscount.products) {
                            pool[productId] = pool[productId].concat(
                                excludedDiscount.products[productId].itemIds
                            );
                        }
                        intermediateAppliedDiscounts.splice(key, 1);
                        excludedNumberOfDiscounts++;
                    }
                } else {
                    // If the total savings of excluded applied suggestions is greater
                    // than the savings of the suggestion, then move on to the next
                    // suggested discount.
                    numberOfSame = 0;
                    suggestedDiscountItr++;
                    continue;
                }
            }

            // Now the only thing left for the current suggestion is to apply it, by picking
            // and removing the required items from pool. The picking is done by creating an
            // object, or sub-pool of the items mapped by product id.
            let pickedItems: Exclude<
                SuggestedAppliedDiscount["products"],
                undefined
            > = {};
            for (const productId in suggestion.products) {
                // Add product to picked items object
                pickedItems[productId] = {
                    itemIds: [],
                    requiredQuantity:
                        suggestion.products[productId].requiredQuantity,
                };

                let remainingToPick =
                    suggestion.products[productId].requiredQuantity;
                pickedItems[productId].itemIds = pickedItems[
                    productId
                ].itemIds.concat(pool[productId].slice(0, remainingToPick));

                // Remove from order item pool
                pool[productId] = pool[productId].slice(remainingToPick);
            }

            // Added the current suggestion to applied discounts array
            intermediateAppliedDiscounts.push({
                amount: suggestion.amount,
                products: pickedItems,
                desiredItems: suggestion.desiredItems,
                discount: suggestion.discount,
                suggestedDiscountKey: suggestedDiscountItr,
            });

            // The applied discounts needs to be sorted for the exclude process to work as attended
            intermediateAppliedDiscounts.sort((a, b) => a.amount - b.amount);

            // Increment the counter used for checking if the discounts has reached it's limit
            // of being applied a max number of times for an given order.
            numberOfSame++;
        }
    }

    // Combine applied discounts that share the same product conditions
    const appliedDiscounts: AppliedDiscount[] = [];
    let lastKey = -1;
    intermediateAppliedDiscounts.forEach(discount => {
        let items: AppliedDiscount["items"] = [];
        for (const productId in discount.products) {
            items = items.concat(discount.products[productId].itemIds);
        }

        if (lastKey === discount.suggestedDiscountKey) {
            const last = appliedDiscounts[appliedDiscounts.length - 1];
            last.amount += discount.amount;
            last.items = last.items!.concat(items);
        } else {
            appliedDiscounts.push({
                discount: discount.discount,
                items,
                amount: discount.amount,
            });
            lastKey = discount.suggestedDiscountKey;
        }
    });

    return appliedDiscounts;
}

// Creates an object or a pool of items mapped by product
function createItemPool(suggestedDiscounts: SuggestedDiscount[]): ItemPool {
    const pool: ItemPool = {};
    suggestedDiscounts.forEach(suggestedDiscount => {
        for (const productId in suggestedDiscount.products) {
            if (!pool[productId]) {
                pool[productId] = [];
            }
            suggestedDiscount.products[productId].itemIds.forEach(itemId => {
                if (!pool[productId].includes(itemId)) {
                    pool[productId].push(itemId);
                }
            });
        }
    });
    return pool;
}

// Test if a given suggested discount can apply to given item pool
function suggestionCanApply(
    pool: ItemPool,
    suggestion: SuggestedDiscount
): boolean {
    for (const productId in suggestion.products) {
        if (
            suggestion.products[productId].requiredQuantity >
            pool[productId].length
        ) {
            return false;
        }
    }
    return true;
}

// Test if quantities for all products in given product quantity pool is zero
function productQuantitiesEmpty(quantities: {
    [key: string]: number;
}): boolean {
    for (const productId in quantities) {
        if (quantities[productId] > 0) {
            return false;
        }
    }
    return true;
}

// Move items around between given applied discounts to try and
// fulfill the the given desired items for the discounts.
function applyProductDiscountToDesiredItems(
    items: PartialOrderContext["items"],
    discounts: AppliedDiscount[]
): AppliedDiscount[] {
    // For every applied discount with desired items, we iterate over every
    // desired item, check if the item is already in items of the discount,
    // if not, then we'll find the discount that holds the item and swap it
    // with a item that is of the same product. If there is no other discount
    // containing the item, then the discount must be applied to any discount
    // and then we'll simply replace a item of same product for the current
    // discount with the desired item.
    for (const i in discounts) {
        const discount = discounts[i];
        if (!discount.discount.desiredItems) {
            continue;
        }

        for (let j = 0; j < discount.discount.desiredItems.length; j++) {
            // Test if the desired item is already given to the current discount,
            // if so, then we don't need to do anything about it
            const desiredItemId = discount.discount.desiredItems[j];
            if (discount.items!.includes(desiredItemId)) {
                continue;
            }

            // If the desired item could not be found in the order, then
            // we will just move on to the next discount
            const desiredItem = items.find(itr => itr.itemId === desiredItemId);
            if (!desiredItem) {
                continue;
            }

            // Assert that a product id is given for the desired item
            if (desiredItem.productId === undefined) {
                throw new Error(
                    "The current implementation of discounts, does not support items without product id"
                );
            }

            // Find a item of same product in the current discount
            const itemKeyOfSameProductInCurrentDiscount =
                getItemIndexThatIsNotDesiredItems(
                    desiredItem.productId,
                    items,
                    discount.items!,
                    discount.discount.desiredItems!
                );

            // If the current discount doesn't have a item of same product
            // to swap with. So we will have to ignore the desired item.
            if (itemKeyOfSameProductInCurrentDiscount < 0) {
                continue;
            }

            // Find the discount containing the desired item
            const containingDiscountKey = discounts.findIndex(itr =>
                itr.items!.includes(desiredItemId)
            );

            // Swap a item of same product from current discount with the
            // discount containing the desired item.
            if (containingDiscountKey >= 0) {
                // Get the key of the desired item in the discount containing it
                const containingDiscountItemKey = discounts[
                    containingDiscountKey
                ].items!.findIndex(itr => itr === desiredItemId);

                // Store the item id of the item of the same product in a non-reference variable,
                // making sure the item id is not overridden when being used to replacing below.
                const itemIdOfSameProductInCurrentDiscount = Number(
                    discount.items![itemKeyOfSameProductInCurrentDiscount]
                );

                // Replace the item of same product in current discount
                discount.items![itemKeyOfSameProductInCurrentDiscount] =
                    discounts[containingDiscountKey].items![
                        containingDiscountItemKey
                    ];

                // Replace the item in discount containing the desired item with the item of same product
                discounts[containingDiscountKey].items![
                    containingDiscountItemKey
                ] = itemIdOfSameProductInCurrentDiscount;
            } else {
                // If no other discounts hold the desired item, we just replace the item of same product
                // from the current discount with the desired item
                discount.items![itemKeyOfSameProductInCurrentDiscount] =
                    desiredItemId;
            }
        }
    }
    return discounts;
}

function getItemIndexThatIsNotDesiredItems(
    productId: string,
    items: PartialOrderContext["items"],
    discountItems: Exclude<AppliedDiscount["items"], undefined>,
    desiredItems: Exclude<
        AppliedDiscount["discount"]["desiredItems"],
        undefined
    >
): number {
    for (let i = 0; i < discountItems.length; i++) {
        if (!desiredItems.includes(discountItems[i])) {
            // Test if the product id of the item matches
            if (
                items.find(itr => itr.itemId === discountItems[i])
                    ?.productId === productId
            ) {
                return i;
            }
        }
    }
    return -1;
}

function compareSuggestedOrderDiscounts(
    a: SuggestedDiscount,
    b: SuggestedDiscount
) {
    return a.amount === b.amount ? 0 : a.amount < b.amount ? 1 : -1;
}

function convertToEntityConditionContext(
    orderContext: PartialOrderContext,
    discount: DiscountType
) {
    return {
        order: {
            ...orderContext,
            // Filter for items that does not satisfy the conditions
            items: filterOrderItems(orderContext.items, discount.itemQuery),
            // Filter for returnitems that does not satisfy the conditions
            returnedItems: filterOrderItems(
                orderContext.returnedItems,
                discount.itemQuery
            ),
        },
    };
}

export function attemptApplyingOrderDiscount(
    orderContext: PartialOrderContext,
    itemsWithSavedAmount: ItemsWithSavedAmount,
    discount: DiscountType
): SuggestedDiscount | false {
    // Make sure that the conditions for the discount is met
    const check = checkConditions(
        convertToEntityConditionContext(orderContext, discount),
        discount.conditions
    );
    if (check === false) {
        return false;
    }

    const saved = calculateSavedOrderAmount(itemsWithSavedAmount, discount);
    if (saved.amount === 0) {
        return false;
    }

    return {
        ...saved,
        discount,
    };
}

export function attemptApplyingProductDiscount(
    orderContext: PartialOrderContext,
    discount: DiscountType
): SuggestedDiscount | false {
    // Make sure that the conditions for the discount is met
    if (discount.conditions.length === 0) {
        // Product discounts needs at least one condition
        return false;
    }
    const check = checkConditions(
        convertToEntityConditionContext(orderContext, discount),
        discount.conditions
    );
    if (check === false) {
        return false;
    }

    const saved = calculateSavedProductAmount(orderContext, discount, check);
    if (saved.amount === 0) {
        return false;
    }

    return {
        ...saved,
        discount,
    };
}

export function attemptApplyingGroupDiscount(
    orderContext: PartialOrderContext,
    discount: DiscountType
) {
    // Make sure that the conditions for the discount is met
    if (discount.conditions.length === 0) {
        // Product discounts needs at least one condition
        return false;
    }
    const check = checkConditions(
        convertToEntityConditionContext(orderContext, discount),
        discount.conditions
    );

    if (check === false) {
        return false;
    }

    return check;
}

function calculateSavedOrderAmount(
    itemsWithSavedAmount: ItemsWithSavedAmount,
    discount: DiscountType
): { amount: number } {
    const maxSavedAmount = itemsWithSavedAmount.reduce(
        (amount, item) =>
            amount + item.amount - item.minimumAmount - item.savedAmount,
        0
    );
    const orderAmount = itemsWithSavedAmount.reduce(
        (amount, item) => amount + item.amount - item.savedAmount,
        0
    );

    let savedAmount = 0;
    switch (discount.valueType) {
        case "PERCENTAGE":
            savedAmount = Math.round(orderAmount * discount.value);
            break;
        case "ABSOLUTE":
            savedAmount = orderAmount - discount.value;
            break;
        case "RELATIVE":
            savedAmount = discount.value;
            break;
    }

    // Make sure saved amount dont become greater than maxSavedAmount
    // only if the discount is a manual order discount
    if (discount.manual) {
        savedAmount = Math.min(maxSavedAmount, savedAmount);
    }

    // Make sure that the saved amount is never negative and never exceed the order amount
    savedAmount = Math.min(orderAmount, Math.max(0, savedAmount));

    // Round of any decimals
    savedAmount = Math.round(savedAmount);

    return { amount: savedAmount };
}

function calculateSavedProductAmount(
    orderContext: PartialOrderContext,
    discount: DiscountType,
    conditions: SuccessfulConditions
): {
    amount: number;
    desiredItems?: DiscountType["desiredItems"];
    products: Exclude<SuggestedDiscount["products"], undefined>;
} {
    if (!conditions.order) {
        throw new Error(
            "Something went wrong. Tried to apply a product discount without any order conditions returning any product or product group"
        );
    }

    // Now we need to extract the matched products and products groups from the order conditions
    // to know what products or groups to apply the discount to
    const { products } = extractIdsFromOrderConditions(conditions.order);

    // Sum the product and product group quantities
    let total = 0;
    let maxSavedAmount = 0;
    if (discount.type === "RETURN") {
        for (const productId in products) {
            const orderItem = orderContext.returnedItems.find(
                item => item.productId === productId
            );
            if (orderItem) {
                total +=
                    orderItem.amount * products[productId].requiredQuantity;
                maxSavedAmount +=
                    (orderItem.amount - (orderItem.minimumAmount || 0)) *
                    products[productId].requiredQuantity;
            }
        }
    } else {
        for (const productId in products) {
            const orderItem = orderContext.items.find(
                item => item.productId === productId
            );
            if (orderItem) {
                total +=
                    orderItem.amount * products[productId].requiredQuantity;
                maxSavedAmount +=
                    (orderItem.amount - (orderItem.minimumAmount || 0)) *
                    products[productId].requiredQuantity;
            }
        }
    }
    // TODO: Add product groups to total

    // Calculate the saved amount
    let savedAmount = 0;
    switch (discount.valueType) {
        case "PERCENTAGE":
            savedAmount = Math.round(total * discount.value);
            break;
        case "ABSOLUTE":
            savedAmount = total - discount.value;
            break;
        case "RELATIVE":
            savedAmount = discount.value;
            break;
    }

    // Make sure we limit the saved amount to max saved amount
    // only if discount is a manual product discount
    if (discount.manual) {
        savedAmount = Math.min(maxSavedAmount, savedAmount);
    }

    // Make sure that the saved amount is never negative
    savedAmount = Math.min(total, Math.max(0, savedAmount));

    // Round of any decimals
    savedAmount = Math.round(savedAmount);

    return {
        amount: savedAmount,
        desiredItems: discount.desiredItems,
        products,
    };
}

function extractIdsFromOrderConditions(
    orderConditions: Exclude<SuccessfulConditions["order"], undefined>
) {
    const output: {
        products: Exclude<SuggestedDiscount["products"], undefined>;
    } = {
        products: {},
    };

    for (let i = 0; i < orderConditions.length; i++) {
        const productId = orderConditions[i].output.productId;
        if (productId !== undefined) {
            if (!output.products[productId]) {
                output.products[productId] = {
                    itemIds: [],
                    requiredQuantity: 0,
                };
            }
            output.products[productId].itemIds = output.products[
                productId
            ].itemIds.concat(orderConditions[i].output.itemIds);
            output.products[productId].requiredQuantity +=
                orderConditions[i].output.requiredQuantity;
        }
    }

    return output;
}

export function sumDiscountSavings(discounts: AppliedDiscount[]) {
    let total = 0;
    for (const i in discounts) {
        total += discounts[i].amount;
    }
    return total;
}

export function getProductDiscountsWithNumItems(
    discounts: AppliedDiscount[],
    numberOfItems: number
) {
    return discounts.filter(discount => {
        if (discount.discount.type !== "PRODUCT" || !discount.items) {
            return false;
        }

        return discount.items.length === numberOfItems;
    });
}

export function getOrderDiscounts(
    discounts: AppliedDiscount[],
    manual?: "ONLY_MANUAL" | "EXCLUDE_MANUAL"
) {
    return discounts.filter(
        discount =>
            discount.discount.type === "ORDER" &&
            (!manual ||
                (manual === "ONLY_MANUAL" && discount.discount.manual) ||
                (manual === "EXCLUDE_MANUAL" && !discount.discount.manual))
    );
}

export function getItemTotal(context: PartialOrderContext) {
    return context.items.reduce((total, item) => total + item.amount, 0);
}

export function getItemMinimumTotal(context: PartialOrderContext) {
    return context.items.reduce(
        (total, item) => total + (item.minimumAmount || 0),
        0
    );
}

export function calculateGroupSavingsForCombinations(
    combinations: string[][],
    discount: DiscountType,
    orderContext: PartialOrderContext
) {
    const combinationsWithSavings: CombinationWithSavings[] = [];
    for (let i = 0; i < combinations.length; i++) {
        let total = 0;
        const combinationWithSavings: CombinationWithSavings = {
            itemIds: [],
            savedAmount: 0,
            totalAmount: 0,
        };

        for (let k = 0; k < combinations[i].length; k++) {
            const orderItem = orderContext.items.find(
                item => item.itemId.toString() === combinations[i][k]
            );
            if (orderItem) {
                total += orderItem.amount;
            }
            combinationWithSavings.itemIds.push(combinations[i][k]);
        }

        let savedAmount = 0;
        let totalAmount = 0;
        switch (discount.valueType) {
            case "PERCENTAGE":
                savedAmount = Math.round(total * discount.value);
                totalAmount = total;
                break;
            case "ABSOLUTE":
                savedAmount = total - discount.value;
                totalAmount = total;
                break;
            case "RELATIVE":
                savedAmount = discount.value;
                totalAmount = total;
                break;
        }

        combinationWithSavings.savedAmount = Math.round(savedAmount);
        combinationWithSavings.totalAmount = totalAmount;
        combinationsWithSavings.push(combinationWithSavings);
    }
    return combinationsWithSavings;
}

export function findBestGroupCombinations(
    combinationsWithSavingsSorted: CombinationWithSavings[]
) {
    const items: CombinationWithSavings[] = [];

    // Find the best combinations, starting with the one that gives the largest saving.
    // then iterate through the list of combinations excluding the ones that has itemIds already used
    for (let i = 0; i < combinationsWithSavingsSorted.length; i++) {
        let isUnique: boolean = true;
        for (
            let j = 0;
            j < combinationsWithSavingsSorted[i].itemIds.length;
            j++
        ) {
            if (
                items.find(itm =>
                    itm.itemIds.includes(
                        combinationsWithSavingsSorted[i].itemIds[j]
                    )
                ) !== undefined
            ) {
                isUnique = false;
                break;
            }
        }
        if (isUnique === true) {
            items.push(combinationsWithSavingsSorted[i]);
        }
    }
    return items;
}
