import React, { ReactNode } from "react";
import { View } from "react-native";
import { StyleFunction, useThemedStyle } from "../../theme";

export function PaddedLayout({
    darkMode = true,
    children,
}: {
    darkMode?: boolean;
    children: ReactNode;
}) {
    const styles = useThemedStyle(styleFunc);
    return (
        <View
            style={[
                styles.container,
                darkMode ? styles.darkMode : styles.lightMode,
            ]}
        >
            {children}
        </View>
    );
}

const styleFunc: StyleFunction = theme => ({
    container: {
        ...theme.styles.flex1,
        backgroundColor: theme.colors.primary,
        padding: theme.spacingScale,
    },

    darkMode: {
        backgroundColor: theme.colors.primary,
    },

    lightMode: {
        backgroundColor: theme.colors.white,
    },
});
