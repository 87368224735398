import React, { useCallback } from "react";
import type { OrderConditions } from "lib/src/entity-condition";
import { produce } from "immer";
import type { GQDiscountFormQuery } from "graphql-sdk";
import type { ConditionAssignment, Conditions } from "lib";
import { Picker, Spacer } from "@venuepos/react-common";
import { useTranslation } from "locales";
import { StyleSheet } from "react-native";

export function TagConditionInput({
    condition,
    conditions,
    onConditionChange,
    formData,
}: {
    condition: ConditionAssignment;
    conditions: Conditions;
    onConditionChange: (condition: ConditionAssignment) => void;
    formData: GQDiscountFormQuery;
}) {
    const [t] = useTranslation();

    const conditionType = condition.condition as keyof Pick<
        OrderConditions,
        "hasTag" | "hasTagGroup"
    >;
    const args = condition.args as any;
    const selectedValue =
        conditionType === "hasTag" ? args.tagId : args.tagGroupId;

    const unusedTags = formData.tags.data.filter(tagItr => {
        // If the tag is the same as the current condition, then it's allowed
        if (tagItr.id === selectedValue) {
            return true;
        }

        return (
            conditions.find(
                itr =>
                    itr.condition === "hasTag" &&
                    (itr.args as OrderConditions["hasTag"]).tagId === tagItr.id
            ) === undefined
        );
    });

    const unusedTagGroups = formData.tagGroups.data.filter(groupItr => {
        // If the group is the same as the current condition, then it's allowed
        if (groupItr.id === selectedValue) {
            return true;
        }

        // Filter out groups used by other conditions
        return (
            conditions.find(
                itr =>
                    itr.condition === "hasTagGroup" &&
                    (itr.args as OrderConditions["hasTagGroup"]).tagGroupId ===
                        groupItr.id
            ) === undefined
        );
    });

    const onTypeChange = useCallback(
        (
            type: Extract<
                ConditionAssignment["condition"],
                "hasTag" | "hasTagGroup"
            >
        ) => {
            onConditionChange(
                produce(condition, draft => {
                    draft.condition = type;
                    switch (type) {
                        case "hasTag":
                            if (unusedTags[0]) {
                                (draft.args as OrderConditions["hasTag"]) = {
                                    tagId: unusedTags[0].id,
                                };
                            }
                            break;

                        case "hasTagGroup":
                            if (unusedTagGroups[0]) {
                                (draft.args as OrderConditions["hasTagGroup"]) =
                                    {
                                        tagGroupId: unusedTagGroups[0].id,
                                    };
                            }
                            break;
                    }
                })
            );
        },
        [condition, onConditionChange, unusedTagGroups, unusedTags]
    );

    const onIdChange = useCallback(
        (id: string) => {
            onConditionChange(
                produce(condition, draft => {
                    switch (conditionType) {
                        case "hasTag":
                            (draft.args as any).tagId = id;
                            break;
                        case "hasTagGroup":
                            (draft.args as any).tagGroupId = id;
                            break;
                    }
                })
            );
        },
        [condition, onConditionChange, conditionType]
    );

    let input;
    switch (conditionType) {
        case "hasTag":
            input = (
                <Picker
                    selectedValue={selectedValue}
                    onValueChange={onIdChange}
                    style={styles.typePicker}
                >
                    {unusedTags.map(tag => (
                        <Picker.Item
                            key={tag.id}
                            value={tag.id}
                            label={tag.name || tag.identifier}
                        />
                    ))}
                </Picker>
            );
            break;

        case "hasTagGroup":
            input = (
                <Picker
                    selectedValue={selectedValue}
                    onValueChange={onIdChange}
                    style={styles.typePicker}
                >
                    {unusedTagGroups.map(group => (
                        <Picker.Item
                            key={group.id}
                            value={group.id}
                            label={group.name}
                        />
                    ))}
                </Picker>
            );
            break;
    }

    return (
        <>
            <Picker
                selectedValue={conditionType}
                onValueChange={onTypeChange as (type: string) => void}
                style={styles.itemPicker}
            >
                <Picker.Item
                    key="hasTag"
                    value="hasTag"
                    label={t("backoffice.discount_form.tag", "Tag")}
                    disabled={unusedTags.length === 0}
                />
                <Picker.Item
                    key="hasTagGroup"
                    value="hasTagGroup"
                    label={t("backoffice.discount_form.tag_group", "Group")}
                    disabled={unusedTagGroups.length === 0}
                />
            </Picker>
            <Spacer />
            {input}
        </>
    );
}

const styles = StyleSheet.create({
    itemPicker: {
        width: "auto",
        marginBottom: 0,
    },
    typePicker: {
        marginBottom: 0,
        flex: 1,
    },
});
