import React from "react";
import { produce } from "immer";

import type { DateTimeType } from "lib";
import { useTranslation } from "locales";
import { View, ViewStyle } from "react-native";
import type { DateTimeConstraint } from "./date-time-constraint-form";
import {
    DateTimePickerInput,
    InputControl,
    Picker,
    Spacer,
    StyleFunction,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";

export function DateTimeConstraintInput({
    constraint,
    onConstraintChange,
}: {
    constraint: DateTimeConstraint;
    onConstraintChange: (constraint: DateTimeConstraint) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();

    return (
        <View style={[theme.styles.row, theme.styles.flex1]}>
            <InputControl style={[theme.styles.flex1, styles.noSpacing]}>
                <Input
                    constraintType={constraint.type}
                    value={constraint.from}
                    onValueChange={value =>
                        onConstraintChange(
                            produce(constraint, draft => {
                                draft.from = value;
                            })
                        )
                    }
                    testID="from"
                    label={t("common.from", "From")}
                />
            </InputControl>
            <Spacer space={2} />
            <InputControl style={[theme.styles.flex1, styles.noSpacing]}>
                <Input
                    constraintType={constraint.type}
                    value={constraint.to}
                    onValueChange={value =>
                        onConstraintChange(
                            produce(constraint, draft => {
                                draft.to = value;
                            })
                        )
                    }
                    testID="to"
                    label={t("common.to", "To")}
                />
            </InputControl>
        </View>
    );
}

function Input({
    constraintType,
    value,
    label,
    onValueChange,
    disabled,
    style,
    testID,
}: {
    constraintType: DateTimeConstraint["type"];
    value: Partial<DateTimeType>;
    label?: string;
    onValueChange: (value: Partial<DateTimeType>) => void;
    disabled?: boolean;
    style?: ViewStyle;
    testID?: string;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const theme = useTheme();

    switch (constraintType) {
        case "dateTime":
        case "date":
        case "time":
            return (
                <DateTimePickerInput
                    label={label}
                    dateTimeType={constraintType}
                    style={[theme.styles.flex1, style]}
                    value={value as any}
                    onChange={date => {
                        if (date) {
                            onValueChange(date);
                        }
                    }}
                    disabled={disabled}
                    testID={testID}
                />
            );
        case "week":
            return (
                <Picker
                    style={[theme.styles.flex1, styles.pickerSpacing, style]}
                    selectedValue={value.week!}
                    onValueChange={newValue => {
                        onValueChange(
                            produce(value, draft => {
                                draft.week = Number(newValue);
                            })
                        );
                    }}
                    label={label}
                    testID={testID}
                >
                    {(() => {
                        const items = [];
                        for (let i = 1; i <= 53; i++) {
                            items.push(
                                <Picker.Item
                                    key={i}
                                    value={String(i)}
                                    label={String(i)}
                                />
                            );
                        }
                        return items;
                    })()}
                </Picker>
            );
        case "weekDay":
            return (
                <Picker
                    style={[theme.styles.flex1, styles.pickerSpacing, style]}
                    selectedValue={value.weekDay!}
                    onValueChange={newValue => {
                        onValueChange(
                            produce(value, draft => {
                                draft.weekDay = Number(newValue);
                            })
                        );
                    }}
                    label={label}
                    testID={testID}
                >
                    <Picker.Item
                        value="1"
                        label={t("date.week_days.monday", "Monday")}
                    />
                    <Picker.Item
                        value="2"
                        label={t("date.week_days.tuesday", "Tuesday")}
                    />
                    <Picker.Item
                        value="3"
                        label={t("date.week_days.wednesday", "Wednesday")}
                    />
                    <Picker.Item
                        value="4"
                        label={t("date.week_days.thursday", "Thursday")}
                    />
                    <Picker.Item
                        value="5"
                        label={t("date.week_days.friday", "Friday")}
                    />
                    <Picker.Item
                        value="6"
                        label={t("date.week_days.saturday", "Saturday")}
                    />
                    <Picker.Item
                        value="0"
                        label={t("date.week_days.sunday", "Sunday")}
                    />
                </Picker>
            );
        default:
            return null;
    }
}

const styleFunc: StyleFunction = theme => ({
    noSpacing: { marginBottom: 0 },
    pickerSpacing: { marginBottom: theme.spacingScale },
});
