import { AuthActions, Route } from "@venuepos/react-common";

import {
    AccountCreateScreen,
    AccountEditScreen,
    AccountPaymentScreen,
    AccountsListScreen,
    AccountViewScreen,
    BookkeepingAccountEdit,
    BookkeepingAccounts,
    BookkeepingAccountView,
    CustomerCreateScreen,
    CustomerEditScreen,
    CustomerGroupCreateScreen,
    CustomerGroupEditScreen,
    CustomerViewScreen,
} from "./account-payments";
import { CardTransactionsScreen } from "./card-transaction/card-transactions-screen";
import { CardTransactionScreen } from "./card-transaction/view/view";
import {
    CashRegisterClaimScreen,
    CashRegisterCreateScreen,
    CashRegisterEditScreen,
    CashRegisterListScreen,
} from "./cash-register";
import { CurrencyScreen } from "./currency";
import { DashboardScreen } from "./dashboard";
import {
    DepartmentCreateScreen,
    DepartmentEditScreen,
    DepartmentListScreen,
} from "./department";
import {
    DiscountCreateScreen,
    DiscountEditScreen,
    DiscountListScreen,
} from "./discount";
import {
    CreateIntegrationScreen,
    EditIntegrationScreen,
    IntegrationsScreen,
} from "./integration";
import { InvoiceListScreen, InvoiceScreen } from "./invoice";
import {
    LayoutCreateScreen,
    LayoutEditScreen,
    LayoutsScreen,
    TableLayoutCreateScreen,
    TableLayoutEditScreen,
} from "./layout";
import { LoginScreen } from "./login";
import { Create, Edit, Merchants } from "./merchant";
import { MerchantSettingsScreen } from "./merchant/merchant-settings-screen";
import { MiscButtonListScreen } from "./misc-buttons";
import { MiscButtonCreateScreen } from "./misc-buttons/create/create";
import { MiscButtonEditScreen } from "./misc-buttons/edit/edit";
import { PaperDemoScreen } from "./paper-demo/paper-demo";
import {
    PaymentConfigurationCreateScreen,
    PaymentConfigurationEditScreen,
    PaymentConfigurationListScreen,
} from "./payment-configuration";
import {
    PaymentMethodCreateScreen,
    PaymentMethodEditScreen,
    PaymentMethodListScreen,
} from "./payment-method";
import { PrinterEditScreen } from "./printer/edit/edit";
import { Printers } from "./printer/printers";
import {
    ProductCreateScreen,
    ProductEditScreen,
    ProductGroupCreateScreen,
    ProductGroupEditScreen,
    ProductGroupListScreen,
    ProductListScreen,
} from "./product";
import { ReportScreen } from "./report";
import { ShiftListScreen, ShiftScreen } from "./shift";
import {
    Tags,
    TagCreateScreen,
    TagEditScreen,
    TagGroupCreateScreen,
    TagGroupEditScreen,
} from "./tags";
import {
    RoleCreateScreen,
    RoleEditScreen,
    RoleListScreen,
    UserCreateScreen,
    UserEditScreen,
    UserListScreen,
} from "./user";
import { VatCreateScreen } from "./vat/create/create";
import { VatListScreen } from "./vat/vats";
import { CashRegisterStatusListScreen } from "./cash-register/status/cash-register-status-list";
import { InventoryProductNoteTemplateScreen } from "./product/inventory-product-note-template";
import { SumUpAuthorizationScreen } from "./sumup/sumup-authorization-screen";
import { ExportScreen as SaftExportScreen } from "./saft-export";
import { SoftpayMerchantListScreen } from "./softpay";
import {
    SoftpayMiaMerchantListScreen,
    SoftpayMiaMerchantScreen,
    SoftpayMiaStoreScreen,
    SoftpayMiaTerminalScreen,
} from "./softpay-mia";
import { TaskResultListScreen } from "./task/task-result-list";
import { TaskResultScreen } from "./task/task-result";
import { InvoiceCountScreen } from "./graphs-and-stats/invoice-count/invoice-count";
import { GraphAndStatsScreen } from "./graphs-and-stats";
// import { ReceiptLogoScreen } from "./logo/logo";
import { CmpEventListScreen } from "./cmp-event/cmp-event-list-screen";
import { CmpEventProductListScreen } from "./cmp-event/cmp-event-product-list-screen";
import { CmpProductListScreen } from "./cmp-event/cmp-product-list-screen";

export const routeConfig: Record<string, Route> = {
    // i18next t("backoffice.routes.overview", "Overview")
    DASHBOARD: {
        link: "overview",
        translationKey: "backoffice.routes.overview",
        component: DashboardScreen,
        iconName: "overview",
        testID: "menu:overview",
    },
    // i18next t("backoffice.routes.products", "Products")
    PRODUCTS: {
        link: "product",
        translationKey: "backoffice.routes.products",
        component: ProductListScreen,
        iconName: "products",
        isAuthorized: (auth: AuthActions) => auth.may("merchant.product"),
        testID: "menu:products",
    },
    // i18next t("backoffice.routes.product_create", "Create product")
    PRODUCT_CREATE: {
        link: "product/create",
        translationKey: "backoffice.routes.product_create",
        skipMenu: true,
        component: ProductCreateScreen,
    },
    // i18next t("backoffice.routes.product_edit", "Edit product")
    PRODUCT_EDIT: {
        link: "product/edit/:id",
        translationKey: "backoffice.routes.product_edit",
        skipMenu: true,
        component: ProductEditScreen,
    },
    // i18next t("backoffice.routes.product_groups", "Product groups")
    PRODUCT_GROUPS: {
        link: "product/group",
        translationKey: "backoffice.routes.product_groups",
        component: ProductGroupListScreen,
        isAuthorized: (auth: AuthActions) => auth.may("merchant.product"),
        testID: "menu:productGroups",
    },
    // i18next t("backoffice.routes.product_group_create", "Create product group")
    PRODUCT_GROUP_CREATE: {
        link: "product/group/create",
        translationKey: "backoffice.routes.product_group_create",
        component: ProductGroupCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.product_group_edit", "Edit product group")
    PRODUCT_GROUP_EDIT: {
        link: "product/group/edit/:id",
        translationKey: "backoffice.routes.product_group_edit",
        skipMenu: true,
        component: ProductGroupEditScreen,
    },
    // i18next t("backoffice.routes.cash_registers", "Cash registers")
    CASH_REGISTERS: {
        link: "cash-register",
        translationKey: "backoffice.routes.cash_registers",
        component: CashRegisterListScreen,
        iconName: "cashRegister",
        isAuthorized: (auth: AuthActions) =>
            auth.may(
                [
                    "merchant.cash_register.write",
                    "merchant.cash_register.delete",
                ],
                "OR"
            ),
        testID: "menu:cashRegisters",
    },
    // i18next t("backoffice.routes.cash_register_status", "Cash register status")
    CASH_REGISTERS_STATUS: {
        link: "cash-register/status",
        translationKey: "backoffice.routes.cash_register_status",
        component: CashRegisterStatusListScreen,
        iconName: "cashRegister",
        isAuthorized: (auth: AuthActions) => auth.may(["admin.role"]),
    },
    // i18next t("backoffice.routes.cash_register_create", "Create cash register")
    CASH_REGISTER_CREATE: {
        link: "cash-register/create",
        translationKey: "backoffice.routes.cash_register_create",
        skipMenu: true,
        component: CashRegisterCreateScreen,
    },
    // i18next t("backoffice.routes.cash_register_edit", "Edit cash register")
    CASH_REGISTER_EDIT: {
        link: "cash-register/edit/:id",
        translationKey: "backoffice.routes.cash_register_edit",
        skipMenu: true,
        component: CashRegisterEditScreen,
    },
    // i18next t("backoffice.routes.cash_register_claim", "Claim cash register")
    CASH_REGISTER_CLAIM: {
        link: "cash-register/claim",
        translationKey: "backoffice.routes.cash_register_claim",
        skipMenu: true,
        component: CashRegisterClaimScreen,
    },
    // i18next t("backoffice.routes.departments", "Departments")
    DEPARTMENTS: {
        link: "department",
        translationKey: "backoffice.routes.departments",
        component: DepartmentListScreen,
        iconName: "department",
        isAuthorized: (auth: AuthActions) =>
            auth.may(
                ["merchant.department.write", "merchant.department.delete"],
                "OR"
            ),
        testID: "menu:departments",
    },
    // i18next t("backoffice.routes.department_create", "Create department")
    DEPARTMENT_CREATE: {
        link: "department/create",
        translationKey: "backoffice.routes.department_create",
        component: DepartmentCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.department_edit", "Edit department")
    DEPARTMENT_EDIT: {
        link: "department/edit/:id",
        translationKey: "backoffice.routes.department_edit",
        skipMenu: true,
        component: DepartmentEditScreen,
    },
    // i18next t("backoffice.routes.cmp_events", "CMP events")
    CMP_EVENTS: {
        link: "cmp-products/events",
        translationKey: "backoffice.routes.cmp_events",
        component: CmpEventListScreen,
        iconName: "event",
        isAuthorized: (auth: AuthActions) => auth.may("merchant.cmp"),
        testID: "menu:cmpEvents",
    },
    // i18next t("backoffice.routes.merchants", "Merchants")
    MERCHANTS: {
        link: "merchant",
        translationKey: "backoffice.routes.merchants",
        component: Merchants,
        iconName: "merchant",
        isAuthorized: (auth: AuthActions) =>
            auth.may(["admin.merchant.write", "admin.merchant.delete"], "OR"),
        testID: "menu:merchants",
    },
    // i18next t("backoffice.routes.settings", "Settings")
    MERCHANT_SETTINGS: {
        link: "settings",
        translationKey: "backoffice.routes.settings",
        component: MerchantSettingsScreen,
        iconName: "settings",
        isAuthorized: (auth: AuthActions) =>
            auth.may("merchant.merchant_settings"),
        testID: "menu:merchantSettings",
    },
    // i18next t("backoffice.routes.merchant_create", "Create merchant")
    MERCHANT_CREATE: {
        link: "merchant/create",
        translationKey: "backoffice.routes.merchant_create",
        component: Create,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.merchant_edit", "Edit merchant")
    MERCHANT_EDIT: {
        link: "merchant/edit/:id",
        translationKey: "backoffice.routes.merchant_edit",
        skipMenu: true,
        component: Edit,
    },
    // i18next t("backoffice.routes.layouts", "Layouts")
    LAYOUTS: {
        link: "product/layout",
        translationKey: "backoffice.routes.layouts",
        component: LayoutsScreen,
        isAuthorized: (auth: AuthActions) =>
            auth.may(["merchant.layout.delete", "merchant.layout.write"], "OR"),
        testID: "menu:layouts",
    },
    // i18next t("backoffice.routes.layout_create", "Create product layout")
    LAYOUT_CREATE: {
        link: "product/layout/create",
        translationKey: "backoffice.routes.layout_create",
        component: LayoutCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.layout_edit", "Edit product layout")
    LAYOUT_EDIT: {
        link: "product/layout/edit/:id",
        translationKey: "backoffice.routes.layout_edit",
        skipMenu: true,
        component: LayoutEditScreen,
    },
    // i18next t("backoffice.routes.table_layout_create", "Create table layout")
    TABLE_LAYOUT_CREATE: {
        link: "product/layout/table/create",
        translationKey: "backoffice.routes.table_layout_create",
        component: TableLayoutCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.table_layout_edit", "Edit table layout")
    TABLE_LAYOUT_EDIT: {
        link: "product/layout/table/edit/:id",
        translationKey: "backoffice.routes.table_layout_edit",
        skipMenu: true,
        component: TableLayoutEditScreen,
    },
    // i18next t("backoffice.routes.login", "Log in")
    LOGIN: {
        link: "login",
        translationKey: "backoffice.routes.login",
        component: LoginScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.invoices", "Invoices")
    INVOICES: {
        link: "overview/invoice",
        translationKey: "backoffice.routes.invoices",
        component: InvoiceListScreen,
        isAuthorized: (auth: AuthActions) => auth.may("merchant.invoice"),
        testID: "menu:invoices",
    },
    // i18next t("backoffice.routes.invoice", "Receipt")
    INVOICE: {
        link: "overview/invoice/:id",
        translationKey: "backoffice.routes.invoice",
        skipMenu: true,
        component: InvoiceScreen,
    },
    // i18next t("backoffice.routes.shifts", "Shifts")
    SHIFTS: {
        link: "overview/shift",
        translationKey: "backoffice.routes.shifts",
        component: ShiftListScreen,
        iconName: "shift",
        isAuthorized: (auth: AuthActions) => auth.may("merchant.shift"),
        testID: "menu:shifts",
    },
    // i18next t("backoffice.routes.shift", "View shift")
    SHIFT: {
        link: "overview/shift/:id",
        translationKey: "backoffice.routes.shift",
        skipMenu: true,
        component: ShiftScreen,
    },
    // i18next t("backoffice.routes.users", "Users")
    USERS: {
        link: "user",
        translationKey: "backoffice.routes.users",
        component: UserListScreen,
        iconName: "users",
        isAuthorized: (auth: AuthActions) =>
            auth.may(
                [
                    "merchant.user.write",
                    "merchant.user.delete",
                    "admin.user.write",
                    "admin.user.delete",
                ],
                "OR"
            ) && auth.may(["merchant.role", "admin.role"], "OR"),
        testID: "menu:users",
    },
    // i18next t("backoffice.routes.user_create", "Create user")
    USER_CREATE: {
        link: "user/create",
        translationKey: "backoffice.routes.user_create",
        component: UserCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.user_edit", "Edit user")
    USER_EDIT: {
        link: "user/edit/:id",
        translationKey: "backoffice.routes.user_edit",
        skipMenu: true,
        component: UserEditScreen,
    },
    // i18next t("backoffice.routes.roles", "Roles")
    ROLES: {
        link: "user/role",
        translationKey: "backoffice.routes.roles",
        component: RoleListScreen,
        isAuthorized: (auth: AuthActions) =>
            auth.may(["merchant.role", "admin.role"], "OR"),
        testID: "menu:roles",
    },
    // i18next t("backoffice.routes.role_create", "Create role")
    ROLE_CREATE: {
        link: "user/role/create",
        translationKey: "backoffice.routes.role_create",
        skipMenu: true,
        component: RoleCreateScreen,
    },
    // i18next t("backoffice.routes.role_edit", "Edit role")
    ROLE_EDIT: {
        link: "user/role/edit/:id",
        translationKey: "backoffice.routes.role_edit",
        skipMenu: true,
        component: RoleEditScreen,
    },
    // i18next t("backoffice.routes.discounts", "Discounts")
    DISCOUNTS: {
        link: "product/discount",
        translationKey: "backoffice.routes.discounts",
        component: DiscountListScreen,
        isAuthorized: (auth: AuthActions) => auth.may("merchant.discount"),
        testID: "menu:discounts",
    },
    // i18next t("backoffice.routes.discount_create", "Create discount")
    DISCOUNT_CREATE: {
        link: "product/discount/create",
        translationKey: "backoffice.routes.discount_create",
        component: DiscountCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.discount_edit", "Edit discount")
    DISCOUNT_EDIT: {
        link: "product/discount/edit/:id",
        translationKey: "backoffice.routes.discount_edit",
        skipMenu: true,
        component: DiscountEditScreen,
    },
    // i18next t("backoffice.routes.payment_configurations", "Payment configurations")
    PAYMENT_CONFIGURATIONS: {
        link: "settings/payment",
        translationKey: "backoffice.routes.payment_configurations",
        component: PaymentConfigurationListScreen,
        iconName: "payment",
        isAuthorized: (auth: AuthActions) =>
            auth.may(
                [
                    "merchant.payment_configuration.write",
                    "merchant.payment_configuration.delete",
                ],
                "OR"
            ),
        testID: "menu:paymentConfigurations",
    },
    // i18next t("backoffice.routes.payment_configuration_create", "Create payment configuration")
    PAYMENT_CONFIGURATION_CREATE: {
        link: "settings/payment/config/create",
        translationKey: "backoffice.routes.payment_configuration_create",
        component: PaymentConfigurationCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.payment_configuration_edit", "Edit payment configuration")
    PAYMENT_CONFIGURATION_EDIT: {
        link: "settings/payment/config/edit/:id",
        translationKey: "backoffice.routes.payment_configuration_edit",
        skipMenu: true,
        component: PaymentConfigurationEditScreen,
    },
    // i18next t("backoffice.routes.payment_methods", "Payment methods")
    PAYMENT_METHODS: {
        link: "settings/payment/method",
        translationKey: "backoffice.routes.payment_methods",
        component: PaymentMethodListScreen,
        isAuthorized: (auth: AuthActions) =>
            auth.may(
                [
                    "merchant.payment_method.write",
                    "merchant.payment_method.delete",
                ],
                "OR"
            ),
        testID: "menu:paymentMethods",
    },
    // i18next t("backoffice.routes.payment_method_create", "Create payment method")
    PAYMENT_METHOD_CREATE: {
        link: "settings/payment/method/create",
        translationKey: "backoffice.routes.payment_method_create",
        skipMenu: true,
        component: PaymentMethodCreateScreen,
    },
    // i18next t("backoffice.routes.payment_method_edit", "Edit payment method")
    PAYMENT_METHOD_EDIT: {
        link: "settings/payment/method/edit/:id",
        translationKey: "backoffice.routes.payment_method_edit",
        skipMenu: true,
        component: PaymentMethodEditScreen,
    },
    // i18next t("backoffice.routes.reports", "Reports")
    REPORT: {
        link: "overview/report",
        translationKey: "backoffice.routes.reports",
        component: ReportScreen,
        isAuthorized: (auth: AuthActions) => auth.may("merchant.report"),
        testID: "menu:report",
    },
    // i18next t("backoffice.routes.currency", "Currency")
    CURRENCY: {
        link: "settings/currency",
        translationKey: "backoffice.routes.currency",
        component: CurrencyScreen,
        isAuthorized: (auth: AuthActions) => auth.may("merchant.currency"),
        testID: "menu:currency",
    },
    // i18next t("backoffice.routes.customer_create", "Create customer")
    CUSTOMER_CREATE: {
        link: "account-payments/customer/create",
        translationKey: "backoffice.routes.customer_create",
        component: CustomerCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.customer_edit", "Edit customer")
    CUSTOMER_EDIT: {
        link: "account-payments/customer/edit/:id",
        translationKey: "backoffice.routes.customer_edit",
        skipMenu: true,
        component: CustomerEditScreen,
    },
    // i18next t("backoffice.routes.customer", "Customer")
    CUSTOMER_VIEW: {
        link: "account-payments/customer/:id",
        translationKey: "backoffice.routes.customer",
        skipMenu: true,
        component: CustomerViewScreen,
    },
    // i18next t("backoffice.routes.customer_group_create", "Create customer group")
    CUSTOMER_GROUP_CREATE: {
        link: "account-payments/customer/group/create",
        translationKey: "backoffice.routes.customer_group_create",
        component: CustomerGroupCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.customer_group_edit", "Edit customer group")
    CUSTOMER_GROUP_EDIT: {
        link: "account-payments/customer/group/edit/:id",
        translationKey: "backoffice.routes.customer_group_edit",
        skipMenu: true,
        component: CustomerGroupEditScreen,
    },
    // i18next t("backoffice.routes.gift_cards", "Gift cards")
    ACCOUNTS: {
        link: "account-payments/customer/account",
        translationKey: "backoffice.routes.gift_cards",
        component: AccountsListScreen,
        isAuthorized: (auth: AuthActions) => auth.may("merchant.accounts"),
        testID: "menu:accounts",
    },
    // i18next t("backoffice.routes.account_create", "Create account")
    ACCOUNT_CREATE: {
        link: "account-payments/customer/account/create",
        translationKey: "backoffice.routes.account_create",
        component: AccountCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.account_edit", "Edit account")
    ACCOUNT_EDIT: {
        link: "account-payments/customer/account/edit/:id",
        translationKey: "backoffice.routes.account_edit",
        skipMenu: true,
        component: AccountEditScreen,
    },
    // i18next t("backoffice.routes.account", "Account")
    ACCOUNT_VIEW: {
        link: "account-payments/customer/account/:id",
        translationKey: "backoffice.routes.account",
        skipMenu: true,
        component: AccountViewScreen,
    },
    // i18next t("backoffice.routes.customers_accounts", "Customers & Accounts")
    ACCOUNT_PAYMENTS: {
        link: "account-payments",
        translationKey: "backoffice.routes.customers_accounts",
        component: AccountPaymentScreen,
        iconName: "customers",
        isAuthorized: (auth: AuthActions) => auth.may("merchant.customer"),
        testID: "menu:accountPayments",
    },
    // i18next t("backoffice.routes.customers", "Customers")
    CUSTOMERS: {
        link: "account-payments/customer",
        translationKey: "backoffice.routes.customers",
        component: AccountPaymentScreen,
        iconName: "customers",
        isAuthorized: (auth: AuthActions) => auth.may("merchant.customer"),
        testID: "menu:customers",
    },
    // i18next t("backoffice.routes.bookkeeping", "Bookkeeping")
    BOOKKEEPING_ACCOUNTS: {
        link: "account-payments/bookkeeping",
        translationKey: "backoffice.routes.bookkeeping",
        component: BookkeepingAccounts,
        isAuthorized: (auth: AuthActions) => auth.may("merchant.accounts"),
        testID: "menu:bookkeepingAccounts",
    },
    // i18next t("backoffice.routes.bookkeeping_account_view", "View bookkeeping account")
    BOOKKEEPING_ACCOUNT_VIEW: {
        link: "account-payments/bookkeeping/view/:id",
        translationKey: "backoffice.routes.bookkeeping_account_view",
        skipMenu: true,
        component: BookkeepingAccountView,
    },
    // i18next t("backoffice.routes.bookkeeping_account_edit", "Edit bookkeeping account")
    BOOKKEEPING_ACCOUNT_EDIT: {
        link: "account-payments/bookkeeping/edit/:id",
        translationKey: "backoffice.routes.bookkeeping_account_edit",
        skipMenu: true,
        component: BookkeepingAccountEdit,
    },
    // i18next t("backoffice.routes.tags", "Tags")
    TAGS: {
        link: "account-payments/tag",
        translationKey: "backoffice.routes.tags",
        component: Tags,
        iconName: "tags",
        isAuthorized: (auth: AuthActions) => auth.may("merchant.tag"),
        testID: "menu:tags",
    },
    // i18next t("backoffice.routes.tag_create", "Create tag")
    TAG_CREATE: {
        link: "account-payments/tag/create",
        translationKey: "backoffice.routes.tag_create",
        component: TagCreateScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.tag_edit", "Edit tag")
    TAG_EDIT: {
        link: "account-payments/tag/edit/:id",
        translationKey: "backoffice.routes.tag_edit",
        skipMenu: true,
        component: TagEditScreen,
    },
    // i18next t("backoffice.routes.tag_group_create", "Create tag group")
    TAG_GROUP_CREATE: {
        link: "account-payments/tag/group/create",
        translationKey: "backoffice.routes.tag_group_create",
        skipMenu: true,
        component: TagGroupCreateScreen,
    },
    // i18next t("backoffice.routes.tag_group_edit", "Edit tag group")
    TAG_GROUP_EDIT: {
        link: "account-payments/tag/group/edit/:id",
        translationKey: "backoffice.routes.tag_group_edit",
        skipMenu: true,
        component: TagGroupEditScreen,
    },
    PAPER_DEMO: {
        link: "dev/paper-demo",
        translationKey: "backoffice.routes.paper_demo",
        component: PaperDemoScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.printers", "Printers")
    PRINTERS: {
        link: "settings/printer",
        translationKey: "backoffice.routes.printers",
        component: Printers,
        iconName: "printer",
        isAuthorized: (auth: AuthActions) => auth.may("merchant.printer"),
        testID: "menu:printers",
    },
    // i18next t("backoffice.routes.printer_edit", "Edit printer")
    PRINTER_EDIT: {
        link: "settings/printer/edit/:id",
        translationKey: "backoffice.routes.printer_edit",
        skipMenu: true,
        component: PrinterEditScreen,
    },
    // i18next t("backoffice.routes.misc_buttons", "Function keys")
    MISC_BUTTONS: {
        link: "settings/misc-button",
        translationKey: "backoffice.routes.misc_buttons",
        component: MiscButtonListScreen,
        isAuthorized: (auth: AuthActions) =>
            auth.may(
                ["merchant.misc_button.delete", "merchant.misc_button.write"],
                "OR"
            ),
        testID: "menu:miscButtons",
    },
    // i18next t("backoffice.routes.misc_button_create", "Create Function keys")
    MISC_BUTTON_CREATE: {
        link: "settings/misc-button/create",
        translationKey: "backoffice.routes.misc_button_create",
        skipMenu: true,
        component: MiscButtonCreateScreen,
    },
    // i18next t("backoffice.routes.misc_button_edit", "Edit Functions keys")
    MISC_BUTTON_EDIT: {
        link: "settings/misc-button/edit/:id",
        translationKey: "backoffice.routes.misc_button_edit",
        skipMenu: true,
        component: MiscButtonEditScreen,
    },
    // i18next t("backoffice.routes.vats", "VAT")
    VATS: {
        link: "settings/vats",
        translationKey: "backoffice.routes.vats",
        component: VatListScreen,
        iconName: "printer",
        isAuthorized: (auth: AuthActions) => auth.may("merchant.vat"),
        testID: "menu:vats",
    },
    // i18next t("backoffice.routes.vat_create", "Create VAT")
    VAT_CREATE: {
        link: "settings/vat/create",
        translationKey: "backoffice.routes.vat_create",
        skipMenu: true,
        component: VatCreateScreen,
    },
    // i18next t("backoffice.routes.integrations", "Integrations")
    INTEGRATIONS: {
        link: "settings/integrations",
        translationKey: "backoffice.routes.integrations",
        component: IntegrationsScreen,
        isAuthorized: (auth: AuthActions) =>
            auth.may("merchant.merchant_settings"),
        testID: "menu:integrations",
    },
    // i18next t("backoffice.routes.integration_create", "Create integration")
    INTEGRATION_CREATE: {
        link: "settings/integrations/create",
        translationKey: "backoffice.routes.integration_create",
        component: CreateIntegrationScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.integration_edit", "Edit integration")
    INTEGRATION_EDIT: {
        link: "settings/integrations/edit/:id",
        translationKey: "backoffice.routes.integration_edit",
        component: EditIntegrationScreen,
        skipMenu: true,
    },
    // i18next t("backoffice.routes.card_transactions", "Card transactions")
    CARD_TRANSACTIONS: {
        link: "overview/card-transactions",
        translationKey: "backoffice.routes.card_transactions",
        component: CardTransactionsScreen,
        isAuthorized: (auth: AuthActions) => auth.may("merchant.invoice"),
        testID: "menu:cardTransactions",
    },
    // i18next t("backoffice.routes.card_transaction_view", "Card transaction")
    CARD_TRANSACTION_VIEW: {
        link: "overview/card-transactions/view/:id",
        translationKey: "backoffice.routes.card_transaction_view",
        skipMenu: true,
        component: CardTransactionScreen,
    },
    // i18next t("backoffice.routes.inventory_product_note_templates", "Inventory product note templates")
    INVENTORY_PRODUCT_NOTE_TEMPLATES: {
        link: "overview/product/inventory-product-note-templates",
        translationKey: "backoffice.routes.inventory_product_note_templates",
        skipMenu: true,
        component: InventoryProductNoteTemplateScreen,
    },
    // i18next t("backoffice.routes.sumup_authorization", "SumUp")
    SUMUP_AUTHORIZATION: {
        link: "settings/sumup",
        translationKey: "backoffice.routes.sumup_authorization",
        component: SumUpAuthorizationScreen,
        isAuthorized: (auth: AuthActions) =>
            auth.may(
                [
                    "merchant.payment_configuration.write",
                    "merchant.payment_configuration.delete",
                ],
                "OR"
            ),
    },
    // i18next t("backoffice.routes.saft_export", "SAF-T Export")
    SAFT_EXPORT: {
        link: "overview/saft-export",
        translationKey: "backoffice.routes.saft_export",
        skipMenu: false,
        component: SaftExportScreen,
        isAuthorized: (auth: AuthActions) => auth.may("merchant.report"),
        testID: "menu:saft-export",
    },
    // i18next t("backoffice.routes.softpay_merchant_list", "Softpay merchants")
    SOFTPAY_MERCHANT_LIST: {
        link: "softpay",
        translationKey: "backoffice.routes.softpay_merchant_list",
        component: SoftpayMerchantListScreen,
        iconName: "mobile",
        isAuthorized: (auth: AuthActions) => auth.may(["admin.role"]),
    },
    // i18next t("backoffice.routes.softpay_mia_merchant_list", "Softpay MIA merchants")
    SOFTPAY_MIA_MERCHANT_LIST: {
        link: "softpay/mia/merchants",
        translationKey: "backoffice.routes.softpay_mia_merchant_list",
        component: SoftpayMiaMerchantListScreen,
        isAuthorized: (auth: AuthActions) => auth.may(["admin.role"]),
    },
    // i18next t("backoffice.routes.softpay_mia_merchant", "Softpay MIA merchant")
    SOFTPAY_MIA_MERCHANT: {
        link: "softpay/mia/:acquirer/:merchantId",
        translationKey: "backoffice.routes.softpay_mia_merchant",
        component: SoftpayMiaMerchantScreen,
        skipMenu: true,
        isAuthorized: (auth: AuthActions) => auth.may(["admin.role"]),
    },
    // i18next t("backoffice.routes.softpay_mia_store", "Softpay MIA store")
    SOFTPAY_MIA_STORE: {
        link: "softpay/mia/:acquirer/:merchantId/store/:storeId",
        translationKey: "backoffice.routes.softpay_mia_store",
        component: SoftpayMiaStoreScreen,
        skipMenu: true,
        isAuthorized: (auth: AuthActions) => auth.may(["admin.role"]),
    },
    // i18next t("backoffice.routes.softpay_mia_terminal", "Softpay MIA terminal")
    SOFTPAY_MIA_TERMINAL: {
        link: "softpay/mia/:acquirer/:merchantId/store/:storeId/terminal/:terminalId",
        translationKey: "backoffice.routes.softpay_mia_terminal",
        component: SoftpayMiaTerminalScreen,
        skipMenu: true,
        isAuthorized: (auth: AuthActions) => auth.may(["admin.role"]),
    },
    // i18next t("backoffice.routes.task_result_list", "Task results")
    TASK_RESULT_LIST: {
        link: "task-result",
        translationKey: "backoffice.routes.task_result_list",
        component: TaskResultListScreen,
        iconName: "tasks",
        isAuthorized: (auth: AuthActions) => auth.may(["admin.role"]),
    },
    // i18next t("backoffice.routes.task_result_view", "Task result")
    TASK_RESULT_VIEW: {
        link: "task-result/view/:type",
        translationKey: "backoffice.routes.task_result_view",
        component: TaskResultScreen,
        skipMenu: true,
        isAuthorized: (auth: AuthActions) => auth.may(["admin.role"]),
    },
    // i18next t("backoffice.routes.graph_and_stats", "Graph and stats")
    GRAPH_AND_STATS: {
        link: "graph-and-stats",
        translationKey: "backoffice.routes.graph_and_stats",
        component: GraphAndStatsScreen,
        skipMenu: false,
        iconName: "chart-mixed",
        isAuthorized: (auth: AuthActions) => auth.may(["admin.role"]),
    },
    // i18next t("backoffice.routes.invoice_count", "Invoice count")
    INVOICE_COUNT_VIEW: {
        link: "graph-and-stats/invoice-count",
        translationKey: "backoffice.routes.invoice_count",
        component: InvoiceCountScreen,
        skipMenu: false,
        isAuthorized: (auth: AuthActions) => auth.may(["admin.role"]),
    },
    // i18next t("backoffice.routes.receipt_logo", "Receipt logo")
    // RECEIPT_LOGO: {
    //     link: "settings/receipt-image",
    //     translationKey: "backoffice.routes.receipt_logo",
    //     component: ReceiptLogoScreen,
    //     iconName: "receipt",
    // },
    // i18next t("backoffice.routes.cmp_products", "CMP Products")
    CMP_PRODUCTS: {
        link: "cmp-products",
        translationKey: "backoffice.routes.cmp_products",
        component: CmpProductListScreen,
        iconName: "event",
        isAuthorized: (auth: AuthActions) => auth.may("merchant.cmp"),
        testID: "menu:cmpProducts",
    },
    // i18next t("backoffice.routes.cmp_event_products", "CMP event products")
    CMP_EVENT: {
        link: "cmp-products/events/:id",
        translationKey: "backoffice.routes.cmp_event_products",
        component: CmpEventProductListScreen,
        iconName: "event",
        isAuthorized: (auth: AuthActions) => auth.may("merchant.cmp"),
        testID: "menu:cmpEventProducts",
        skipMenu: true,
    },
};
