import {
    Alert,
    Button,
    CheckBox,
    ColorIndicator,
    ColorPickerInput,
    Form,
    IconButton,
    InputControl,
    InputLabel,
    Loading,
    NumberInput,
    Picker,
    RequiredIndicator,
    StyleFunction,
    TextInput,
    useForm,
    useMerchantConfig,
    useThemedStyle,
} from "@venuepos/react-common";
import {
    GQVenueAccessAccessType,
    GQVenueAccessEvent,
    useProductGroupsQuery,
    useVenueAccessTicketParametersQuery,
} from "graphql-sdk";
import { produce } from "immer";
import {
    BaseLayoutButton,
    formatAmount,
    parseAmount,
    VenueAccessTicketButtonType,
} from "lib";
import { AvailableLocale, useTranslation } from "locales";
import { useCallback, useEffect, useState } from "react";
import React, { View } from "react-native";
import { FunctionButtonModalOutput } from "..";
import { useAdminSession } from "../../../../../../session";

export function VenueAccessTicketsSubForm({
    values: mainFormValues,
    onSubmit,
}: {
    values: VenueAccessTicketButtonType;
    onSubmit: (values: FunctionButtonModalOutput) => void;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);
    const merchantConfig = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);

    const subForm = useForm<
        Omit<VenueAccessTicketButtonType, keyof BaseLayoutButton>
    >(
        {
            label: { required: true },
            productGroupId: { required: true },
            venueAccessTypes: { required: true },
            venueAccessEvent: {
                required: true,
                number: true,
                integer: true,
            },
            amount: { required: true, number: true },
        },
        {
            buttonType: "FUNCTION",
            function: "VENUE_ACCESS_TICKETS",

            productGroupId: mainFormValues.productGroupId || "",
            amount: mainFormValues.amount || 0,
            color: mainFormValues.color || "",
            label: mainFormValues.label || "",
            validFor: mainFormValues.validFor || "today",
            venueAccessEvent: mainFormValues.venueAccessEvent || null,
            venueAccessTypes: mainFormValues.venueAccessTypes || [],
        }
    );

    const [{ errors, values }, { setValue, handleSubmit }] = subForm;

    // Fetch events and ticket types from Venue Access
    const {
        data,
        error: ticketError,
        loading: ticketLoading,
    } = useVenueAccessTicketParametersQuery({
        variables: {
            eventId: values.venueAccessEvent,
        },
    });

    const accessEvents = data?.venueAccessTicketParameters.events;
    const accessTypes = data?.venueAccessTicketParameters.accessTypes;

    const [productGroupColor, setProductGroupColor] = useState<string>("");
    const [activateColorPicker, setActivateColorPicker] = useState<boolean>(
        !!values.color || false
    );

    // Fetch product groups
    const productGroupsQuery = useProductGroupsQuery({
        variables: {
            pagination: {
                sort: "name",
                sortDirection: "ASC",
                pageSize: 999999,
            },
        },
        fetchPolicy: "no-cache",
    });
    const productGroups = productGroupsQuery.data?.productGroups.data;

    const handleGroupChange = useCallback(
        (selectedProductGroupId: string) => {
            if (!productGroups?.length) {
                return;
            }

            setValue("productGroupId", selectedProductGroupId);

            const groupData = productGroups.find(
                groupItr => groupItr.id === selectedProductGroupId
            );

            if (!groupData) {
                setProductGroupColor("");
                return;
            }

            setProductGroupColor(groupData.color);
        },
        [productGroups, setValue]
    );

    const handleOnSubmit = useCallback(() => {
        onSubmit({
            buttonType: "FUNCTION",
            formValues: values as VenueAccessTicketButtonType,
            displayValues: {
                color: values.color || productGroupColor,
                label: values.label,
                formattedAmount: formatAmount(
                    values.amount,
                    merchantConfig.currency,
                    {
                        locale: locale as AvailableLocale,
                    }
                ),
            },
        });
    }, [locale, merchantConfig.currency, onSubmit, productGroupColor, values]);

    // When a button is loaded, then wait for the product groups to load, so we can set the group color
    useEffect(() => {
        if (
            !productGroups ||
            productGroups.length === 0 ||
            !values.productGroupId
        ) {
            return;
        }

        const groupData = productGroups.find(
            groupItr => groupItr.id === values.productGroupId
        );

        if (!groupData) {
            return;
        }

        setProductGroupColor(groupData.color);
    }, [productGroups, values.productGroupId]);

    // Select the first ticket type for the selected event, if no ticket type is selected
    useEffect(() => {
        if (
            accessTypes &&
            accessTypes.length > 0 &&
            values?.venueAccessTypes === undefined
        ) {
            if (accessTypes[0]) {
                setValue("venueAccessTypes", [accessTypes[0].id]);
            }
        }
    }, [accessTypes, setValue, values]);

    if (ticketError) {
        return (
            <>
                <Alert type="error">
                    {t(
                        "backoffice.error.from_server",
                        "There was an error: {{errorText}}",
                        {
                            errorText: ticketError.message,
                        }
                    )}
                </Alert>
            </>
        );
    }

    return (
        <>
            <View style={[styles.rowContainer, styles.eventRow]}>
                <View style={styles.rowContent}>
                    {ticketLoading ? (
                        <>
                            <InputLabel>
                                {t(
                                    "layout.function_button_form.venue_access_event"
                                )}{" "}
                                <RequiredIndicator />
                            </InputLabel>
                            <Loading size="small" />
                        </>
                    ) : (
                        <AccessEventControl
                            form={subForm}
                            accessEvents={accessEvents}
                        />
                    )}
                </View>
                <View style={styles.rowContent}>
                    {ticketLoading ? (
                        <>
                            <InputLabel>
                                <>
                                    {t(
                                        "layout.function_button_form.venue_access_type"
                                    )}{" "}
                                    <RequiredIndicator />
                                </>
                            </InputLabel>
                            <Loading size="small" />
                        </>
                    ) : (
                        <AccessTypesControl
                            form={subForm}
                            accessTypes={accessTypes}
                        />
                    )}
                </View>
            </View>

            <View style={styles.rowContainer}>
                <InputControl error={errors.amount} style={styles.rowContent}>
                    <NumberInput
                        min={0}
                        label={t("layout.function_button_form.ticket_price")}
                        onChange={value => {
                            setValue(
                                "amount",
                                value !== null ? parseAmount(value) : 0
                            );
                        }}
                        defaultValue={values?.amount ? values.amount / 100 : 0}
                        unit={merchantConfig.currency}
                    />
                </InputControl>
                <InputControl error={errors.validFor} style={styles.rowContent}>
                    <Picker
                        label={t(
                            "layout.function_button_form.valid_for",
                            "Valid for"
                        )}
                        onValueChange={value => setValue("validFor", value)}
                        selectedValue={values?.validFor ?? undefined}
                    >
                        <Picker.Item
                            value="today"
                            label={t(
                                "layout.function_button_form.valid_for_options.today",
                                "Same day"
                            )}
                            key="today"
                        />
                        <Picker.Item
                            value="2_days"
                            label={t(
                                "layout.function_button_form.valid_for_options.2_days",
                                "2 days"
                            )}
                            key="2_days"
                        />
                        <Picker.Item
                            value="3_days"
                            label={t(
                                "layout.function_button_form.valid_for_options.3_days",
                                "3 days"
                            )}
                            key="3_days"
                        />
                        <Picker.Item
                            value="4_days"
                            label={t(
                                "layout.function_button_form.valid_for_options.4_days",
                                "4 days"
                            )}
                            key="4_days"
                        />
                        <Picker.Item
                            value="5_days"
                            label={t(
                                "layout.function_button_form.valid_for_options.5_days",
                                "5 days"
                            )}
                            key="5_days"
                        />
                        <Picker.Item
                            value="6_days"
                            label={t(
                                "layout.function_button_form.valid_for_options.6_days",
                                "6 days"
                            )}
                            key="6_days"
                        />
                        <Picker.Item
                            value="7_days"
                            label={t(
                                "layout.function_button_form.valid_for_options.7_days",
                                "7 days"
                            )}
                            key="7_days"
                        />
                        <Picker.Item
                            value="3_years"
                            label={t(
                                "layout.function_button_form.valid_for_options.3_years",
                                "3 years"
                            )}
                            key="3_years"
                        />
                    </Picker>
                </InputControl>
            </View>

            <View style={styles.rowContainer}>
                <InputControl error={errors.label} style={styles.rowContent}>
                    <TextInput
                        required
                        label={t("layout.function_button_form.label", "Label")}
                        placeholder={t(
                            "layout.function_button_form.label_placeholder",
                            "Write a short button text"
                        )}
                        value={values.label}
                        onChangeText={value => {
                            setValue("label", value);
                        }}
                        onClear={() => {
                            setValue("label", "");
                        }}
                    />
                </InputControl>

                <InputControl
                    error={errors.productGroupId}
                    style={styles.rowContent}
                >
                    {!productGroups?.length ? (
                        <Loading
                            message={t(
                                "backoffice.layout.checking_product_groups",
                                "Checking for product groups. Are there any?"
                            )}
                            size="small"
                        />
                    ) : (
                        <Picker
                            required
                            label={t(
                                "layout.function_button_form.product_group",
                                "Product group"
                            )}
                            selectedValue={values.productGroupId}
                            onValueChange={handleGroupChange}
                        >
                            <Picker.Item
                                label={t(
                                    "layout.function_button_form.choose_product_group",
                                    "Choose a product group"
                                )}
                                value=""
                                key="__select__"
                            />
                            {productGroups.map(productGroup => (
                                <Picker.Item
                                    key={productGroup.id}
                                    value={productGroup.id}
                                    label={productGroup.name}
                                />
                            ))}
                        </Picker>
                    )}
                </InputControl>
            </View>

            <View style={[styles.rowContainer, styles.onTop]}>
                <InputControl
                    description={t(
                        "layout.function_button_form.override_product_group_color",
                        "The button gets the colour of the product group, unless you select a different color here."
                    )}
                    style={styles.rowContent}
                >
                    <View style={styles.colorRow}>
                        <InputLabel>
                            {`${t(
                                "layout.button.product_group_color",
                                "Product group color"
                            )}:`}
                        </InputLabel>
                        <View style={styles.groupColor}>
                            <ColorIndicator
                                color={productGroupColor}
                                copyOnClick={true}
                                animate={false}
                            />
                        </View>
                    </View>
                    <CheckBox
                        value={activateColorPicker}
                        onValueChange={checkboxValue => {
                            if (!checkboxValue) {
                                setValue("color", "");
                            }
                            setActivateColorPicker(checkboxValue);
                        }}
                        label={t(
                            "layout.button.pick_color_question",
                            "Pick a custom color?"
                        )}
                    />
                </InputControl>
                {activateColorPicker ? (
                    <InputControl
                        error={errors.color}
                        style={styles.rowContent}
                    >
                        <ColorPickerInput
                            defaultColor={values.color}
                            label={t(
                                "layout.button.custom_color",
                                "Custom button color"
                            )}
                            onChangeColor={newColor => {
                                setValue("color", newColor);
                            }}
                        />
                    </InputControl>
                ) : (
                    <View style={styles.rowContent} />
                )}
            </View>
            <Button type="primary" onPress={handleSubmit(handleOnSubmit)}>
                {t("layout.function_button_form.types.apply", "Apply")}
            </Button>
        </>
    );
}

function AccessEventControl({
    form: [{ values, errors }, { setValues }],
    accessEvents,
}: {
    form: Form<Omit<VenueAccessTicketButtonType, keyof BaseLayoutButton>>;
    accessEvents: Pick<GQVenueAccessEvent, "id" | "name" | "isActive">[];
}) {
    const [t] = useTranslation();

    if (!values || !accessEvents) {
        return null;
    }

    const eventCopy = [...accessEvents].sort((itemA, itemB) =>
        itemA.name.localeCompare(itemB.name)
    );

    return (
        <InputControl error={errors.venueAccessEvent}>
            <Picker
                required
                label={t("layout.function_button_form.venue_access_event")}
                selectedValue={String(values.venueAccessEvent)}
                onValueChange={newValue => {
                    setValues({
                        venueAccessEvent: Number(newValue),
                        venueAccessTypes: undefined,
                    });
                }}
                disabled={eventCopy.length === 0}
            >
                {eventCopy.length > 0 ? (
                    <Picker.Item
                        key="__unselected__"
                        value=""
                        label={t(
                            "layout.function_button_form.select_event",
                            "Select an event"
                        )}
                    />
                ) : (
                    <Picker.Item
                        key="__none__"
                        value=""
                        label={t(
                            "layout.function_button_form.no_events",
                            "No events"
                        )}
                    />
                )}

                {eventCopy.length > 0
                    ? eventCopy.map(accessEvent => (
                          <Picker.Item
                              key={accessEvent.id}
                              label={accessEvent.name}
                              value={String(accessEvent.id)}
                              disabled={!accessEvent.isActive}
                          />
                      ))
                    : null}
            </Picker>
        </InputControl>
    );
}

function AccessTypesControl({
    form: [{ values, errors }, { setValue }],
    accessTypes,
}: {
    form: Form<Omit<VenueAccessTicketButtonType, keyof BaseLayoutButton>>;
    accessTypes: Pick<GQVenueAccessAccessType, "id" | "name">[];
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    if (!values || !accessTypes) {
        return null;
    }

    return (
        <InputControl error={errors.venueAccessTypes}>
            <InputLabel>
                <>
                    {t("layout.function_button_form.venue_access_type")}{" "}
                    <RequiredIndicator />
                </>
            </InputLabel>
            {values.venueAccessTypes?.map((selectedAccessType, index) => (
                <View
                    key={`${index}-${selectedAccessType}`}
                    style={styles.venueAccessTypeItem}
                >
                    <Picker
                        selectedValue={selectedAccessType}
                        onValueChange={value => {
                            if (!values.venueAccessTypes) {
                                return;
                            }

                            setValue(
                                "venueAccessTypes",
                                produce(values.venueAccessTypes, draft => {
                                    draft[index] = Number(value);
                                })
                            );
                        }}
                        disabled={!accessTypes || accessTypes?.length === 0}
                        style={styles.venueAccessTypePicker}
                    >
                        {accessTypes && accessTypes.length > 0 ? (
                            accessTypes.map(accessType => (
                                <Picker.Item
                                    key={accessType.id}
                                    label={accessType.name}
                                    value={String(accessType.id)}
                                />
                            ))
                        ) : (
                            <Picker.Item key="" value="" label="" />
                        )}
                    </Picker>
                    <IconButton
                        name="close"
                        disabled={values.venueAccessTypes!.length <= 1}
                        onPress={() => {
                            if (!values.venueAccessTypes) {
                                return;
                            }

                            setValue(
                                "venueAccessTypes",
                                produce(values.venueAccessTypes, draft => {
                                    draft.splice(index, 1);
                                })
                            );
                        }}
                        style={[
                            styles.removeIcon,
                            values.venueAccessTypes!.length <= 1 &&
                                styles.removeIconDisabled,
                        ]}
                    />
                </View>
            ))}

            {accessTypes && accessTypes.length > 0 ? (
                <>
                    {/* <Picker
                        selectedValue=""
                        onValueChange={value => {
                            if (!values.venueAccessTypes) {
                                return;
                            }

                            setValue(
                                "venueAccessTypes",
                                produce(values.venueAccessTypes, draft => {
                                    draft[index] = Number(value);
                                })
                            );
                        }}
                        disabled={!accessTypes || accessTypes?.length === 0}
                        style={styles.venueAccessTypePicker}
                    >
                        {accessTypes && accessTypes.length > 0 ? (
                            accessTypes.map(accessType => (
                                <Picker.Item
                                    key={accessType.id}
                                    label={accessType.name}
                                    value={String(accessType.id)}
                                />
                            ))
                        ) : (
                            <Picker.Item key="" value="" label="" />
                        )}
                    </Picker> */}

                    <View style={styles.addVenueAccessTypeButtonContainer}>
                        <Button
                            onPress={() => {
                                setValue(
                                    "venueAccessTypes",
                                    (values?.venueAccessTypes || []).concat([
                                        accessTypes![0].id,
                                    ])
                                );
                            }}
                            variant="text"
                            size="small"
                            iconName="plus"
                        >
                            {t("common.add", "Add")}
                        </Button>
                    </View>
                </>
            ) : (
                <Picker disabled={true}>
                    <Picker.Item label="Select an event first." value="" />
                </Picker>
            )}
        </InputControl>
    );
}

const styleFunc: StyleFunction = theme => ({
    venueAccessTypeItem: {
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: theme.spacingScale,
    },
    venueAccessTypePicker: {
        marginBottom: 0,
    },
    removeIcon: {
        color: theme.colors.textDark,
    },
    addVenueAccessTypeButtonContainer: {
        alignItems: "flex-start",
    },
    rowContent: {
        ...theme.styles.flex1,
    },
    rowContainer: {
        ...theme.styles.row,
        columnGap: theme.spacingScale * 2,
    },
    onTop: { zIndex: 2 },
    colorRow: {
        ...theme.styles.row,

        alignItems: "baseline",
        marginBottom: 8,
    },
    groupColor: {
        marginLeft: theme.spacingScale / 2,
    },

    eventRow: { minHeight: 125 }, // Note: This row shows spinners when the GQL queries are running. The spinners have a different height than the pickers, so to avoid too much jumbling around, the row has a minimum height.
});
