import React, { ReactNode, useCallback } from "react";
import { View, Animated, Platform } from "react-native";
import { Spacer, StyleFunction, useThemedStyle } from "../..";
import { useToast } from "./context";
import { ToastItem } from "./item";
export { ProvideToast, useToast } from "./context";
export type { IToastContext } from "./context";

/**
 * app.tsx
 *
 * <ToastProvider>
 * ...
 * </ToastProvider>
 *
 * awesomeScreen.tsx
 *
 * Toast.info("Hejsa");
 * Toast.render(() => <View><Text>Custom Toast</Text></View>)
 */

export interface IToast {
    id: string; // uuid (automatically added)
    title?: string;
    message: string;
    type: "success" | "warning" | "error" | "default" | "custom";
    expires: number; // duration of the toast in seconds
    showClose: boolean;
    animated: Animated.Value;
    iconName: string;
    iconColor?: string;
    render: () => ReactNode;
}

export function ToastContainer() {
    const styles = useThemedStyle(styleFunc);
    const { stack, onRemove } = useToast();

    const handleRemoveItem = useCallback(
        (itemId: IToast["id"]) => {
            onRemove(itemId);
        },
        [onRemove]
    );

    return (
        <View
            // container should not generate touch events
            pointerEvents="box-none"
            style={[
                styles.view,
                Platform.OS === "web" ? styles.webContainer : undefined,
            ]}
        >
            {stack.map(item => (
                <View pointerEvents="auto" key={item.id}>
                    <Spacer />
                    <ToastItem
                        item={item}
                        onRemove={() => {
                            handleRemoveItem(item.id);
                        }}
                    />
                </View>
            ))}
        </View>
    );
}

const styleFunc: StyleFunction = (theme, dimensions) => ({
    view: {
        position: "absolute",
        left: theme.spacingScale * 5,
        bottom:
            dimensions.width <= theme.breakpoint.portraitWidth
                ? theme.spacingScale * 4
                : theme.spacingScale * 2,
        zIndex: 5,
        overflow: "hidden",
    },
    webContainer: {
        // @ts-expect-error The "fixed" value is not allowed in React Native, so this style declaration is only used with Web, and the browser understands this property.
        position: "fixed",
    },
});
