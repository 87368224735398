import {
    Alert,
    ColorIndicator,
    DataTable,
    Icon,
    Loading,
    PageTitle,
    StyleFunction,
    Surface,
    Text,
    useAuth,
    useMerchantConfig,
    useModal,
    usePagination,
    useTheme,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQCmpEventProductsQuery, useCmpEventProductsQuery } from "graphql-sdk";
import { formatAmount, formatDateTime } from "lib";
import { AvailableLocale, useTranslation } from "locales";
import React, { useCallback } from "react";
import { RootStackScreenProps } from "../../navigation";
import { useAdminSession } from "../../session";
import { AdminContainer } from "../container";
import { CmpEventProductCalendar } from "./cmp-event-product-calendar";

type ScreenProps = RootStackScreenProps<"CMP_EVENT">;

export function CmpEventProductListScreen({ route }: ScreenProps) {
    const auth = useAuth();
    auth.enforce(["merchant.cmp"]);

    const id = decodeURI(route.params.id);

    const styles = useThemedStyle(styleFunc);
    const [{ locale }] = useAdminSession(["locale"]);
    const merchantConfig = useMerchantConfig();
    const theme = useTheme();
    const [t] = useTranslation();
    const { render } = useModal();
    const {
        page,
        pageSize,
        sortBy,
        sortDirection,
        onSortChange,
        onPageChange,
        onPageSizeChange,
    } = usePagination({
        initialSortBy: "name",
        initialSortDirection: "DESC",
    });

    const { data, loading, error } = useCmpEventProductsQuery({
        variables: {
            externalEventId: id,
            pagination: {
                page,
                pageSize,
                sort: sortBy,
                sortDirection: sortDirection,
            },
        },
        fetchPolicy: "no-cache",
    });

    const handleCalendarPress = useCallback(
        async item => {
            await render(onClose => (
                <CmpEventProductCalendar
                    onClose={onClose}
                    output={item.calendar}
                />
            ));
        },
        [render]
    );

    // Translations for print:
    // t("backoffice.cmp_event_product.print_always", "Always");
    // t("backoffice.cmp_event_product.print_never", "Never");
    // t("backoffice.cmp_event_product.print_next_day", "Next day");

    const renderCmpEventProductRow = useCallback(
        (
            item: GQCmpEventProductsQuery["cmpEventProducts"]["data"][0],
            rowIndex
        ) => (
            <DataTable.Row
                style={rowIndex % 2 ? theme.styles.rowIndex : undefined}
                key={`cmpEventId_${item.id}`}
            >
                <DataTable.Cell style={styles.iconColumn}>
                    <ColorIndicator
                        status={item.active ? "success" : "warning"}
                    />
                </DataTable.Cell>
                <DataTable.Cell>{item.externalId}</DataTable.Cell>
                <DataTable.Cell>{item.name}</DataTable.Cell>
                <DataTable.Cell numeric>
                    {item.amount === null
                        ? "-"
                        : formatAmount(item.amount, merchantConfig.currency, {
                              locale: locale as AvailableLocale,
                          })}
                </DataTable.Cell>

                <DataTable.Cell style={styles.vatColumn}>
                    <Text>{item.group.vat.percentage} %</Text>
                </DataTable.Cell>
                <DataTable.Cell>
                    {t(
                        "backoffice.cmp_event_product.print_" +
                            item.shouldPrint.toLowerCase(),
                        item.shouldPrint
                    )}
                </DataTable.Cell>
                <DataTable.Cell onPress={() => handleCalendarPress(item)}>
                    {item.calendar && (
                        <>
                            <Text numberOfLines={1}>{item.calendar}</Text>
                            <Icon name="eye" style={styles.icon} />
                        </>
                    )}
                </DataTable.Cell>
                <DataTable.Cell style={styles.centerColumn}>
                    {item.upgradeable
                        ? t("common.yes", "Yes")
                        : t("common.no", "No")}
                </DataTable.Cell>
                <DataTable.Cell style={styles.sequenceColumn} numeric>
                    {item.sequence}
                </DataTable.Cell>
                <DataTable.Cell>
                    {formatDateTime(item.createdAt, locale as AvailableLocale)}
                </DataTable.Cell>
                <DataTable.Cell>
                    {formatDateTime(item.updatedAt, locale as AvailableLocale)}
                </DataTable.Cell>
            </DataTable.Row>
        ),
        [
            handleCalendarPress,
            locale,
            merchantConfig.currency,
            styles.centerColumn,
            styles.icon,
            styles.iconColumn,
            styles.sequenceColumn,
            styles.vatColumn,
            t,
            theme.styles.rowIndex,
        ]
    );

    if (error) {
        return (
            <Alert type="error">
                {t(
                    "backoffice.error.from_server",
                    "There was an error: {{errorText}}",
                    {
                        errorText: error?.message,
                    }
                )}
            </Alert>
        );
    }

    return (
        <AdminContainer>
            <PageTitle>
                {t("backoffice.cmp_event_product.cmp_event", "CMP event")}
            </PageTitle>
            <Surface>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "active" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("active")}
                            style={styles.iconColumn}
                        >
                            {t("backoffice.common.active", "Active")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "externalId"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("externalId")}
                        >
                            {t("backoffice.common.external_id", "External id")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "name" ? sortDirection : undefined
                            }
                            onPress={() => onSortChange("name")}
                        >
                            {t("backoffice.cmp_event_product.name", "Name")}
                        </DataTable.Title>
                        <DataTable.Title numeric>
                            {t("backoffice.cmp_event_product.amount", "Amount")}
                        </DataTable.Title>
                        <DataTable.Title style={styles.vatColumn}>
                            {t("backoffice.cmp_event_product.vat", "Vat")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t("backoffice.cmp_event_product.print", "Print")}
                        </DataTable.Title>
                        <DataTable.Title>
                            {t(
                                "backoffice.cmp_event_product.calendar",
                                "Calendar"
                            )}
                        </DataTable.Title>
                        <DataTable.Title style={styles.centerColumn}>
                            {t(
                                "backoffice.cmp_event_product.upgradeable",
                                "Upgradeable"
                            )}
                        </DataTable.Title>
                        <DataTable.Title style={styles.sequenceColumn} numeric>
                            {t("backoffice.common.sequence", "Sequence")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "createdAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("createdAt")}
                        >
                            {t("common.created_at", "Created at")}
                        </DataTable.Title>
                        <DataTable.Title
                            sortDirection={
                                sortBy === "updatedAt"
                                    ? sortDirection
                                    : undefined
                            }
                            onPress={() => onSortChange("updatedAt")}
                        >
                            {t("common.updated_at", "Updated at")}
                        </DataTable.Title>
                    </DataTable.Header>
                    {loading ? (
                        <Loading />
                    ) : data.cmpEventProducts.data.length === 0 ? (
                        <DataTable.Row style={styles.darkRow}>
                            <DataTable.Cell>
                                {t(
                                    "backoffice.cmp_event_product.no_data",
                                    "No products for event."
                                )}
                            </DataTable.Cell>
                        </DataTable.Row>
                    ) : (
                        data?.cmpEventProducts.data.map(
                            renderCmpEventProductRow
                        )
                    )}
                    <DataTable.Pagination
                        onPageChange={onPageChange}
                        pageSize={pageSize}
                        onSizeChange={onPageSizeChange}
                        page={page}
                        numberOfPages={data?.cmpEventProducts.pagination.pages}
                        itemCount={
                            data?.cmpEventProducts.pagination.resultCount
                        }
                    />
                </DataTable>
            </Surface>
        </AdminContainer>
    );
}

const styleFunc: StyleFunction = theme => ({
    darkRow: {
        backgroundColor: theme.colors.grey50,
    },
    icon: {
        color: theme.colors.secondary,
        paddingHorizontal: theme.spacingScale / 2,
    },
    iconColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 60 },
    sequenceColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 80 },
    vatColumn: { flexGrow: 0, flexShrink: 0, flexBasis: 80 },
    centerColumn: {
        justifyContent: "center",
    },
});
