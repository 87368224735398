import type { OrderItemGroup } from "./types";
import { assign } from "xstate";
import type { AssignMeta } from "xstate/lib/types";
import type { OrderContext } from "./machine";
import type {
    ItemReturnedEvent,
    ItemReturnedRemovedEvent,
    ItemsAddedEvent,
    ItemsRemovedEvent,
    NameSetEvent,
    OrderCustomerAddedEvent,
    OrderExternalProductCheckedOutEvent,
    OrderInvoiceStartedEvent,
    OrderOpenedEvent,
    OrderTagAddedEvent,
    PaymentAddedEvent,
    SetDiscountsEvent,
    SetItemsNoteEvent,
    TableAssignedEvent,
    OrderInvoiceCompletedEvent,
    OrderExternalDataAddedEvent,
} from "./machine-events";
import { calculateDiscounts, getOrderDiscounts } from "../discount";
import { dateToDateTime } from "../date";
import {
    Fraction,
    addFractions,
    minFractions,
    subtractFractions,
} from "../utility";
import { createInvoiceLines, createOpenInvoiceFromLines } from "./invoice";

function calculateOrderItemAmount(
    context: OrderContext,
    itemGroupType: OrderItemGroup["type"]
) {
    const currentLines = context.lines.reduce(
        (sum, line) =>
            sum + (line.itemGroupType === itemGroupType ? line.amount : 0),
        0
    );
    const previousInvoiceLines = context.invoices
        .filter(itr => itr.status === "CLOSED")
        .reduce(
            (sum, invoice) =>
                sum +
                invoice.lines.reduce(
                    (invoiceSum, line) =>
                        invoiceSum +
                        (line.itemGroupType === itemGroupType
                            ? line.amount
                            : 0),
                    0
                ),
            0
        );
    return (
        (currentLines + previousInvoiceLines) *
        (itemGroupType === "ITEM" ? 1 : -1)
    );
}

function calculateOrderPaymentAmount({ invoices }: OrderContext) {
    return invoices.reduce(
        (sum, invoice) =>
            sum +
            invoice.payments.reduce(
                (invoiceSum, payment) => invoiceSum + payment.amount,
                0
            ),
        0
    );
}

function calculateOrderDiscountAmount(context: OrderContext) {
    const currentLines = context.lines.reduce(
        (sum, line) => sum + line.discountAmount,
        0
    );
    const previousInvoiceLines = context.invoices
        .filter(itr => itr.status === "CLOSED")
        .reduce(
            (sum, invoice) =>
                sum +
                invoice.lines.reduce(
                    (invoiceSum, line) => invoiceSum + line.discountAmount,
                    0
                ),
            0
        );
    return currentLines + previousInvoiceLines;
}

/**
 * Action for updating totals on the context.
 * Updates itemAmount and remainder
 */
export const updateTotalsAction = assign<OrderContext, any>({
    itemAmount: (context: OrderContext) => {
        return calculateOrderItemAmount(context, "ITEM");
    },
    returnedItemAmount: (context: OrderContext) => {
        return calculateOrderItemAmount(context, "RETURN");
    },
    remainder: (context: OrderContext) => {
        return (
            calculateOrderItemAmount(context, "ITEM") -
            calculateOrderDiscountAmount(context) -
            calculateOrderPaymentAmount(context) -
            calculateOrderItemAmount(context, "RETURN")
        );
    },
    discountAmount: (context: OrderContext) => {
        return calculateOrderDiscountAmount(context);
    },
    totalAmount: (context: OrderContext) => {
        return (
            calculateOrderItemAmount(context, "ITEM") -
            calculateOrderDiscountAmount(context) -
            calculateOrderItemAmount(context, "RETURN")
        );
    },
    paymentAmount: (context: OrderContext) => {
        return calculateOrderPaymentAmount(context);
    },
});

/**
 * Action for adding a payment to the open invoice. This will always be the latest added invoice.
 */
export const paymentAddedAction = assign<OrderContext, PaymentAddedEvent>({
    invoices: ({ invoices }, event) => {
        const invoice = invoices[invoices.length - 1];

        invoice.payments.push(event.data);
        invoice.paymentAmount += event.data.amount;

        invoice.remainder =
            invoice.itemAmount - invoice.discountAmount - invoice.paymentAmount;

        return invoices;
    },
});

/**
 * Action for adding an items
 */
export const addItemsAction = assign<OrderContext, ItemsAddedEvent>({
    items: (context, event: ItemsAddedEvent) => {
        let idCounter = context.itemIdCounter;
        const items = context.items;
        for (let i = 0; i < event.data.quantity; i++) {
            items.push({
                amount: event.data.amount,
                paid: {
                    fraction: { numerator: 0, denominator: 0 },
                    amount: 0,
                    vatAmount: 0,
                    costAmount: 0,
                },
                minimumAmount: event.data.minimumAmount,
                costAmount: event.data.costAmount,
                productId: event.data.productId,
                productGroupId: event.data.productGroupId,
                productGroupName: event.data.productGroupName,
                vat: event.data.vat,
                name: event.data.name,
                note: event.data.note,
                itemId: idCounter,
                addedAt: event.data.addedAt,
                excludeFromDiscounts: event.data.excludeFromDiscounts,
                discountKey: -1,
                external: event.data.external
                    ? { ...event.data.external, checkedOut: false }
                    : undefined,
            });

            idCounter++;
        }

        return items;
    },
    itemIdCounter: (context, event: ItemsAddedEvent) => {
        return context.itemIdCounter + event.data.quantity;
    },
});

/**
 * Action for updating itemGroups on the context
 */
export const updateItemGroupsAction = assign<OrderContext, any>({
    itemGroups: context => {
        let itemGroups: OrderContext["itemGroups"] = [];

        // Create item groups for `items` excluding `returnedItems`
        context.items.forEach(item => {
            // Start by finding matching item group
            // Requirement for matching item group
            // - Item group has to be of type `ITEM`
            // - Either the product of the item group has to match with the product of the
            //   item, or else the name and item-amount has to match
            // - Note has to match
            // - Discount has to match
            const itemGroupKey = itemGroups.findIndex(
                itemGroup =>
                    itemGroup.type === "ITEM" &&
                    ((item.productId &&
                        itemGroup.productId === item.productId) ||
                        (!item.productId &&
                            itemGroup.name === item.name &&
                            itemGroup.amount / itemGroup.items.length ===
                                item.amount)) &&
                    itemGroup.note === item.note &&
                    itemGroup.discountKey === item.discountKey
            );

            if (itemGroupKey >= 0) {
                // Add to existing item group
                itemGroups[itemGroupKey].items.push(item.itemId);
                itemGroups[itemGroupKey].quantity++;
                itemGroups[itemGroupKey].amount += item.amount;
                itemGroups[itemGroupKey].costAmount += item.costAmount;
            } else {
                // No group was found, let's create one
                // First we need to find out if the item is used in any applied discount
                const discountKey = context.appliedDiscounts.findIndex(
                    itr => itr.items && itr.items.includes(item.itemId)
                );

                itemGroups.push({
                    type: "ITEM",
                    discount: context.appliedDiscounts[discountKey],
                    discountKey,
                    items: [item.itemId],
                    productId: item.productId,
                    productGroupId: item.productGroupId,
                    productGroupName: item.productGroupName,
                    name: item.name,
                    minimumAmount: item.minimumAmount,
                    excludeFromDiscounts: item.excludeFromDiscounts,
                    amount: item.amount,
                    costAmount: item.costAmount,
                    vatAmount: 0,
                    note: item.note,
                    quantity: 1,
                });
            }
        }, []);

        // Create item groups for `returnedItems`
        context.returnedItems.forEach(returnedItem => {
            // Start by finding matching item group
            const itemGroupKey = itemGroups.findIndex(
                itemGroup =>
                    itemGroup.type === "RETURN" &&
                    ((returnedItem.productId &&
                        itemGroup.productId === returnedItem.productId) ||
                        (!returnedItem.productId &&
                            itemGroup.name === returnedItem.name &&
                            itemGroup.amount / itemGroup.items.length ===
                                returnedItem.amount)) &&
                    itemGroup.note === returnedItem.note &&
                    itemGroup.discountKey === returnedItem.discountKey
            );

            if (itemGroupKey >= 0) {
                // Add to existing item group
                itemGroups[itemGroupKey].items.push(returnedItem.itemId);
                itemGroups[itemGroupKey].quantity++;
                itemGroups[itemGroupKey].amount += returnedItem.amount;
                itemGroups[itemGroupKey].costAmount += returnedItem.costAmount;
            } else {
                // No group was found, let's create one
                // First we need to find out if the item is used in any applied discount
                const discountKey = context.appliedDiscounts.findIndex(
                    itr => itr.items && itr.items.includes(returnedItem.itemId)
                );

                itemGroups.push({
                    type: "RETURN",
                    discount: context.appliedDiscounts[discountKey],
                    discountKey,
                    items: [returnedItem.itemId],
                    productId: returnedItem.productId,
                    productGroupId: returnedItem.productGroupId,
                    productGroupName: returnedItem.productGroupName,
                    name: returnedItem.name,
                    excludeFromDiscounts: returnedItem.excludeFromDiscounts,
                    amount: returnedItem.amount,
                    costAmount: returnedItem.costAmount,
                    vatAmount: 0,
                    note: returnedItem.note,
                    quantity: 1,
                });
            }
        }, []);

        // Sort groups by their items
        itemGroups.sort((a, b) => {
            return a.items[0] > b.items[0] ? 1 : -1; // items in a and b can never be the same
        });

        // Next we move all groups of same discount together
        const groupedByDiscount: OrderItemGroup[] = [];
        while (itemGroups.length > 0) {
            groupedByDiscount.push(itemGroups[0]);
            itemGroups.splice(0, 1);
            const toGroupWith = groupedByDiscount[groupedByDiscount.length - 1];

            let j = 0;
            while (
                toGroupWith.discountKey !== undefined &&
                toGroupWith.discountKey >= 0 &&
                j < itemGroups.length
            ) {
                const itemGroup = itemGroups[j];
                if (
                    itemGroup.discountKey !== undefined &&
                    itemGroup.discountKey >= 0 &&
                    itemGroup.discountKey === toGroupWith.discountKey
                ) {
                    groupedByDiscount.push(itemGroup);
                    itemGroups.splice(j, 1);
                } else {
                    j++;
                }
            }
        }

        return groupedByDiscount;
    },
});

/**
 * Action for updating lines on the context
 */
export const updateLinesAction = assign<OrderContext, any>({
    lines: context => createInvoiceLines(context),
});

/**
 * Action for updating vat on item groups
 */
export const updateItemGroupsVatAction = assign<OrderContext, any>({
    itemGroups: context => {
        // For VAT calculations we distribute discounts to the item groups based on their scale:
        //
        // ig-amount        = price * quantity                                                                  ; Item group amount
        // ig-od-factor     = ig-amount / order-item-amount                                                     ; Item group order discount factor
        // ig-pd-factor     = ig-amount / product-discount-amount                                               ; Item group product discount factor
        // ig-discount      = (ig-o-factor * order-discount-amount) - (ig-pd-factor * product-discount-amount)  ; Item group discount total
        //
        // ig-vat-amount    = (ig-amount - ig-discount) / (1 + ig-vat / 100)

        const orderDiscounts = getOrderDiscounts(context.appliedDiscounts);
        const orderDiscount = orderDiscounts[0];

        for (let i = 0; i < context.itemGroups.length; i++) {
            const itemGroup = context.itemGroups[i];

            // Use VAT of first item of the item group as the item group VAT
            const firstItemId = itemGroup.items[0];
            const item =
                itemGroup.type === "ITEM"
                    ? context.items.find(itm => itm.itemId === firstItemId)
                    : context.returnedItems.find(
                          itm => itm.itemId === firstItemId
                      );
            if (!item) {
                continue;
            }

            let discount = 0;

            // Calculated scaled product discount amount
            if (
                itemGroup.discountKey !== undefined &&
                itemGroup.discountKey >= 0
            ) {
                const productDiscount =
                    context.appliedDiscounts[itemGroup.discountKey];

                const totalItemAmountForDiscountItems = context.itemGroups
                    .filter(itr => itr.discountKey === itemGroup.discountKey)
                    .reduce((sum, itr) => sum + itr.amount, 0);

                const itemGroupDiscountFactor =
                    totalItemAmountForDiscountItems === 0
                        ? 0
                        : itemGroup.amount / totalItemAmountForDiscountItems;

                discount += productDiscount.amount * itemGroupDiscountFactor;
            }

            // Calculated scaled order discount amount
            if (orderDiscount) {
                const itemGroupFactor =
                    context.itemAmount === 0 || itemGroup.type === "RETURN"
                        ? 0
                        : itemGroup.amount / context.itemAmount;
                discount += orderDiscount.amount * itemGroupFactor;
            }

            // Calculate VAT and apply to item group

            const itemGroupCalculatedAmount = itemGroup.amount - discount;
            const amountExclVat = Math.round(
                itemGroupCalculatedAmount /
                    (1 +
                        (item.vat && item.vat.percentage
                            ? item.vat.percentage / 100
                            : 0))
            );
            itemGroup.vatAmount = Math.round(
                itemGroupCalculatedAmount - amountExclVat
            );
        }
        return context.itemGroups;
    },
});

export const updateTotalVatAction = assign<OrderContext, any>({
    totalVatAmount: context =>
        context.itemGroups.reduce(
            (sum, group) =>
                sum + group.vatAmount * (group.type === "RETURN" ? -1 : 1),
            0
        ),
});

export const nameSetAction = assign<OrderContext, NameSetEvent>({
    name: (_context: OrderContext, event: NameSetEvent) => {
        return event.data.name;
    },
});

export const tableAssignedAction = assign<OrderContext, TableAssignedEvent>({
    tableId: (_context: OrderContext, event: TableAssignedEvent) => {
        return event.data.tableId;
    },
});

/**
 * Action for removing all items with the specified productId or itemId
 */
export const removeItemAction = assign<OrderContext, ItemsRemovedEvent>({
    items: (context, event: ItemsRemovedEvent) => {
        return context.items.filter(
            item => !event.data.items.includes(item.itemId)
        );
    },
});

/**
 * Action for set note for given item
 */
export const setItemsNoteAction = assign<OrderContext, SetItemsNoteEvent>({
    items: (context, event: SetItemsNoteEvent) => {
        const items = context.items;
        for (let i = 0; i < event.data.items.length; i++) {
            const key = items.findIndex(
                itr => itr.itemId === event.data.items[i]
            );

            if (key >= 0) {
                items[key].note = event.data.note;
            }
        }
        return items;
    },
    returnedItems: (context, event: SetItemsNoteEvent) => {
        const returnedItems = context.returnedItems;
        for (let i = 0; i < event.data.items.length; i++) {
            const key = returnedItems.findIndex(
                itr => itr.itemId === event.data.items[i]
            );

            if (key >= 0) {
                returnedItems[key].note = event.data.note;
            }
        }
        return returnedItems;
    },
});

/**
 * Action for adding a return item with the specified productId
 */
export const addReturnItemAction = assign<OrderContext, ItemReturnedEvent>({
    returnedItems: (context, event: ItemReturnedEvent) => {
        let idCounter = context.itemIdCounter;
        for (let i = 0; i < event.data.quantity; i++) {
            context.returnedItems.push({
                amount: event.data.amount,
                costAmount: event.data.costAmount,
                paid: {
                    fraction: { numerator: 0, denominator: 0 },
                    amount: 0,
                    vatAmount: 0,
                    costAmount: 0,
                },
                productId: event.data.productId,
                productGroupId: event.data.productGroupId,
                productGroupName: event.data.productGroupName,
                vat: event.data.vat,
                name: event.data.name,
                note: event.data.note,
                itemId: idCounter,
                addedAt: event.data.addedAt,
                excludeFromDiscounts: event.data.excludeFromDiscounts,
                discountKey: event.data.discountKey,
                external: event.data.external
                    ? { ...event.data.external, checkedOut: false }
                    : undefined,
            });

            idCounter++;
        }

        return context.returnedItems;
    },
    itemIdCounter: (context, event: ItemReturnedEvent) => {
        return context.itemIdCounter + event.data.quantity;
    },
});

/**
 * Action for removing a returned item with the specified productId
 */
export const removeReturnedItemAction = assign<
    OrderContext,
    ItemReturnedRemovedEvent
>({
    returnedItems: (context, event: ItemReturnedRemovedEvent) => {
        return context.returnedItems.filter(
            i => !event.data.items.includes(i.itemId)
        );
    },
});

/**
 * Action for setting the context discounts to the discounts given in an event
 */
export const setSetDiscountsAction = assign<OrderContext, SetDiscountsEvent>({
    discounts: (orderContext, event: SetDiscountsEvent) => {
        return [...event.data.discounts];
    },
});

/**
 * Action for calculating the context applied discounts to the calculated discounts from the discount service
 */
export const calculateAppliedDiscountsAction = assign<OrderContext, any>({
    appliedDiscounts: orderContext => {
        const appliedDiscounts = calculateDiscounts(
            {
                items: orderContext.items,
                returnedItems: orderContext.returnedItems,
                openedAt: dateToDateTime(new Date(orderContext.openedAt)),
                tags: orderContext.tags,
                customer: orderContext.customer,
                departmentId: orderContext.departmentId,
            },
            orderContext.discounts
        );

        orderContext.items.forEach(item => {
            item.discountKey = -1;
        });

        appliedDiscounts.forEach((discount, discountKey) => {
            if (!discount.items) {
                return;
            }

            discount.items.forEach(itemId => {
                if (discount.discount.type === "RETURN") {
                    return;
                }
                orderContext.items[
                    orderContext.items.findIndex(itr => itr.itemId === itemId)
                ].discountKey = discountKey;
            });
            discount.items.forEach(itemId => {
                if (discount.discount.type !== "RETURN") {
                    return;
                }
                orderContext.returnedItems[
                    orderContext.returnedItems.findIndex(
                        itr => itr.itemId === itemId
                    )
                ].discountKey = discountKey;
            });
        });
        return appliedDiscounts;
    },
});

/**
 * Action for adding tags the context
 */
export const addTagAction = assign<OrderContext, OrderTagAddedEvent>({
    tags: (orderContext, event) => {
        return [...orderContext.tags, event.data];
    },
});

/**
 * Action for adding tags the context
 */
export const addCustomerAction = assign<OrderContext, OrderCustomerAddedEvent>({
    customer: (orderContext, event) => event.data,
});

/**
 * Action for marking external order item as checked out
 */
export const updateExternalCheckoutOutState = assign<
    OrderContext,
    OrderExternalProductCheckedOutEvent
>({
    items: (orderContext, event) => {
        const index = orderContext.items.findIndex(
            itr => itr.itemId === event.data.itemId
        );
        const item = orderContext.items[index];

        if (!item) {
            return orderContext.items;
        }
        if (!item.external) {
            throw new Error(
                "Cannot mark non-external order item as externally checked out"
            );
        }

        item.external.checkedOut = true;
        item.external.checkoutRef = event.data.ref;

        return orderContext.items;
    },
    returnedItems: (orderContext, event) => {
        const index = orderContext.returnedItems.findIndex(
            itr => itr.itemId === event.data.itemId
        );
        const item = orderContext.returnedItems[index];

        if (!item) {
            return orderContext.returnedItems;
        }
        if (!item.external) {
            throw new Error(
                "Cannot mark non-external order item as externally checked out"
            );
        }

        item.external.checkedOut = true;
        item.external.checkoutRef = event.data.ref;

        return orderContext.returnedItems;
    },
});

export const externalDataAddedAction = assign<
    OrderContext,
    OrderExternalDataAddedEvent
>({
    externalData: (orderContext, event) => {
        orderContext.externalData.push({
            integrationId: event.data.integrationId,
            data: event.data.data,
        });
        return orderContext.externalData;
    },
    invoices: ({ invoices }, event) => {
        const invoice = invoices[invoices.length - 1];
        if (!invoice.externalReceipts) {
            invoice.externalReceipts = [];
        }

        if (event.data.data && event.data.data.length > 0) {
            for (let i = 0; i < event.data.data.length; i++) {
                invoice.externalReceipts.push({
                    receipt: event.data.data[i].receipt,
                    integrationId: event.data.integrationId,
                });
            }
        }

        return invoices;
    },
});

/**
 * Action for adding a new open invoice
 */
export const addOpenInvoice = assign<OrderContext, OrderInvoiceStartedEvent>({
    invoices: (orderContext, event) => {
        return [
            ...orderContext.invoices,
            createOpenInvoiceFromLines(orderContext, event),
        ];
    },
});

/**
 * Action for cancelling open invoice. This will always be the latest added invoice.
 */
export const cancelOpenInvoice = assign<OrderContext, any>({
    invoices: ({ invoices }) => {
        return invoices.slice(0, invoices.length - 1);
    },
});

/**
 * Action for completing open invoice. This will always be the latest added invoice.
 */
export const completeOpenInvoice = assign<
    OrderContext,
    OrderInvoiceCompletedEvent
>({
    invoices: ({ invoices }, event) => {
        invoices[invoices.length - 1] = {
            ...invoices[invoices.length - 1],
            status: "CLOSED",
            receiptNumber: event.data.receiptNumber,
            receiptText: event.data.receiptText,
        };

        return invoices;
    },
    items: ({ invoices, items, itemGroups }) => {
        const invoice = invoices[invoices.length - 1];
        const lines = invoice.lines;
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const itemGroup = itemGroups[line.itemGroupIndex];
            if (itemGroup.type !== "ITEM") {
                continue;
            }

            let distributeSize: Fraction = {
                numerator: line.fraction.numerator * itemGroup.items.length,
                denominator: line.fraction.denominator,
            };

            // The distributed amount is simply the amount of the invoice line. We
            // do not subtract the discount amount, as the saved amount is part
            // of the paid amount. Let me explain with an example.
            // Imagine buying 2 beers for 60.00 and you get 10.00 discount.
            // Let's now say you split the bill in two. That would make each part of the
            // bill cost 25.00. You pay you part of the bill for 25.00. So the remainder
            // is now 25.00. But for some crazy reason, your friend who is paying for
            // the other part of the bill, can't get the discount. So you'll expect
            // he to be paying 30.00. If we subtracted the discount amount from the
            // remainder, but the discount no longer was available, the remainder will
            // become 35.00. This is not the 30.00 as expected, and the merchant
            // will then actually end up with 5.00 too much. So instead of subtracting
            // the discount amount, we decide that when you've paid your 25.00, you've actually
            // paid 30.00 and the remainder then will become 30.00. And then your
            // friend that lost his discount, just has to pay the remaining amount. This
            // still holds if you kept the discount, because the 25.00 will be counted
            // as 30.00, just like your part of the bill.
            let distributeAmount = line.amount;
            let distributeVatAmount = line.vatAmount;

            for (
                let j = 0;
                j < itemGroup.items.length && distributeSize.numerator > 0;
                j++
            ) {
                const itemId = itemGroup.items[j];
                const item = items.find(itr => itr.itemId === itemId);
                if (!item) {
                    throw new Error("Item not found");
                }

                if (
                    item.paid.fraction.numerator !== 0 &&
                    item.paid.fraction.numerator ===
                        item.paid.fraction.denominator
                ) {
                    continue;
                }

                const fractionPay = minFractions(
                    subtractFractions(
                        {
                            numerator: 1,
                            denominator: 1,
                        },
                        item.paid.fraction
                    ),
                    distributeSize
                );

                item.paid.fraction = addFractions(
                    item.paid.fraction,
                    fractionPay
                );
                distributeSize = subtractFractions(distributeSize, fractionPay);

                if (distributeSize.numerator > 0) {
                    const amountPay = Math.round(
                        (distributeAmount * fractionPay.numerator) /
                            fractionPay.denominator
                    );
                    const amountVat = Math.round(
                        (distributeVatAmount * fractionPay.numerator) /
                            fractionPay.denominator
                    );

                    item.paid.amount += amountPay;
                    item.paid.vatAmount += amountVat;

                    distributeAmount -= amountPay;
                    distributeVatAmount -= amountVat;
                } else {
                    item.paid.amount += distributeAmount;
                    item.paid.vatAmount += distributeVatAmount;
                }
            }
        }
        return items;
    },
    returnedItems: ({ invoices, returnedItems, itemGroups }) => {
        const invoice = invoices[invoices.length - 1];
        const lines = invoice.lines;
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const itemGroup = itemGroups[line.itemGroupIndex];
            if (itemGroup.type !== "RETURN") {
                continue;
            }

            let distributeSize: Fraction = {
                numerator: line.fraction.numerator * itemGroup.items.length,
                denominator: line.fraction.denominator,
            };
            let distributeAmount = line.amount - line.discountAmount;

            for (
                let j = 0;
                j < itemGroup.items.length && distributeSize.numerator > 0;
                j++
            ) {
                const itemId = itemGroup.items[j];
                const item = returnedItems.find(itr => itr.itemId === itemId);
                if (!item) {
                    throw new Error("Item not found");
                }

                if (
                    item.paid.fraction.numerator !== 0 &&
                    item.paid.fraction.numerator ===
                        item.paid.fraction.denominator
                ) {
                    continue;
                }

                const fractionPay = minFractions(
                    subtractFractions(
                        {
                            numerator: 1,
                            denominator: 1,
                        },
                        item.paid.fraction
                    ),
                    distributeSize
                );

                item.paid.fraction = addFractions(
                    item.paid.fraction,
                    fractionPay
                );
                distributeSize = subtractFractions(distributeSize, fractionPay);

                if (distributeSize.numerator > 0) {
                    const amountPay =
                        (distributeAmount * fractionPay.numerator) /
                        fractionPay.denominator;
                    item.paid.amount += amountPay;
                    distributeAmount -= amountPay;
                } else {
                    item.paid.amount += distributeAmount;
                }
            }
        }
        return returnedItems;
    },
});

export const updatePaymentAllowed = assign<
    OrderContext,
    any // TypeScript inference fails here. We don't really use the event, so I'd argue this action should be 3 different actions
>({
    paymentAllowed: (context, event) => {
        switch (event) {
            case "PAYMENT_ADDED":
                return (
                    context.invoices[context.invoices.length - 1].remainder !==
                    0
                );

            case "INVOICE_COMPLETED":
                return context.remainder !== 0;

            case "EXTERNAL_PRODUCT_FAILED":
                return true;
        }

        return true;
    },
});

// This function purpose is to make sure that no property of OrderContext is left out
// in the `orderOpenedAction` below. If any property is missing we'll get a compile error.
export const orderOpenedActionProperties = (properties: {
    [K in keyof OrderContext]:
        | ((
              context: OrderContext,
              event: OrderOpenedEvent,
              meta: AssignMeta<OrderContext, OrderOpenedEvent>
          ) => OrderContext[K])
        | OrderContext[K];
}) => properties;

/**
 * Action for resetting the machine context back to it's default values
 */
export const orderOpenedAction = assign<OrderContext, OrderOpenedEvent>(
    orderOpenedActionProperties({
        id: (ctx: OrderContext, event: OrderOpenedEvent) => {
            return event.aggregateId;
        },
        shiftId: (_, event: OrderOpenedEvent) => {
            return event.data.shiftId;
        },
        itemAmount: () => 0,
        totalVatAmount: () => 0,
        returnedItemAmount: () => 0,
        totalAmount: () => 0,
        discountAmount: () => 0,
        paymentAmount: () => 0,
        remainder: () => 0,
        items: () => [],
        itemIdCounter: () => 0,
        itemGroups: () => [],
        lines: () => [],
        returnedItems: () => [],
        appliedDiscounts: () => [],
        discounts: (ctx: OrderContext, event: OrderOpenedEvent) => {
            return event.data.discounts ? [...event.data.discounts] : [];
        },
        receiptText: () => "",
        openedAt: (_, event: OrderOpenedEvent) => event.data.openedAt,
        name: () => "",
        currency: (_, event: OrderOpenedEvent) => event.data.currency,
        departmentId: (_, event: OrderOpenedEvent) => event.data.departmentId,
        tags: () => [],
        customer: () => undefined,
        tableId: () => undefined,
        invoices: () => [],
        paymentAllowed: () => true,
        externalData: () => [],
    })
);
