import {
    Alert,
    Button,
    CheckBox,
    Form,
    IconButton,
    InputControl,
    InputLabel,
    Loading,
    NumberInput,
    Picker,
    RemovableRow,
    RequiredText,
    StyleFunction,
    Surface,
    Text,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import { GQPrintersQuery, GQVatsQuery, useProductFormQuery } from "graphql-sdk";
import { IProductInput, parseAmount, SupportedCurrenciesType } from "lib";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "locales";
import { View } from "react-native";
import { Divider } from "react-native-paper";

import produce from "immer";

export function ProductForm(props: {
    form: Form<IProductInput>;
    onSubmit: () => void;
    currency: SupportedCurrenciesType;
    submitButton: [string, string];
    productId?: string;
    printers?: GQPrintersQuery;
    vats?: GQVatsQuery;
}) {
    const [t] = useTranslation();
    const styles = useThemedStyle(styleFunc);

    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const { data, loading } = useProductFormQuery();

    const [enabled, setEnabled] = useState<boolean>(!values?.inheritPrinter);

    const onEnablePrinterSetting = useCallback(
        checked => {
            setEnabled(checked);
            setValue("inheritPrinter", !checked);
        },
        [setValue]
    );

    const onAddBarcode = useCallback(() => {
        setValue(
            "barcodes",
            produce(values?.barcodes, draft => {
                draft?.push({
                    id: undefined,
                    barcode: "",
                });
            }) || []
        );
    }, [setValue, values?.barcodes]);

    const onBarcodeChange = useCallback(
        (text, index: number) => {
            if (text === undefined || text.trim() === "") {
                return;
            }
            setValue(
                "barcodes",
                produce(values?.barcodes, draft => {
                    if (draft !== undefined) {
                        draft[index].barcode = text;
                    }
                }) || []
            );
        },
        [setValue, values?.barcodes]
    );

    const onRemoveBarcode = useCallback(
        (_item, index) => {
            setValue(
                "barcodes",
                produce(values?.barcodes, draft => {
                    draft?.splice(index, 1);
                }) || []
            );
        },
        [setValue, values?.barcodes]
    );

    useEffect(() => {
        // Add an empty barcode if there are no barcodes yet
        if (values?.barcodes.length === 0) {
            setValue("barcodes", [{ barcode: "" }]);
        }
    }, [setValue, values?.barcodes.length]);

    useEffect(() => {
        setEnabled(!values?.inheritPrinter);
    }, [values]);

    if (!values || loading) {
        return (
            <View style={styles.container}>
                <Surface>
                    <Loading />
                </Surface>
            </View>
        );
    }

    return (
        <View style={styles.container} testID="product">
            {values.isExternal && (
                <Alert type="info" style={styles.readonlyContainer}>
                    {t(
                        "backoffice.common.readonly_product",
                        "This product is controlled by an external system via the API and most of the properties can only be changed from there."
                    )}
                </Alert>
            )}

            <Surface>
                <InputControl
                    error={errors.name}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : null
                    }
                >
                    <TextInput
                        label={t("common.name", "Name")}
                        placeholder={t(
                            "backoffice.common.enter_name",
                            "Enter name"
                        )}
                        defaultValue={values.name}
                        onChangeText={text => setValue("name", text)}
                        disabled={values.isExternal}
                        testID="name"
                        required={true}
                    />
                </InputControl>

                <InputControl
                    error={errors.active}
                    description={t(
                        "backoffice.product_form.active_description",
                        "Notice: If a product is set to inactive and the product is used in a layout, the product will be removed from the layout"
                    )}
                >
                    <CheckBox
                        label={t("common.active", "Active")}
                        value={values.active}
                        onValueChange={checked => setValue("active", checked)}
                        testID="active"
                    />
                </InputControl>

                <InputControl
                    error={errors.groupId}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : null
                    }
                >
                    <Picker
                        label={t("common.product_group", "Product group")}
                        onValueChange={value => {
                            setValue("groupId", value);
                        }}
                        selectedValue={values.groupId}
                        disabled={values.isExternal}
                        testID="group"
                        required={true}
                    >
                        <Picker.Item
                            label={t(
                                "backoffice.product_form.choose_product_group",
                                "Choose a product group"
                            )}
                            value=""
                            testID="noProductGroup"
                        />
                        {data?.productGroups.data.map(productGroup => {
                            return (
                                <Picker.Item
                                    key={productGroup.id}
                                    value={productGroup.id}
                                    label={productGroup.name}
                                    testID={"group:" + productGroup.name}
                                />
                            );
                        })}
                    </Picker>
                </InputControl>
                <InputControl
                    error={errors.externalId}
                    description={
                        values.isExternal
                            ? `${t(
                                  "backoffice.product_form.external_id_description",
                                  "Number or ID that is used by another system. Used for reference."
                              )} ${t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )}`
                            : t(
                                  "backoffice.product_form.external_id_description",
                                  "Number or ID that is used by another system. Used for reference."
                              )
                    }
                >
                    <TextInput
                        label={t(
                            "backoffice.product_form.external_id",
                            "Number"
                        )}
                        placeholder={t(
                            "backoffice.product_form.enter_external_id",
                            "Enter number"
                        )}
                        defaultValue={values.externalId || ""}
                        onChangeText={text =>
                            setValue("externalId", text || null)
                        }
                        disabled={values.isExternal}
                        testID="externalId"
                    />
                </InputControl>

                <InputControl
                    error={errors.barcode}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : t(
                                  "backoffice.product_form.barcodes_description",
                                  "Enter one or more barcodes for the product by pressing +"
                              )
                    }
                >
                    <InputLabel>
                        {t("backoffice.product_form.barcode", "Barcode")}
                        {!values.isExternal && (
                            <IconButton
                                name="plus"
                                size="small"
                                onPress={onAddBarcode}
                                style={styles.addBarcode}
                            />
                        )}
                    </InputLabel>

                    {values.barcodes.map((item, index) => (
                        <RemovableRow
                            key={`barcode_row:${item.barcode}_${index}`}
                            onRemove={() => {
                                onRemoveBarcode(item, index);
                            }}
                            disabled={values.isExternal}
                        >
                            <TextInput
                                style={styles.textInput}
                                placeholder={t(
                                    "backoffice.product_form.enter_barcode",
                                    "Enter barcode"
                                )}
                                defaultValue={item.barcode || ""}
                                onBlur={event =>
                                    onBarcodeChange(
                                        event.nativeEvent.text,
                                        index
                                    )
                                }
                                disabled={values.isExternal}
                                testID="barcode"
                            />
                        </RemovableRow>
                    ))}
                </InputControl>

                <InputControl
                    error={errors.parentId}
                    description={
                        values.isExternal
                            ? `${t(
                                  "backoffice.product_form.parent_description",
                                  "If selected, the product becomes a sub-product of the selected parent product."
                              )} ${t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )}`
                            : t(
                                  "backoffice.product_form.parent_description",
                                  "If selected, the product becomes a sub-product of the selected parent product."
                              )
                    }
                >
                    <Picker
                        label={t(
                            "backoffice.product_form.parent",
                            "Parent product"
                        )}
                        onValueChange={value => {
                            setValue("parentId", value !== "" ? value : null);
                        }}
                        selectedValue={values.parentId || ""}
                        disabled={values.isExternal}
                    >
                        <Picker.Item
                            label={t(
                                "backoffice.product_form.choose_parent",
                                "Choose a parent product"
                            )}
                            value=""
                        />
                        {data?.products.data.map(product => {
                            if (
                                props.productId &&
                                product.id === props.productId
                            ) {
                                return null;
                            }
                            return (
                                <Picker.Item
                                    key={product.id}
                                    value={product.id}
                                    label={product.name}
                                />
                            );
                        })}
                    </Picker>
                </InputControl>

                <InputControl
                    error={errors.amount}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : null
                    }
                >
                    <NumberInput
                        label={t(
                            "backoffice.product_form.price_incl_vat",
                            "Price incl VAT"
                        )}
                        placeholder={t(
                            "backoffice.product_form.enter_price_incl_vat",
                            "Enter price incl VAT"
                        )}
                        defaultValue={values.amount / 100}
                        onChange={value => {
                            setValue("amount", parseAmount(value || 0));
                        }}
                        decimals={2}
                        unit={props.currency}
                        disabled={values.isExternal}
                        testID="amount"
                    />
                    {values.amount < 0 && (
                        <View>
                            <Text style={styles.info}>
                                {t(
                                    "backoffice.product_form.amount_is_negative",
                                    "Notice: The amount is negative"
                                )}
                            </Text>
                        </View>
                    )}
                </InputControl>

                <InputControl
                    error={errors.minimumAmount}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : null
                    }
                >
                    <NumberInput
                        label={t(
                            "backoffice.product_form.minimum_price",
                            "Minimum price"
                        )}
                        placeholder={t(
                            "backoffice.product_form.enter_minimum_price",
                            "Enter minimum price"
                        )}
                        defaultValue={values.minimumAmount / 100}
                        onChange={value =>
                            setValue("minimumAmount", parseAmount(value || 0))
                        }
                        disabled={values.amount < 0 || values.isExternal}
                        decimals={2}
                        unit={props.currency}
                        testID="minimumAmount"
                    />
                </InputControl>
                <InputControl
                    error={errors.costAmount}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : null
                    }
                >
                    <NumberInput
                        label={t(
                            "backoffice.product_form.cost_price",
                            "Cost price"
                        )}
                        placeholder={t(
                            "backoffice.product_form.enter_cost_price",
                            "Enter cost price"
                        )}
                        defaultValue={values.costAmount / 100}
                        onChange={value =>
                            setValue("costAmount", parseAmount(value || 0))
                        }
                        decimals={2}
                        unit={props.currency}
                        disabled={values.isExternal}
                        testID="costPrice"
                    />
                </InputControl>

                <InputControl
                    error={errors.ownVatId}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : null
                    }
                >
                    <Picker
                        label={t("backoffice.product_form.vat", "VAT")}
                        onValueChange={text =>
                            setValue("ownVatId", text === "" ? null : text)
                        }
                        selectedValue={values.ownVatId || undefined}
                        disabled={values.isExternal}
                        testID="vat"
                    >
                        <Picker.Item
                            label={t(
                                "backoffice.product_form.use_group__vat",
                                "Use product group VAT"
                            )}
                            value=""
                        />
                        {props.vats?.vats.data.map(vat => {
                            return (
                                <Picker.Item
                                    key={vat.id}
                                    value={vat.id}
                                    label={vat.name}
                                    testID={"vat:" + vat.name}
                                />
                            );
                        })}
                    </Picker>
                </InputControl>
                <InputControl
                    error={errors.ownExcludeFromDiscounts}
                    description={
                        values.isExternal
                            ? t(
                                  "backoffice.common.readonly_value",
                                  "This value is controlled by an external system via the API and can only be changed from here."
                              )
                            : null
                    }
                >
                    <CheckBox
                        label={t(
                            "backoffice.product_form.exclude_from_discounts",
                            "Exclude from discounts"
                        )}
                        value={values.ownExcludeFromDiscounts}
                        onValueChange={value =>
                            setValue("ownExcludeFromDiscounts", value)
                        }
                        disabled={values.isExternal}
                        testID="ownExludeFromDiscounts"
                    />
                </InputControl>

                {values.isExternal ? <Divider style={styles.divider} /> : null}

                <InputControl
                    error={errors.buttonText}
                    description={t(
                        "backoffice.product_form.plu_description",
                        "If set, the product can be found by this code on the cash register"
                    )}
                >
                    <NumberInput
                        label={t("backoffice.product_form.plu_label", "PLU")}
                        defaultValue={values.plu || null}
                        onChange={value => setValue("plu", value || null)}
                        testID="plu"
                    />
                </InputControl>

                <InputControl
                    error={errors.buttonText}
                    description={t(
                        "backoffice.product_form.button_text_description",
                        "If set, the button text will be shown on the cash register instead of the product name"
                    )}
                >
                    <TextInput
                        label={t(
                            "backoffice.product_form.button_text_label",
                            "Button Text"
                        )}
                        defaultValue={values.buttonText || ""}
                        onChangeText={text =>
                            setValue("buttonText", text !== "" ? text : null)
                        }
                        testID="buttonText"
                    />
                </InputControl>

                <InputControl
                    error={errors.printerId || undefined}
                    description={t(
                        "backoffice.product_form.printer.override_product_group_settings",
                        "Uses setting from product group unless overridden here."
                    )}
                >
                    <InputLabel>
                        {t(
                            "backoffice.product_form.printer.do_print",
                            "Send to printer for order handling"
                        )}
                    </InputLabel>

                    <View style={styles.row}>
                        <View style={styles.checkbox}>
                            <CheckBox
                                value={enabled}
                                onValueChange={onEnablePrinterSetting}
                                testID="printerSelect"
                            />
                        </View>
                        <View style={styles.input}>
                            <Picker
                                onValueChange={value => {
                                    setValue("printerId", value);
                                }}
                                selectedValue={values.printerId || undefined}
                                disabled={!enabled}
                                testID="printer"
                            >
                                {enabled && (
                                    <Picker.Item
                                        label={t(
                                            "backoffice.product_form.printer.do_not_print",
                                            "Do not print"
                                        )}
                                        value=""
                                        testID="printer:doNotPrint"
                                    />
                                )}
                                {enabled &&
                                    props.printers?.printers.data
                                        .filter(itr => itr.class === "Generic")
                                        .filter(itr => itr.type !== "INTERNAL")
                                        .map(printer => (
                                            <Picker.Item
                                                key={printer.id}
                                                value={printer.id}
                                                label={printer.name}
                                                testID={
                                                    "printer:" + printer.name
                                                }
                                            />
                                        ))}
                            </Picker>
                        </View>
                    </View>
                </InputControl>
                <Button onPress={handleSubmit(props.onSubmit)} testID="save">
                    {t(props.submitButton[0], props.submitButton[1])}
                </Button>
                <RequiredText />
            </Surface>
        </View>
    );
}
const styleFunc: StyleFunction = theme => ({
    container: {
        width: theme.dimensions.maxFormWidth,
    },
    row: {
        flexDirection: "row",
    },
    checkbox: {
        paddingTop: 8,
        paddingRight: 20,
    },
    divider: {
        marginBottom: theme.spacingScale * 2,
    },
    input: {
        flex: 1,
    },
    info: {
        fontSize: 12,
        color: theme.colors.warning,
    },
    readonlyContainer: {
        marginBottom: theme.spacingScale * 2,
    },
    readonlyText: {
        ...theme.fonts.thin,
        fontSize: 14,
        color: theme.colors.textDark,
        fontStyle: "italic",
    },
    addBarcode: {
        color: theme.colors.textDark,
    },
    textInput: { flex: 1 },
});
