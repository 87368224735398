import {
    formatAmount,
    type ILayoutProduct,
    type LayoutButtonMetaData,
    type ProductButton,
} from "lib";
import { useForm, useMerchantConfig } from "@venuepos/react-common";
import { schemaProductButton } from "../../../../forms";
import React, { useCallback } from "react";
import { ProductButtonForm } from "./product-button-form";
import { AddButtonModalOutput } from "..";
import { AvailableLocale } from "locales";
import { useAdminSession } from "../../../../../../session";

export function ProductButtonMainForm({
    button,
    buttonProduct,
    metaData,
    onClose,
}: {
    button: ProductButton;
    buttonProduct: ILayoutProduct;
    metaData: LayoutButtonMetaData;
    onClose: (values?: AddButtonModalOutput) => void;
}) {
    const { currency } = useMerchantConfig();
    const [{ locale }] = useAdminSession(["locale"]);

    const productButtonForm = useForm<ProductButton>(schemaProductButton, {
        buttonType: "PRODUCT",
        id: button.id || "",
        productId: buttonProduct.id || "",
        x: button.x || 0,
        y: button.y || 0,
        width: button.width || 1,
        height: button.height || 1,
        color: button.color || "",
        label: button.label || "",
    });

    const [{ values }] = productButtonForm;

    const editProductButton = useCallback(() => {
        if (!values || !buttonProduct) {
            return;
        }

        return onClose({
            buttonType: "PRODUCT",
            formValues: values,
            productData: buttonProduct,
            displayValues: {
                label: buttonProduct.buttonText || buttonProduct.name,
                color: buttonProduct.group.color,
                formattedAmount: formatAmount(buttonProduct.amount, currency, {
                    locale: locale as AvailableLocale,
                }),
            },
        });
    }, [buttonProduct, currency, locale, onClose, values]);

    return (
        <ProductButtonForm
            form={productButtonForm}
            onSubmit={editProductButton}
            submitButton={["common.save", "Save"]}
            product={buttonProduct}
            metaData={metaData}
        />
    );
}
