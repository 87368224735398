import React from "react-native";
import {
    Loading,
    InputControl,
    Form,
    RequiredText,
    Button,
    useForm,
    TextInput,
    NumberInput,
    Headline,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import {
    GQIntegrationQuery,
    useCmpProductSettingsSaveMutation,
    useIntegrationSettingsLazyQuery,
} from "graphql-sdk";
import { useCallback, useEffect } from "react";
import { useHandleMutationError } from "../../hooks";
import { CmpProductSettings, CmpProductSettingsInput } from "lib";
import { schemaCmpProductSettings } from "./forms";

export function CmpProductSettingsTab({
    integration,
}: {
    integration: GQIntegrationQuery["integration"];
}) {
    const [t] = useTranslation();
    const { handleMutationError } = useHandleMutationError();
    const [cmpProductSettingsSave] = useCmpProductSettingsSaveMutation();
    const form = useForm<CmpProductSettingsInput>(
        schemaCmpProductSettings,
        null
    );

    const [{ values }, { setDefaultValues }] = form;

    const edit = useCallback(async () => {
        if (
            !integration ||
            !integration.type ||
            integration.type !== "CMP-PRODUCT" ||
            !values
        ) {
            return;
        }

        await handleMutationError(async () => {
            const settingsInput: CmpProductSettingsInput = {
                hostname: values.hostname,
                port: values.port,
                vhost: values.vhost,
                exchangeName: values.exchangeName,
                queueName: values.queueName,
                routingKey: values.routingKey,
                username: values.username,
                password: values.password,
                cmpOrderUrl: values.cmpOrderUrl,
            };

            await cmpProductSettingsSave({
                variables: {
                    integrationId: integration.id,
                    settings: settingsInput,
                },
            });
        }, t("common.saved", "Saved"));
    }, [integration, values, handleMutationError, t, cmpProductSettingsSave]);

    const [getIntegrationSettings, integrationSettings] =
        useIntegrationSettingsLazyQuery({
            fetchPolicy: "no-cache",
        });

    useEffect(() => {
        if (!integrationSettings?.data) {
            return;
        }

        const settings = integrationSettings.data?.integrationSettings;

        const defaultValues = {
            hostname: (settings as CmpProductSettings).hostname ?? "",
            port: (settings as CmpProductSettings).port ?? 0,
            vhost: (settings as CmpProductSettings).vhost ?? "",
            exchangeName: (settings as CmpProductSettings).exchangeName ?? "",
            queueName: (settings as CmpProductSettings).queueName ?? "",
            routingKey: (settings as CmpProductSettings).routingKey ?? "",
            username: (settings as CmpProductSettings).username ?? "",
            password: (settings as CmpProductSettings).password ?? "",
            cmpOrderUrl: (settings as CmpProductSettings).cmpOrderUrl ?? "",
        };

        setDefaultValues(defaultValues);
    }, [integrationSettings, setDefaultValues]);

    useEffect(() => {
        if (!integration?.id) {
            return;
        }

        getIntegrationSettings({
            variables: { integrationId: integration.id },
        });
    }, [getIntegrationSettings, integration?.id]);

    if (
        !integration ||
        !integrationSettings.data ||
        integrationSettings.loading
    ) {
        return <Loading />;
    }

    return (
        <CmpProductSettingsForm
            form={form}
            onSubmit={edit}
            submitButton={["common.edit", "Edit"]}
        />
    );
}

function CmpProductSettingsForm({
    form,
    onSubmit,
    submitButton,
}: {
    form: Form<CmpProductSettingsInput>;
    onSubmit: () => Promise<void>;
    submitButton: [string, string];
}) {
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = form;

    if (!values) {
        return <Loading />;
    }

    return (
        <>
            <Headline size="h5">
                {t(
                    "backoffice.integrations.cmp_product.product_sync_headline",
                    "Product synchronization"
                )}
            </Headline>
            <InputControl
                error={errors.hostname}
                description={t(
                    "backoffice.integrations.cmp_product.hostname_description",
                    "Hostname of the RabbitMQ server for synchronizing products with the CMP."
                )}
            >
                <TextInput
                    label={t(
                        "backoffice.integrations.cmp_product.hostname",
                        "Hostname"
                    )}
                    placeholder={t(
                        "backoffice.integrations.cmp_product.hostname_enter",
                        "Enter hostname"
                    )}
                    defaultValue={(values as CmpProductSettingsInput).hostname}
                    onChangeText={text => setValue("hostname", text)}
                    required={true}
                />
            </InputControl>
            <InputControl
                error={errors.port}
                description={t(
                    "backoffice.integrations.cmp_product.port_description",
                    "Port for the RabbitMQ server for synchronizing products with the CMP."
                )}
            >
                <NumberInput
                    label={t(
                        "backoffice.integrations.cmp_product.port",
                        "Port"
                    )}
                    placeholder={t(
                        "backoffice.integrations.cmp_product.port_enter",
                        "Enter port"
                    )}
                    defaultValue={(values as CmpProductSettingsInput).port}
                    onChange={text => setValue("port", text)}
                    required={true}
                />
            </InputControl>
            <InputControl
                error={errors.vhost}
                description={t(
                    "backoffice.integrations.cmp_product.vhost_description",
                    "Vhost of the RabbitMQ setting for synchronizing products with the CMP."
                )}
            >
                <TextInput
                    label={t(
                        "backoffice.integrations.cmp_product.vhost",
                        "Vhost"
                    )}
                    placeholder={t(
                        "backoffice.integrations.cmp_product.vhost_enter",
                        "Enter vhost"
                    )}
                    defaultValue={(values as CmpProductSettingsInput).vhost}
                    onChangeText={text => setValue("vhost", text)}
                    required={true}
                />
            </InputControl>
            <InputControl
                error={errors.username}
                description={t(
                    "backoffice.integrations.cmp_product.username_description",
                    "Username of the RabbitMQ setting for synchronizing products with the CMP."
                )}
            >
                <TextInput
                    label={t(
                        "backoffice.integrations.cmp_product.username",
                        "Username"
                    )}
                    placeholder={t(
                        "backoffice.integrations.cmp_product.username_enter",
                        "Enter username"
                    )}
                    defaultValue={(values as CmpProductSettingsInput).username}
                    onChangeText={text => setValue("username", text)}
                    required={true}
                />
            </InputControl>
            <InputControl
                error={errors.password}
                description={t(
                    "backoffice.integrations.cmp_product.password_description",
                    "Password of the RabbitMQ setting for synchronizing products with the CMP."
                )}
            >
                <TextInput
                    label={t(
                        "backoffice.integrations.cmp_product.password",
                        "Password"
                    )}
                    placeholder={t(
                        "backoffice.integrations.cmp_product.password",
                        "Enter password"
                    )}
                    defaultValue={(values as CmpProductSettingsInput).password}
                    onChangeText={text => setValue("password", text)}
                    required={true}
                />
            </InputControl>
            <InputControl
                error={errors.exchangeName}
                description={t(
                    "backoffice.integrations.cmp_product.exchange_name_description",
                    "Exchange name of the RabbitMQ setting for synchronizing products with the CMP."
                )}
            >
                <TextInput
                    label={t(
                        "backoffice.integrations.cmp_product.exchange_name",
                        "Exchange name"
                    )}
                    placeholder={t(
                        "backoffice.integrations.cmp_product.exchange_name_enter",
                        "Enter exchange name"
                    )}
                    defaultValue={
                        (values as CmpProductSettingsInput).exchangeName
                    }
                    onChangeText={text => setValue("exchangeName", text)}
                    required={true}
                />
            </InputControl>
            <InputControl
                error={errors.queueName}
                description={t(
                    "backoffice.integrations.cmp_product.queue_name_description",
                    "Queue name of the RabbitMQ setting for synchronizing products with the CMP."
                )}
            >
                <TextInput
                    label={t(
                        "backoffice.integrations.cmp_product.queue_name",
                        "Queue name"
                    )}
                    placeholder={t(
                        "backoffice.integrations.cmp_product.queue_name_enter",
                        "Enter queue name"
                    )}
                    defaultValue={(values as CmpProductSettingsInput).queueName}
                    onChangeText={text => setValue("queueName", text)}
                    required={true}
                />
            </InputControl>
            <InputControl
                error={errors.routingKey}
                description={t(
                    "backoffice.integrations.cmp_product.routing_key_description",
                    "Routing key of the RabbitMQ setting for synchronizing products with the CMP."
                )}
            >
                <TextInput
                    label={t(
                        "backoffice.integrations.cmp_product.routing_key",
                        "Routing key"
                    )}
                    placeholder={t(
                        "backoffice.integrations.cmp_product.routing_key_enter",
                        "Enter routing key"
                    )}
                    defaultValue={
                        (values as CmpProductSettingsInput).routingKey
                    }
                    onChangeText={text => setValue("routingKey", text)}
                    required={true}
                />
            </InputControl>
            <Headline size="h5">
                {t(
                    "backoffice.integrations.cmp_product.order_integration_headline",
                    "Order integration"
                )}
            </Headline>
            <InputControl
                error={errors.cmpOrderUrl}
                description={t(
                    "backoffice.integrations.cmp_product.cmp_order_url_description",
                    "URL for connection to the CMP for creating orders."
                )}
            >
                <TextInput
                    label={t(
                        "backoffice.integrations.cmp_product.cmp_order_url",
                        "CMP url"
                    )}
                    placeholder={t(
                        "backoffice.integrations.cmp_product.cmp_order_url_enter",
                        "Enter url to CMP"
                    )}
                    defaultValue={
                        (values as CmpProductSettingsInput).cmpOrderUrl
                    }
                    onChangeText={text => setValue("cmpOrderUrl", text)}
                    required={true}
                />
            </InputControl>
            <Button onPress={handleSubmit(onSubmit)} testID="save">
                {t(submitButton[0], submitButton[1])}
            </Button>
            <RequiredText />
        </>
    );
}
