import React, { useCallback } from "react";
import type { GQPrintersQuery } from "graphql-sdk";
import { useTheme, DataTable, IconButton } from "@venuepos/react-common";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import { formatDateTime } from "lib";
import { useAdminSession } from "../../session";
import type { AvailableLocale } from "locales";

export function PrinterRow({
    item,
    onSelect,
    onDelete,
    style,
}: {
    item: GQPrintersQuery["printers"]["data"][0];
    onSelect: (itemId: string) => void;
    onDelete: (itemId: string) => void;
    style?: StyleProp<ViewStyle>;
}) {
    const theme = useTheme();

    const handleSelect = useCallback(() => {
        onSelect(item.id);
    }, [item.id, onSelect]);

    const handleDelete = useCallback(() => {
        onDelete(item.id);
    }, [item.id, onDelete]);

    const [{ locale }] = useAdminSession(["locale"]);

    return (
        <DataTable.Row onPress={handleSelect} style={style}>
            <DataTable.Cell>{item.name}</DataTable.Cell>
            <DataTable.Cell style={styles.smallColumn}>
                {item.manufacturer}
            </DataTable.Cell>
            <DataTable.Cell style={styles.smallColumn}>
                {item.type}
            </DataTable.Cell>
            <DataTable.Cell>{item.serial}</DataTable.Cell>
            <DataTable.Cell style={styles.smallColumn}>
                {item.class}
            </DataTable.Cell>
            <DataTable.Cell>{item.cashRegister?.name}</DataTable.Cell>
            <DataTable.Cell>
                {formatDateTime(item.createdAt, locale as AvailableLocale)}
            </DataTable.Cell>
            <DataTable.Cell numeric style={styles.iconColumn}>
                <IconButton
                    color={theme.colors.secondary}
                    name="edit"
                    onPress={handleSelect}
                />
                <IconButton
                    color={theme.colors.secondary}
                    name="delete"
                    onPress={handleDelete}
                />
            </DataTable.Cell>
        </DataTable.Row>
    );
}

const styles = StyleSheet.create({
    iconColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40,
    },
    smallColumn: {
        flexBasis: 140,
        flexGrow: 0,
    },
});
