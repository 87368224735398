import {
    Button,
    CheckBox,
    ColorIndicator,
    ColorPickerInput,
    Form,
    Headline,
    InputControl,
    InputLabel,
    Loading,
    Spacer,
    StyleFunction,
    TextInput,
    useThemedStyle,
} from "@venuepos/react-common";
import { useTranslation } from "locales";
import { useState } from "react";
import React, { View } from "react-native";

import { ButtonSizeForm } from "../../../../button-size-form";

import type { ILayoutProduct, LayoutButtonMetaData, ProductButton } from "lib";
export function ProductButtonForm(props: {
    form: Form<ProductButton>;
    product: ILayoutProduct;
    onSubmit: () => void;
    submitButton: [string, string];
    metaData: LayoutButtonMetaData;
}) {
    const styles = useThemedStyle(styleFunc);
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, handleSubmit }] = props.form;
    const [activateColorPicker, setActivateColorPicker] = useState<boolean>(
        !!values.color || false
    );

    const productGroupColor = props.product.group.color;

    if (!values) {
        return <Loading />;
    }

    return (
        <>
            <Headline size="h2">{props.product.name}</Headline>
            <ButtonSizeForm
                form={props.form as any}
                metaData={props.metaData}
            />
            <View>
                <InputControl
                    error={errors.label}
                    description={t(
                        "layout.product_button.label_description",
                        "If this field is left blank, the button will be labelled with the name or button text of the selected product."
                    )}
                >
                    <TextInput
                        label={t("layout.button.label", "The button's text")}
                        placeholder={t(
                            "layout.product_button.label_placeholder",
                            `This button will be labelled '{{ label }}'`,
                            {
                                label:
                                    props.product.buttonText ||
                                    props.product.name,
                                interpolation: { escapeValue: false },
                            }
                        )}
                        value={values.label}
                        onChangeText={text => {
                            setValue("label", text);
                        }}
                        onClear={() => {
                            setValue("label", "");
                        }}
                    />
                </InputControl>
                <View style={[styles.rowContainer, styles.onTop]}>
                    <InputControl
                        description={t(
                            "layout.function_button_form.override_product_group_color",
                            "The button gets the colour of the product group, unless you select a different color here."
                        )}
                        style={styles.rowContent}
                    >
                        <View style={styles.colorRow}>
                            <InputLabel>
                                {`${t(
                                    "layout.button.product_group_color",
                                    "Product group color"
                                )}:`}
                            </InputLabel>
                            <Spacer />
                            <ColorIndicator
                                color={productGroupColor}
                                copyOnClick={true}
                                animate={false}
                            />
                        </View>
                        <CheckBox
                            value={activateColorPicker}
                            onValueChange={checkboxValue => {
                                if (!checkboxValue) {
                                    setValue("color", "");
                                }
                                setActivateColorPicker(checkboxValue);
                            }}
                            label={t(
                                "layout.button.pick_color_question",
                                "Pick a custom color?"
                            )}
                        />
                        <Spacer />
                    </InputControl>
                    {activateColorPicker ? (
                        <InputControl
                            error={errors.color}
                            style={styles.rowContent}
                        >
                            <ColorPickerInput
                                defaultColor={values.color ?? undefined}
                                label={t(
                                    "layout.button.custom_color",
                                    "Custom button color"
                                )}
                                onChangeColor={newColor => {
                                    setValue("color", newColor);
                                }}
                            />
                        </InputControl>
                    ) : (
                        <View style={styles.rowContent} />
                    )}
                </View>
                <Button
                    type="primary"
                    onPress={handleSubmit(props.onSubmit)}
                    testID="layout:editButton:save"
                >
                    {t(props.submitButton[0], props.submitButton[1])}
                </Button>
            </View>
        </>
    );
}

const styleFunc: StyleFunction = theme => ({
    rowContent: {
        ...theme.styles.flex1,
    },
    rowContainer: {
        ...theme.styles.row,
        columnGap: theme.spacingScale * 2,
    },
    onTop: { zIndex: 2 },
    colorRow: {
        ...theme.styles.row,
        alignItems: "baseline",
        marginBottom: 8, // To match the height/layout of the right side of the row.
    },
    groupColor: {
        marginLeft: theme.spacingScale / 2,
    },
});
