import React, { useCallback } from "react";
import { Form, RequiredText } from "@venuepos/react-common";
import { useTranslation } from "locales";
import {
    GQPaymentMethodFormQuery,
    usePaymentMethodFormQuery,
} from "graphql-sdk";
import {
    Button,
    CheckBox,
    InputControl,
    Picker,
    TextInput,
    useMerchantConfig,
} from "@venuepos/react-common";
import type { PaymentMethodInputForm, PAYMENT_TYPES } from "lib";
import { View } from "react-native";

type PaymentType = {
    value: PAYMENT_TYPES;
    defaultLabel: string;
};

// i18next t("backoffice.payment_method_form.types.CASH", "Cash")
const paymentTypeCash: PaymentType = {
    value: "CASH",
    defaultLabel: "Cash",
};

// i18next t("backoffice.payment_method_form.types.CARD", "Card")
const paymentTypeCard: PaymentType = {
    value: "CARD",
    defaultLabel: "Card",
};

// i18next t("backoffice.payment_method_form.types.ACCOUNT", "Customer account")
const paymentTypeAccount: PaymentType = {
    value: "ACCOUNT",
    defaultLabel: "Customer account",
};

// i18next t("backoffice.payment_method_form.types.GENERIC", "Generic payment")
const paymentTypeGeneric: PaymentType = {
    value: "GENERIC",
    defaultLabel: "Generic payment",
};

// i18next t("backoffice.payment_method_form.types.CMP_GIFTCARD", "CMP giftcard")
const paymentTypeCmpGiftcard: PaymentType = {
    value: "CMP_GIFTCARD",
    defaultLabel: "CMP giftcard",
};

export const paymentTypes: PaymentType[] = [
    paymentTypeCash,
    paymentTypeCard,
    paymentTypeAccount,
    paymentTypeGeneric,
    paymentTypeCmpGiftcard,
];

export function PaymentMethodForm(props: {
    form: Form<PaymentMethodInputForm>;
    formData: GQPaymentMethodFormQuery;
    onSubmit: () => void;
    submitButton: [string, string];
}) {
    const merchantConfig = useMerchantConfig();
    const [t] = useTranslation();
    const [{ values, errors }, { setValue, setValues, handleSubmit }] =
        props.form;
    const { data } = usePaymentMethodFormQuery();

    const selectPaymentType = useCallback(
        (paymentType: string) => {
            if (paymentType === "CARD" || paymentType === "ACCOUNT") {
                const merchantCurrency = data!.currencies.data.find(
                    itr => itr.code === merchantConfig.currency
                );
                setValues({
                    currencyId: merchantCurrency!.id,
                    paymentType,
                });
            } else {
                setValue("paymentType", paymentType);
            }
        },
        [data, merchantConfig.currency, setValue, setValues]
    );

    if (!values || !data) {
        return null;
    }

    return (
        <View testID="paymentMethod">
            <InputControl error={errors.name}>
                <TextInput
                    label={t("common.name", "Name")}
                    placeholder={t(
                        "backoffice.common.enter_name",
                        "Enter name"
                    )}
                    defaultValue={values.name}
                    onChangeText={text => setValue("name", text)}
                    testID="name"
                    required={true}
                />
            </InputControl>

            <InputControl error={errors.paymentType}>
                <Picker
                    label={t(
                        "backoffice.payment_method_form.paymentType",
                        "Payment type"
                    )}
                    selectedValue={
                        values.paymentType === null
                            ? "none"
                            : values.paymentType
                    }
                    onValueChange={newValue => {
                        selectPaymentType(newValue);
                    }}
                    testID="type"
                    required={true}
                >
                    <Picker.Item
                        label={t(
                            "backoffice.payment_method_form.payment_type_none",
                            "Select a payment type"
                        )}
                        key="none"
                        value="none"
                    />
                    {paymentTypes.map(paymentType => {
                        return (
                            <Picker.Item
                                key={`payment_type_${paymentType.value}`}
                                label={t(
                                    `backoffice.payment_method_form.types.${paymentType.value}`,
                                    paymentType.defaultLabel
                                )}
                                value={paymentType.value}
                                testID={"type:" + paymentType.value}
                            />
                        );
                    })}
                </Picker>
            </InputControl>

            {["CASH", "GENERIC"].includes(values.paymentType!) && (
                <InputControl error={errors.currencyId}>
                    <Picker
                        label={t(
                            "backoffice.payment_method_form.currency",
                            "Currency"
                        )}
                        selectedValue={
                            values.currencyId === ""
                                ? "none"
                                : values.currencyId
                        }
                        onValueChange={newValue =>
                            setValue(
                                "currencyId",
                                newValue !== "none" ? newValue : ""
                            )
                        }
                        testID="currency"
                        required={true}
                    >
                        <Picker.Item
                            label={t(
                                "backoffice.payment_method_form.currency_none",
                                "Select a currency"
                            )}
                            key="none"
                            value="none"
                        />
                        {props.formData.currencies.data.map(currency => {
                            return (
                                <Picker.Item
                                    key={`currency_${currency.code}`}
                                    label={currency.code}
                                    value={currency.id}
                                    testID={"currency:" + currency.code}
                                />
                            );
                        })}
                    </Picker>
                </InputControl>
            )}

            <InputControl error={errors.enabled}>
                <CheckBox
                    label={t(
                        "backoffice.payment_method_form.enabled",
                        "Enabled?"
                    )}
                    value={values.enabled}
                    onValueChange={newValue => setValue("enabled", newValue)}
                    testID="enabled"
                />
            </InputControl>

            <InputControl error={errors.account}>
                <TextInput
                    label={t(
                        "backoffice.payment_method_form.account",
                        "Account"
                    )}
                    placeholder={t(
                        "backoffice.payment_method_form.enter_account",
                        "Enter account number"
                    )}
                    defaultValue={values.account}
                    onChangeText={text => setValue("account", text)}
                    testID="account"
                />
            </InputControl>

            <InputControl error={errors.diffAccount}>
                <TextInput
                    label={t(
                        "backoffice.payment_method_form.diffAccount",
                        "Account for differences"
                    )}
                    placeholder={t(
                        "backoffice.payment_method_form.enter_diff_account",
                        "Enter account number for differences"
                    )}
                    defaultValue={values.diffAccount}
                    onChangeText={text => setValue("diffAccount", text)}
                    testID="diffAccount"
                />
            </InputControl>

            <Button onPress={handleSubmit(props.onSubmit)} testID="save">
                {t(props.submitButton[0], props.submitButton[1])}
            </Button>

            <RequiredText />
        </View>
    );
}
