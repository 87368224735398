// Purpose: Make defaults and configuration parameters available to the application (web, ios, android)
// SECURITY NOTICE: Anything we put into our JS code can be read by the client. That includes configuration variables.

import { DEV_ENV, VP_ENV } from "lib";
import { Platform } from "react-native";

interface IAppConfig {
    environment: string; // This is for convenience, so we can tell what environment we're using.
    backendBaseURL: string;
    baxiHostIP: string;
    baxiHostPort: number;
}

export function getAppConfig(): IAppConfig {
    let env: IAppConfig = {
        environment: VP_ENV,
        backendBaseURL: "",
        baxiHostIP: "91.102.24.111",
        baxiHostPort: 9670,
    };

    if (Platform.OS === "web") {
        if (window.location) {
            env.backendBaseURL = window.location.origin;
        }

        return env;
    }

    if (VP_ENV === "cloud.venuepos.net") {
        env.baxiHostIP = "91.102.24.142";
    }

    // This handles on Device uses for now
    switch (VP_ENV) {
        case DEV_ENV:
            env.backendBaseURL = "http://localhost:4000";
            break;
        default:
            env.backendBaseURL = `https://${VP_ENV}`;
    }

    return env;
}
