import type {
    CashRegisterConfig,
    CommonConfig,
    DepartmentConfig,
    DeviceConfig,
    MerchantConfig,
    MerchantDepartmentConfig,
} from "./types";
import type { Schema } from "../schema";
import { merchantDepartmentConfig } from "./default-values";
import { supportedGiftcardVatBookkeepingMethods } from "../../merchant";

// COMMON
export const commonConfigSchema: Schema<CommonConfig> = {
    welcomeMessage: {
        type: "text",
        validation: { required: true, minLength: { length: 3 } },
        section: "common",
    },
    printOrderWhenCompleted: {
        type: "boolean",
        validation: null,
        section: "common",
    },
    logOutWhenCompleted: {
        type: "text_enum",
        validation: null,
        section: "common",
        values: ["yes", "ask", "no"],
    },
    language: {
        type: "select",
        validation: null,
        section: "common",
        values: ["en", "da"],
    },
    buttonLayout: {
        type: "select",
        validation: null,
        section: "common",
    },
    tableLayout: {
        type: "select",
        validation: null,
        section: "common",
    },
    paymentOptions: {
        type: "select",
        validation: null,
        section: "common",
    },
    miscButtons: {
        type: "select",
        validation: null,
        section: "common",
    },
    rotation: {
        type: "select",
        validation: null,
        section: "common",
        values: ["0", "90", "180", "270"],
    },
    autoDeleteOrderIdleTimeout: {
        type: "number",
        validation: null,
        section: "common",
    },
    autoDeleteOrderWarningTimeout: {
        type: "number",
        validation: null,
        section: "common",
    },
    warnOnRefundAndReturn: {
        type: "boolean",
        validation: null,
        section: "common",
    },
    queryCustomersForData: {
        type: "boolean",
        validation: null,
        section: "common",
    },
    enableLayoutSwipe: {
        type: "boolean",
        validation: null,
        section: "common",
    },
    printReceiptNumberOnOrderSummary: {
        type: "boolean",
        validation: null,
        section: "common",
    },
};

// MERCHANT & DEPARTMENT
export const merchantDepartmentConfigSchema: Schema<MerchantDepartmentConfig> =
    {
        address: {
            type: "text",
            validation: null,
            section: "location",
        },
        zipcode: {
            type: "text",
            validation: null,
            section: "location",
        },
        city: {
            type: "text",
            validation: null,
            section: "location",
        },
        country: {
            type: "text",
            validation: null,
            section: "location",
        },
        email: {
            type: "text",
            validation: null,
            section: "location",
        },
        phone: {
            type: "text",
            validation: null,
            section: "location",
        },
        cvr: {
            type: "text",
            validation: null,
            section: "location",
        },
        headerText: {
            type: "text",
            validation: null,
            section: "receipt",
        },
        footerText: {
            type: "text",
            validation: null,
            section: "receipt",
        },
        showServedBy: {
            type: "boolean",
            validation: null,
            section: "receipt",
        },
        // showLogoOnReceipt: {
        //     type: "boolean",
        //     validation: null,
        //     section: "receipt",
        // },
        sumupAffiliateKey: {
            type: "text",
            validation: null,
            section: "paymentProvider",
        },
        sumupClientId: {
            type: "text",
            validation: null,
            section: "paymentProvider",
        },
        sumupClientSecret: {
            type: "text",
            validation: null,
            section: "paymentProvider",
        },
        sumupRefreshToken: {
            type: "text",
            validation: null,
            section: "paymentProvider",
        },
        sumupRedirectUrl: {
            type: "text",
            validation: null,
            section: "paymentProvider",
        },
        softpayIntegratorId: {
            type: "text",
            validation: null,
            section: "paymentProvider",
        },
        softpayMerchantReference: {
            type: "text",
            validation: null,
            section: "paymentProvider",
        },
        adyenTerminalApiKey: {
            type: "text",
            validation: null,
            section: "paymentProvider",
            superadminOnly: true,
        },
        adyenKeyPassphrase: {
            type: "text",
            validation: null,
            section: "paymentProvider",
        },
        adyenKeyIdentifier: {
            type: "text",
            validation: null,
            section: "paymentProvider",
        },
        adyenKeyVersion: {
            type: "number",
            validation: null,
            section: "paymentProvider",
        },
        adyenCryptoVersion: {
            type: "number",
            validation: null,
            section: "paymentProvider",
        },
    };

// MERCHANT
export const merchantConfigSchema: Schema<MerchantConfig> = {
    ...commonConfigSchema,
    ...merchantDepartmentConfigSchema,
    defaultVat: {
        type: "select",
        validation: {
            required: true,
        },
        section: "merchant",
    },
    defaultDepartment: {
        type: "select",
        validation: null,
        section: "merchant",
    },
    currency: {
        locked: true,
        type: "text",
        validation: {
            required: true,
            minLength: { length: 3 },
            maxLength: { length: 3 },
        },
        section: "merchant",
    },
    giftcardVatBookkeepingMethod: {
        locked: true,
        type: "text_enum",
        validation: {
            required: true,
        },
        section: "merchant",
        values: supportedGiftcardVatBookkeepingMethods,
    },
    barcodeOnReceipt: {
        type: "boolean",
        validation: null,
        section: "merchant",
    },
};

// DEPARTMENT
export const departmentConfigSchema: Schema<DepartmentConfig> = {
    ...commonConfigSchema,
    ...merchantDepartmentConfigSchema,
};

// CASH REGISTER
export const cashRegisterConfigSchema: Schema<CashRegisterConfig> = {
    ...commonConfigSchema,
    receiptPrinter: {
        type: "select",
        validation: null,
        section: "receipt",
    },
    ticketPrinter: {
        type: "select",
        validation: null,
        section: "receipt",
    },
    showServedBy: {
        type: "boolean",
        validation: null,
        section: "receipt",
    },
    // showLogoOnReceipt: {
    //     type: "boolean",
    //     validation: null,
    //     section: "receipt",
    // },
    softpayCloudTerminalAppId: {
        type: "select",
        validation: null,
        section: "paymentProvider",
    },
    adyenTerminalApiPoiId: {
        type: "text",
        validation: { required: true, minLength: { length: 3 } },
        section: "paymentProvider",
    },
};

export const deviceConfigSchema: Schema<DeviceConfig> = {
    ...merchantConfigSchema,
    ...merchantDepartmentConfig,
    ...departmentConfigSchema,
    ...cashRegisterConfigSchema,
};
