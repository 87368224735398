import {
    ButtonDimensions,
    ButtonPosition,
    GridDimensions,
    LayoutTableButton,
    TableSection,
} from "lib";
import {
    LayoutActionType,
    LayoutAction,
    LayoutReducerState,
} from "../../layout-operation-reducer";
import { produceNewLayoutSection } from "../../functions";
import produce from "immer";

export type TableLayoutReducerState = Omit<LayoutReducerState, "sections"> & {
    sections: (TableSection & Partial<{ renaming: boolean }>)[];
    dimensions: GridDimensions;
};

export enum TableLayoutAction {
    ADD_BUTTON_TO_SECTION = "add_button_to_section",
    MOVE_BUTTON_IN_SECTION = "move_button_in_section",
    REMOVE_BUTTON_FROM_SECTION = "remove_button_from_section",
    RESIZE_BUTTON_IN_SECTION = "resize_button_in_section",
    UPDATE_BUTTON_IN_SECTION = "update_button_in_section",
}

export type TableLayoutActionType =
    | {
          type: TableLayoutAction.ADD_BUTTON_TO_SECTION;
          newButton: LayoutTableButton;
      }
    | {
          type: TableLayoutAction.MOVE_BUTTON_IN_SECTION;
          buttonId: LayoutTableButton["id"];
          position: ButtonPosition;
      }
    | {
          type: TableLayoutAction.REMOVE_BUTTON_FROM_SECTION;
          buttonId: LayoutTableButton["id"];
      }
    | {
          type: TableLayoutAction.RESIZE_BUTTON_IN_SECTION;
          buttonId: LayoutTableButton["id"];
          buttonDimensions: ButtonDimensions;
      }
    | {
          type: TableLayoutAction.UPDATE_BUTTON_IN_SECTION;
          updatedButton: LayoutTableButton;
      };

export const tableLayoutReducer = <T extends TableLayoutReducerState>(
    state: T,
    action: LayoutActionType | TableLayoutActionType
) => {
    let newState: T;
    let foundSectionIndex: number;
    let foundSection: typeof newState.sections[0] | undefined;
    let foundButtonIndex: number | undefined;
    let foundButton: LayoutTableButton | undefined;

    switch (action.type) {
        case LayoutAction.ADD_SECTION: {
            if (!action.translationFunction) {
                return state;
            }

            newState = produce(state, draft => {
                draft.sections.push(
                    produceNewLayoutSection(
                        action.translationFunction,
                        String(draft.sections.length + 1)
                    ) as TableSection
                );
            });

            break;
        }

        case LayoutAction.DELETE_SECTION: {
            foundSectionIndex = state.sections.findIndex(
                itr => itr.id === action.sectionId
            );

            if (foundSectionIndex === -1) {
                return state;
            }

            // Find the section and remove it.
            newState = produce(state, draft => {
                draft.sections.splice(foundSectionIndex, 1);

                // If this was the last section, then add a new one.
                if (draft.sections.length === 0) {
                    draft.sections.push(
                        produceNewLayoutSection(
                            action.translationFunction,
                            "1"
                        ) as TableSection
                    );
                }

                // If the currently deleted section was before the currently
                // active section then adjust the index of the active section.
                if (foundSectionIndex <= draft.currentSectionIndex) {
                    draft.currentSectionIndex = draft.currentSectionIndex - 1;

                    // Make sure the index never gets faulty
                    if (draft.currentSectionIndex < 0) {
                        draft.currentSectionIndex = 0;
                    }
                }
            });

            break;
        }

        case LayoutAction.MOVE_SECTION: {
            const layoutIndex = state.sections.findIndex(
                section => section.id === action.sectionId
            );

            if (layoutIndex === -1) {
                return state;
            }

            if (!["BEFORE", "AFTER"].includes(action.direction)) {
                return state;
            }

            const newPosition =
                action.direction === "BEFORE"
                    ? layoutIndex - 1
                    : layoutIndex + 1;

            // if newPosition is out of bounds: back off.
            if (newPosition < 0 || newPosition > state.sections.length) {
                return state;
            }

            newState = produce(state, draft => {
                // Move section from layoutIndex to newPosition
                draft.sections.splice(
                    newPosition,
                    0,
                    draft.sections.splice(layoutIndex, 1)[0]
                );

                // Make sure the selected section makes sense.
                if (layoutIndex === draft.currentSectionIndex) {
                    draft.currentSectionIndex = newPosition;
                } else if (newPosition === draft.currentSectionIndex) {
                    draft.currentSectionIndex = layoutIndex;
                }
            });
            break;
        }

        case LayoutAction.RENAME_SECTION: {
            foundSection = state.sections.find(
                itr => itr.id === action.sectionId
            );

            if (!foundSection) {
                return state;
            }

            // change label on section
            newState = produce(state, draft => {
                draft.sections.map(section => {
                    if (section.id === action.sectionId) {
                        section.label = action.newLabel.trim();
                    }

                    return section;
                });
            });

            break;
        }

        case LayoutAction.SELECT_SECTION: {
            foundSectionIndex = state.sections.findIndex(
                itr => itr.id === action.sectionId
            );

            if (foundSectionIndex === -1) {
                return state;
            }

            newState = produce(state, draft => {
                draft.currentSectionIndex = foundSectionIndex;
            });

            break;
        }

        case LayoutAction.TOGGLE_RENAME_SECTION: {
            foundSection = state.sections.find(
                itr => itr.id === action.sectionId
            );

            if (!foundSection) {
                return state;
            }

            newState = produce(state, draft => {
                draft.sections.map(section => {
                    if (section.id === action.sectionId) {
                        // toggle the renaming property for this section
                        section.renaming = action.toggleState;
                    }
                    return section;
                });
            });
            break;
        }

        case TableLayoutAction.ADD_BUTTON_TO_SECTION: {
            newState = produce(state, draft => {
                draft.sections[draft.currentSectionIndex].buttons.push(
                    action.newButton
                );
            });

            break;
        }

        case TableLayoutAction.MOVE_BUTTON_IN_SECTION: {
            // If, for some odd reason, the button does not have an id or is dropped in the same location, then back off.
            if (!action.buttonId) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButtonIndex = state.sections[
                state.currentSectionIndex
            ].buttons.findIndex(button => button.id === action.buttonId);

            if (foundButtonIndex === -1) {
                return state;
            }

            newState = produce(state, draft => {
                draft.sections[draft.currentSectionIndex].buttons =
                    draft.sections[draft.currentSectionIndex].buttons.map(
                        button => {
                            if (button.id === action.buttonId) {
                                return {
                                    ...button,

                                    ...action.position,
                                };
                            }

                            return button;
                        }
                    );
            });

            break;
        }

        case TableLayoutAction.REMOVE_BUTTON_FROM_SECTION: {
            if (!action.buttonId) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButton = state.sections[
                state.currentSectionIndex
            ].buttons.find(itr => itr.id === action.buttonId);

            if (!foundButton) {
                return state;
            }

            newState = produce(state, draft => {
                // Quote from immerjs.github.io: https://immerjs.github.io/immer/update-patterns/
                // when filtering, creating a fresh collection is simpler than
                // removing irrelevant items
                const buttons =
                    draft.sections[draft.currentSectionIndex].buttons;

                draft.sections[draft.currentSectionIndex].buttons =
                    buttons.filter(button => button.id !== action.buttonId);
            });

            break;
        }

        case TableLayoutAction.RESIZE_BUTTON_IN_SECTION: {
            // If, for some odd reason, the button does not have an id or is dropped in the same location, then back off.
            if (!action.buttonId) {
                return state;
            }

            foundSection = state.sections[state.currentSectionIndex];

            if (!foundSection) {
                return state;
            }

            foundButtonIndex = state.sections[
                state.currentSectionIndex
            ].buttons.findIndex(buttonItr => buttonItr.id === action.buttonId);

            if (foundButtonIndex === -1) {
                return state;
            }

            newState = produce(state, draft => {
                draft.sections[draft.currentSectionIndex].buttons[
                    foundButtonIndex!
                ] = {
                    ...foundSection!.buttons[foundButtonIndex!],

                    // update with the given new dimensions
                    ...action.buttonDimensions,
                };
            });

            break;
        }

        case TableLayoutAction.UPDATE_BUTTON_IN_SECTION: {
            if (!action.updatedButton.id) {
                return state;
            }

            const foundButtons =
                state.sections[state.currentSectionIndex].buttons;

            if (foundButtons === undefined) {
                return state;
            }

            foundButtonIndex = foundButtons.findIndex(
                buttonItr => buttonItr?.id === action.updatedButton.id
            );

            if (foundButtonIndex === -1) {
                return state;
            }

            newState = produce(state, draft => {
                draft.sections[draft.currentSectionIndex].buttons[
                    foundButtonIndex!
                ] = action.updatedButton;
            });

            break;
        }

        default: {
            console.error("Invalid Table Layout Operation:");
            console.error(action);
            throw new Error(
                `Invalid Table Layout Operation: ${JSON.stringify(action)}`
            );
        }
    }

    return newState;
};
